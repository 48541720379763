import {Persistor} from "redux-persist/es/types";
import {Store} from "redux";
import {IStoreService} from "./IStoreService";
import {ApplicationState} from "../../store";

/**
 * Сервис лишь для хранения ссылок на объекты в di контейнере.
 */
export class StoreService implements IStoreService {
    private _persistor: Persistor;
    private _store: Store;

    constructor(persistor: Persistor, store: Store<ApplicationState>) {
        this._persistor = persistor;
        this._store = store;
    }

    get persistor(): Persistor {
        return this._persistor;
    }

    get store(): Store<ApplicationState> {
        return this._store;
    }
}
