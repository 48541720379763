import React, {useState} from 'react';
import {createPortal} from 'react-dom';

import {
    closestCenter,
    defaultDropAnimationSideEffects,
    DndContext,
    DragOverlay,
    DropAnimation,
    MouseSensor,
    TouchSensor,
    UniqueIdentifier,
    useSensor,
    useSensors
} from '@dnd-kit/core';
import {
    AnimateLayoutChanges,
    defaultAnimateLayoutChanges,
    NewIndexGetter,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import {Item} from './components/Item/Item';
import {List} from './components/List/List';
import {Wrapper} from './components/Wrapper/Wrapper';
import {restrictToVerticalAxis} from "@dnd-kit/modifiers";

export interface SortableItemComponent<T> {
    id: UniqueIdentifier;
    disabled?: boolean;
    selected?: boolean;
    item: T;
}

export interface Props<T> {
    dropAnimation?: DropAnimation | null;
    getNewIndex?: NewIndexGetter;
    items: SortableItemComponent<T>[];
    tinyItems?: boolean;
    onEditClick?: (item: SortableItemComponent<T>, index: number) => void;
    onDeleteClick?: (item: SortableItemComponent<T>, index: number) => void;
    renderItemContent: (item: SortableItemComponent<T>, index: number) => JSX.Element;
    reorderItems: (array: SortableItemComponent<T>[], from: number, to: number) => void;
}

const dropAnimationConfig: DropAnimation = {
    sideEffects: defaultDropAnimationSideEffects({
        styles: {
            active: {
                opacity: '0.5',
            },
        },
    }),
};

export function Sortable<T>({
                                dropAnimation = dropAnimationConfig,
                                getNewIndex,
                                items,
                                tinyItems,
                                onDeleteClick,
                                onEditClick,
                                renderItemContent,
                                reorderItems
                            }: Props<T>) {

    const animateLayoutChanges: AnimateLayoutChanges = (args) =>
        defaultAnimateLayoutChanges({...args, wasDragging: true});

    const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor)
    );
    const getIndex = (id: UniqueIdentifier) => items.findIndex((item) => item.id === id);
    const activeIndex = activeId ? getIndex(activeId) : -1;

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={({active}) => {
                if (!active) {
                    return;
                }

                setActiveId(active.id);
            }}
            onDragEnd={({over}) => {
                setActiveId(null);

                if (over) {
                    const overIndex = getIndex(over.id);

                    if (activeIndex !== overIndex) {
                        // Изменить состав массива должен родительский компонент
                        reorderItems(items, activeIndex, overIndex);
                    }
                }
            }}
            onDragCancel={() => setActiveId(null)}
            // measuring={{droppable: {strategy: MeasuringStrategy.Always}}}
            // restrictToFirstScrollableAncestor закомментирован, т.к. работает плохо - если список
            // выходит за пределы текущей прокрутки, перетаскиваемый элемент не может туда зайти
            modifiers={[restrictToVerticalAxis/*, restrictToFirstScrollableAncestor*/]}
        >
            <Wrapper>
                <SortableContext items={items} strategy={verticalListSortingStrategy}>
                    <List tinyItems={tinyItems}>
                        {items.map((value, index) => (
                            <SortableItem
                                key={value.id}
                                id={value.id}
                                index={index}
                                handle={true}
                                tinyItems={tinyItems}
                                value={renderItemContent(value, index)}
                                disabled={value.disabled ?? false}
                                selected={value.selected ?? false}
                                onEdit={(onEditClick) ? (() => onEditClick(value, index)) : undefined}
                                onRemove={(onDeleteClick) ? (() => onDeleteClick(value, index)) : undefined}
                                animateLayoutChanges={animateLayoutChanges}
                                useDragOverlay={true}
                                getNewIndex={getNewIndex}
                            />
                        ))}
                    </List>
                </SortableContext>
            </Wrapper>
            {/** Drag overlay **/
                createPortal(
                    <DragOverlay
                        adjustScale={false}
                        dropAnimation={dropAnimation}
                    >
                        {activeId ? (
                            <Item
                                value={renderItemContent(items[activeIndex], activeIndex)}
                                handle={true}
                                tinyItems={tinyItems}
                                active={true}
                                selected={items[activeIndex].selected}
                                dragOverlay
                            />
                        ) : null}
                    </DragOverlay>,
                    document.body
                )}
        </DndContext>
    );
}

interface SortableItemProps {
    animateLayoutChanges?: AnimateLayoutChanges;
    disabled?: boolean;
    selected?: boolean;
    getNewIndex?: NewIndexGetter;
    id: UniqueIdentifier;
    value: JSX.Element;
    tinyItems?: boolean;
    index: number;
    handle: boolean;
    useDragOverlay?: boolean;

    onRemove?(id: UniqueIdentifier): void;

    onEdit?(id: UniqueIdentifier): void;
}

export function SortableItem({
                                 disabled,
                                 selected,
                                 animateLayoutChanges,
                                 getNewIndex,
                                 handle,
                                 id,
                                 value,
                                 tinyItems,
                                 index,
                                 onRemove,
                                 onEdit,
                                 useDragOverlay
                             }: SortableItemProps) {
    const {
        attributes,
        isDragging,
        isSorting,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
    } = useSortable({
        id,
        animateLayoutChanges,
        disabled,
        getNewIndex,
    });

    return (
        <Item
            ref={setNodeRef}
            value={value}
            tinyItems={tinyItems}
            disabled={disabled}
            selected={selected}
            dragging={isDragging}
            sorting={isSorting}
            handle={handle}
            handleProps={
                handle
                    ? {
                        ref: setActivatorNodeRef,
                    }
                    : undefined
            }
            index={index}
            onRemove={onRemove ? () => onRemove(id) : undefined}
            onEdit={onEdit ? () => onEdit(id) : undefined}
            transform={transform}
            transition={transition}
            listeners={listeners}
            data-index={index}
            data-id={id}
            dragOverlay={!useDragOverlay && isDragging}
            {...attributes}
        />
    );
}
