import styled from "styled-components";
import {Button} from "../../../../../../components/Ui/Elements/Button";

export const ButtonStyled = styled(Button)`
    padding: 10px;
    line-height: 12px;
    color: ${({theme}) => theme.colors.textSecondary};
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-weight: normal;
    
    &.btn--primary {
        color: ${({theme}) => theme.colors.textInverse};
    }
`;
