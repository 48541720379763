import React, {useMemo} from "react";
import {Trans} from "@lingui/macro";
import {useParams} from "react-router-dom";

interface EpisodeCompleteTitleProps {
    episodeName: string;
    lastEpisodeNumber: number;
    lastEpisodeId: string;
}

export const EpisodeCompleteTitle: React.FC<EpisodeCompleteTitleProps> = (
    {
        episodeName,
        lastEpisodeId,
        lastEpisodeNumber
    }
) => {
    const {episodeId} = useParams<{ episodeId: string }>();

    const content = useMemo(() => {
        if (episodeId === lastEpisodeId) {
            return <div><h2>{episodeName},<br/><Trans id={'{lastEpisodeNumber}-й эпизод выполнен!'} values={{lastEpisodeNumber: lastEpisodeNumber}}/>&nbsp;🎉</h2></div>
        }

        return <div><h2>{episodeName},<br/><Trans>эпизод выполнен!</Trans>&nbsp;🎉</h2></div>
    }, [episodeId, episodeName, lastEpisodeId, lastEpisodeNumber]);

    return content;
}