import {withRouter, WithRouterProps} from "../../../components/WithRouterHoc";
import React from "react";
import {IStonlineApiClient} from "../../../../components/StonlineApiClient/IStonlineApiClient";
import {IDateHelperService} from "../../../../services/date-helper/IDateHelperService";
import {ILogger} from "../../../../components/Logger/ILogger";
import {container} from "tsyringe";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {DefaultLoader} from "../../../components/DefaultLoader";
import {ErrorLoadingContent} from "../../../components/Ui/Elements/ErrorLoadingContent";
import {PageBackLink} from "../../../components/Ui/Elements/PageBackLink";
import {PageTitle} from "../../../styles/global-elements";
import {t, Trans} from "@lingui/macro";
import {ApplicationState} from "../../../../store";
import {connect, ConnectedProps} from "react-redux";
import {RoutesList} from "../../../RoutesList";
import {RoutesHelper} from "../../../../helpers/RoutesHelper";
import styled from "styled-components";
import {
    DtoLessonDataResponse
} from "../../../../components/StonlineApiClient/ApiDto/Response/Lesson/DtoLessonDataResponse";
import {
    DtoStudentInLessonListItem
} from "../../../../components/StonlineApiClient/ApiDto/Response/StudentInLesson/DtoStudentInLessonListItem";
import {LoggerSectionsEnum} from "../../../../components/Logger/LoggerSectionsEnum";
import {HorizontalNavigation} from "../../../components/PageNavigation/HorizontalNavigation";
import {LessonPageContextProvider} from "./LessonPageContext";
import {StudentItem} from '../../common/lesson/RoomControlBlock/StudentItemType';
import {LessonMaterialsModePage} from "./OnLessonMode/LessonMaterialsMode/LessonMaterialsModePage";
import RoomControlBlock from "./RoomControlBlock/index";
import {StringHelper} from "../../../../helpers/StringHelper";
import {
    LessonHomeworkMaterialsModePage
} from "./HomeworkMode/LessonHomeworkMaterialsMode/LessonHomeworkMaterialsModePage";
import {IHttpApiClient} from "../../../../components/HttpApiClient/IHttpApiClient";
import {
    DtoLessonRoomOverviewTeacherResponse
} from "../../../../components/HttpApiClient/ApiDto/Response/Lessons/DtoLessonRoomOverviewTeacherResponse";
import {BaseResponseDto} from "../../../../components/HttpApiClient/ApiDto/Response/BaseResponseDto";
import {
    DtoLessonMaterialItem
} from "../../../../components/HttpApiClient/ApiDto/Response/LessonMaterials/DtoLessonMaterialItem";
import {
    DtoLessonMaterialHomeworkItem
} from "../../../../components/HttpApiClient/ApiDto/Response/LessonMaterials/DtoLessonMaterialHomeworkItem";
import {plainToInstance} from "class-transformer";
import {IWsApiClient} from "../../../../components/WsApiClient/IWsApiClient";
import {ApiMethodEnum} from "../../../../components/WsApiClient/ApiMethodEnum";
import {
    DtoTeacherStartOnlineLesson
} from "../../../../components/WsApiClient/ApiDto/Request/Lesson/DtoStartOnlineLessonRequest";
import {
    DtoTeacherStartOnlineLessonResult
} from "../../../../components/WsApiClient/ApiDto/Response/Lesson/DtoTeacherStartOnlineLessonResult";
import {IFeatureToggle} from "../../../../components/FeatureToggle/IFeatureToggle";
import {DtoJoinToRoomRequest} from "../../../../components/WsApiClient/ApiDto/Request/LessonRoom/DtoJoinToRoomRequest";
import {
    DtoJoinToRoomResponse
} from "../../../../components/WsApiClient/ApiDto/Response/LessonRoom/DtoJoinToRoomResponse";
import {Dispatch} from "redux";
import {
    clearRoomState,
    handleLeaveFromRoomViaRouter,
    loadRoomMembersList,
    setLessonRoomId,
    setLessonRoomState,
    setRoomConnectionParams
} from "../../../../store/lessonRoom/actions";
import {LessonRoomStateTypes, RoomConnectionParams} from "../../../../store/lessonRoom/type";
import {
    DtoStopOnlineLessonRequest
} from "../../../../components/WsApiClient/ApiDto/Request/Lesson/DtoStopOnlineLessonRequest";
import {setCameraRequestAccessNow, setRouteLeaveConfirmMessage} from "../../../../store/app/actions";
import {debounce} from "lodash";
import {resetLessonMaterialsState} from "../../../../store/lessonMaterials/actions";
import OnLessonModePage from "./OnLessonMode/OnLessonModePage";
import HomeworkModePage from "./HomeworkMode/HomeworkModePage";
import {
    DtoUserProfileInfoBySchoolStudentProfile
} from "../../../../components/HttpApiClient/ApiDto/Response/Students/DtoUserProfileInfoBySchoolStudentProfile";
import {GeneralModePage} from "./GeneralMode/GeneralModePage";
import {WsConnectionStatusEnum} from "../../../../components/WsApiClient/WsConnectionStatusEnum";
import {setBodyScrollAvailable} from "../../../../store/layout/actions";
import {IMediaDeviceService} from "../../../../services/media-device/IMediaDeviceService";
import {roomMembersSelector} from "../../../../store/lessonRoom/selector";
import {WsResponseStatusEnum} from "../../../../components/WsApiClient/WsResponseStatusEnum";
import {VideoRoomErrorModal, VideoRoomErrorModalTypeEnum} from "../../student/common/Lesson/VideoRoomErrorModal";
import {
    RequestedDataNotFoundException
} from "../../../../components/StonlineApiClient/Exception/RequestedDataNotFoundException";
import {NoConnection} from "../../../../components/HttpApiClient/Exception/NoConnection";
import {ViewModeEnum} from "../../../components/PageNavigation/HorizontalNavigation/ViewModeEnum";
import {LessonConference, LessonConferenceMethods} from "../../../components/LessonConference";
import {ConferenceModeEnum} from "../../../components/LessonConference/Types";
import {ViewportHelper} from "../../../../helpers/ViewportHelper";

enum PageModeEnum {
    GENERAL = 'general',
    ON_LESSON = 'on_lesson',
    HOMEWORK = 'homework',
    OTHER = 'other'
}

enum LeaveFromRoomReasonEnum {
    USER_WANT,
    ROUTER_EVENT,
    WS_DISCONNECTED
}

const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const PageLoaderWrapper = styled.div`
  width: 100%;
`;

const PageMainContentWrapper = styled.div`
  flex-grow: 1;
  padding-bottom: 100px;
  max-width: 100%;

  @media (${({theme}) => theme.media.large}) {
    margin-right: 20px;
    padding-bottom: 40px;
    max-width: 680px;
  }
`;

const LessonConferenceStyled = styled(LessonConference)`
  margin-bottom: 44px;
`;

enum LoadingState {
    NOT_INIT,
    LOADING,
    SUCCESS,
    ERROR
}

interface RouterParams {
    groupId: string;
    lessonId: string;
}

interface TeacherLessonPageProps extends PropsFromRedux {
}

type TeacherLessonPagePropsWRouter = WithRouterProps<RouterParams> & TeacherLessonPageProps;

interface TeacherLessonPageState {
    loadingState: LoadingState;
    groupIntId: number | null;
    lessonIntId: number | null;
    students: StudentItem[];

    lessonDto: DtoLessonDataResponse | null;
    studentInLessonItems: DtoStudentInLessonListItem[];
    activeMode: PageModeEnum;

    lessonRoomId: string | null;
    lessonMaterialsList: DtoLessonMaterialItem[];
    lessonHomeworkMaterialsList: DtoLessonMaterialHomeworkItem[];

    homeworkIsAvailableForStudents: boolean;

    onlineRoomHasVideo: boolean;
    onlineRoomIsOpened: boolean;
    onlineRoomIsConnected: boolean;

    cameraAllowRequestBeforeStartRoom: boolean;
    cameraAllowRequestBeforeJoinToRoom: boolean;

    selectedRoomMemberId: string | null;

    videoAreaInViewport: boolean;
    windowIsLarge: boolean;

    needReconnectToToRoomAfterWsRecovery: boolean;

    videoRoomError: VideoRoomErrorModalTypeEnum | null;
}

class TeacherLessonPage extends React.Component<TeacherLessonPagePropsWRouter, TeacherLessonPageState> {
    protected baseInfoAbortController: AbortController | null;

    protected stonlineApiClient: IStonlineApiClient;
    protected httpApiClient: IHttpApiClient;
    protected dateHelperService: IDateHelperService;
    protected featureToggle: IFeatureToggle;
    protected mediaDeviceService: IMediaDeviceService;

    protected logger: ILogger;

    protected lessonMaterialsPageRef: React.RefObject<LessonMaterialsModePage>;
    protected lessonHomeworkMaterialsPageRef: React.RefObject<LessonHomeworkMaterialsModePage>;
    protected lessonConferenceRef: React.RefObject<LessonConferenceMethods>;

    protected wsClient: IWsApiClient;

    protected accessToCameraPromiseResolve: ((value: boolean) => void) | null;

    protected checkVideoAreaInViewportDebounced;

    protected mediaQueryLarge;

    constructor(props: Readonly<TeacherLessonPagePropsWRouter>) {
        super(props);

        this.stonlineApiClient = container.resolve<IStonlineApiClient>(DiTokens.STONLINE_CLIENT);
        this.httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        this.dateHelperService = container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE);
        this.featureToggle = container.resolve<IFeatureToggle>(DiTokens.FEATURE_TOGGLE);
        this.wsClient = container.resolve<IWsApiClient>(DiTokens.WS_CLIENT);
        this.mediaDeviceService = container.resolve<IMediaDeviceService>(DiTokens.MEDIA_DEVICE_SERVICE);

        this.logger = container.resolve<ILogger>(DiTokens.LOGGER);

        this.mediaQueryLarge = window.matchMedia(`(${this.props.currentTheme.media.large})`);

        this.state = {
            loadingState: LoadingState.NOT_INIT,
            groupIntId: null,
            lessonIntId: null,
            lessonDto: null,
            studentInLessonItems: [],
            activeMode: PageModeEnum.GENERAL,
            students: [],
            lessonMaterialsList: [],
            lessonHomeworkMaterialsList: [],
            lessonRoomId: null,
            onlineRoomHasVideo: false,
            onlineRoomIsOpened: false,
            onlineRoomIsConnected: false,
            cameraAllowRequestBeforeStartRoom: false,
            cameraAllowRequestBeforeJoinToRoom: false,
            selectedRoomMemberId: null,
            videoAreaInViewport: true,
            homeworkIsAvailableForStudents: false,
            windowIsLarge: this.mediaQueryLarge.matches,
            needReconnectToToRoomAfterWsRecovery: false,
            videoRoomError: null
        }

        this.baseInfoAbortController = null;

        this.lessonMaterialsPageRef = React.createRef();
        this.lessonHomeworkMaterialsPageRef = React.createRef();
        this.lessonConferenceRef = React.createRef();

        this.accessToCameraPromiseResolve = null;

        this.checkVideoAreaInViewportDebounced = debounce(this.checkVideoAreaInViewport, 100);
    }

    componentDidMount() {
        const lessonId = parseInt(this.props.match.params.lessonId);
        const groupId = parseInt(this.props.match.params.groupId);

        if ((isNaN(lessonId)) || (lessonId < 1) || (isNaN(groupId)) || (groupId < 1)) {
            this.setState(() => {
                return {
                    loadingState: LoadingState.ERROR
                }
            });

            return;
        }

        this.setState(
            () => {
                return {
                    lessonIntId: lessonId,
                    groupIntId: groupId
                }
            },
            () => {
                // Если подключение готово, загружаем базовую информацию. Иначе просто ждём установки соединения.
                if (this.props.wsStatus === WsConnectionStatusEnum.AUTHORIZED) {
                    this.fetchBaseLessonData(false);
                }
            }
        );

        window.addEventListener('scroll', this.onWindowScroll);
        window.addEventListener('resize', this.onWindowResize);
    }


    componentDidUpdate(prevProps: Readonly<TeacherLessonPagePropsWRouter>, prevState: Readonly<TeacherLessonPageState>, snapshot?: any) {
        // Проверим - не нужно ли разрезолвить промис доступа к камере
        if (this.accessToCameraPromiseResolve !== null) {
            if ((prevProps.accessToCameraRequestingNow === true) && (this.props.accessToCameraRequestingNow === false)) {
                // Если вернулись из "окна" запроса доступа
                this.accessToCameraPromiseResolve(this.props.accessToCameraAllowedInSession ?? false);
            }
        }

        if (this.state.loadingState === LoadingState.NOT_INIT) {
            if (
                (prevProps.wsStatus !== WsConnectionStatusEnum.AUTHORIZED)
                && (this.props.wsStatus === WsConnectionStatusEnum.AUTHORIZED)
            ) {
                // Если страница не инициализирована
                // и ws соединение только сейчас подготовилось (не было готово на этапе монтирования)
                this.fetchBaseLessonData(false);
            }
        } else if (this.state.loadingState === LoadingState.SUCCESS) {
            if (
                (prevProps.wsStatus === WsConnectionStatusEnum.AUTHORIZED)
                && (this.props.wsStatus !== WsConnectionStatusEnum.AUTHORIZED)
            ) {
                // Если WS соединение потерялось
                this.leaveFromRoom(LeaveFromRoomReasonEnum.WS_DISCONNECTED);
            } else if (
                (prevProps.wsStatus !== WsConnectionStatusEnum.AUTHORIZED)
                && (this.props.wsStatus === WsConnectionStatusEnum.AUTHORIZED)
            ) {
                // Если соединение установлено после какой-то проблемы, когда страница уже прошла инициализацию
                this.fetchBaseLessonData(true);
            }
        }
    }

    componentWillUnmount() {
        if (this.baseInfoAbortController !== null) {
            this.baseInfoAbortController.abort();
            this.baseInfoAbortController = null;
        }

        window.removeEventListener('scroll', this.onWindowScroll);
        window.removeEventListener('resize', this.onWindowResize);
        this.checkVideoAreaInViewportDebounced.cancel();

        this.leaveFromRoom(LeaveFromRoomReasonEnum.ROUTER_EVENT);
        this.props.setBeforeUnloadMessage(null);
    }

    protected onWindowResize = () => {
        this.checkVideoAreaInViewportDebounced();
    }

    protected onWindowScroll = () => {
        this.checkVideoAreaInViewportDebounced();
    }

    protected setHomeworkIsAvailableForStudents = () => {
        this.setState(() => {
            return {
                homeworkIsAvailableForStudents: true
            }
        });
    }

    protected checkVideoAreaInViewport = () => {
        if (this.lessonConferenceRef.current) {
            const confCurrentMode = this.lessonConferenceRef.current.getCurrentMode();
            const mainZoneObj = this.lessonConferenceRef.current.getMainZoneObj();

            if (
                (confCurrentMode === ConferenceModeEnum.FULL_SCREEN)
                || (confCurrentMode === ConferenceModeEnum.DOCUMENT_IN_PICTURE)
                || (mainZoneObj === null)
            ) {
                return;
            }

            const mainZoneRect = mainZoneObj.getBoundingClientRect();

            const result = (mainZoneRect.top<100)?ViewportHelper.fullIsInViewport(mainZoneObj):true;

            if (this.state.videoAreaInViewport !== result) {
                this.setState(() => {
                    return {
                        videoAreaInViewport: result
                    }
                });

                this.lessonConferenceRef.current.setCurrentMode(
                    (result) ? ConferenceModeEnum.NORMAL : ConferenceModeEnum.FLOAT_ON_PAGE
                );
            }
        }

        if (this.state.windowIsLarge !== this.mediaQueryLarge.matches) {
            this.setState(() => {
                return {
                    windowIsLarge: this.mediaQueryLarge.matches
                }
            });
        }
    }

    protected leaveFromRoom = (reason: LeaveFromRoomReasonEnum) => {
        if (!this.state.onlineRoomIsConnected) {
            return;
        }

        this.props.setBeforeUnloadMessage(null);

        if (reason === LeaveFromRoomReasonEnum.USER_WANT) {
            this.lessonConferenceRef.current?.stopConnection();

            this.wsClient.query(
                ApiMethodEnum.LESSON_ROOM_LEAVE_FROM_ROOM,
                {},
                null,
                true
            ).then();

            this.setState(() => {
                return {
                    onlineRoomIsOpened: false,
                    onlineRoomIsConnected: false
                }
            });

            this.props.clearActiveRoomState();
            this.props.resetLessonMaterialsState();

            return;
        }

        if (reason === LeaveFromRoomReasonEnum.ROUTER_EVENT) {
            this.lessonConferenceRef.current?.stopConnection();

            this.props.leaveFromRoomViaRouter();

            return;
        }

        if (reason === LeaveFromRoomReasonEnum.WS_DISCONNECTED) {
            this.lessonConferenceRef.current?.emergencyShutdown();

            this.setState(() => {
                return {
                    needReconnectToToRoomAfterWsRecovery: this.state.onlineRoomIsConnected,
                }
            });

            this.props.clearActiveRoomState();

            return;
        }
    }

    protected linkToGroup = () => {
        if (this.state.groupIntId === null) {
            return '#';
        }

        return RoutesHelper.replaceParams(
            RoutesList.TEACHER_GROUP_INFO,
            [
                {
                    key: 'groupId',
                    value: this.state.groupIntId.toString(10)
                }
            ]
        )
    }

    protected addUserProfileDetailsToList = (items: DtoUserProfileInfoBySchoolStudentProfile[], students: StudentItem[]) => {
        items.forEach((item) => {
            const indexInStudents = students.findIndex(existListItem => existListItem.stId === item.stApiStudentId);

            if (indexInStudents > -1) {
                students[indexInStudents].myLessonsUserId = item.id;
                students[indexInStudents].avatarFileId = item.avatarFileId;
            }
        });

        return students;
    }

    protected fetchBaseLessonData = (_afterDisconnect: boolean) => {
        if (
            (this.props.stToken === null)
            || (this.state.lessonIntId === null)
            || (this.state.groupIntId === null)
            || (this.props.apiToken === null)
        ) {
            return null;
        }

        // this.setState(
        //     () => {
        //         return {
        //             loadingState: LoadingState.LOADING
        //         }
        //     }
        // );

        if (this.baseInfoAbortController !== null) {
            this.baseInfoAbortController.abort();
            this.baseInfoAbortController = null;
        }

        this.baseInfoAbortController = new AbortController();

        const fetchPromises: [Promise<[DtoLessonDataResponse, DtoStudentInLessonListItem[]]>, Promise<BaseResponseDto<DtoLessonRoomOverviewTeacherResponse>>] = [
            this.stonlineApiClient.getLessonData(
                this.props.stToken,
                this.state.lessonIntId,
                this.baseInfoAbortController
            ),
            this.httpApiClient.getLessonRoomOverview(
                this.props.apiToken,
                this.state.groupIntId,
                this.state.lessonIntId,
                this.baseInfoAbortController
            )
        ];

        Promise.all(fetchPromises)
            .then(async (value) => {
                // Данные из stonline
                const stResponse = value[0];
                const overviewResponse = value[1];

                let students: StudentItem[] = stResponse[1].map((item) => {
                    return {
                        name: item.studentName,
                        shortName: StringHelper.extractFirstAndMiddleName(item.studentName),
                        stId: item.studentId,
                        myLessonsUserId: null,
                        avatarFileId: null
                    }
                });

                if (overviewResponse.data.lessonStudentProfiles.list.length > 0) {
                    // Есть профили пользователей, пришедшие вместе с overview
                    students = this.addUserProfileDetailsToList(overviewResponse.data.lessonStudentProfiles.list, students);
                }

                if (
                    (this.props.apiToken !== null)
                    && (overviewResponse.data.lessonStudentProfiles.list.length !== students.length)
                ) {
                    // Не про всех участников урока пришла информация. Дозапросим.
                    const profiles = await this.httpApiClient
                        .getUserProfileInfoBySchoolStudentProfile(this.props.apiToken, students.map(item => item.stId));

                    students = this.addUserProfileDetailsToList(profiles.data.list, students);
                }

                this.setState(
                    () => {
                        return {
                            lessonDto: stResponse[0],
                            studentInLessonItems: stResponse[1],
                            loadingState: LoadingState.SUCCESS,
                            students: students,
                            lessonMaterialsList: overviewResponse.data.lessonMaterialsList
                                .list.map((value) => {
                                    return plainToInstance(DtoLessonMaterialItem, value);
                                }),
                            lessonHomeworkMaterialsList: overviewResponse.data.lessonHomeworkMaterialsList
                                .list.map((value) => {
                                    return plainToInstance(DtoLessonMaterialHomeworkItem, value);
                                }),
                            homeworkIsAvailableForStudents: overviewResponse.data.homeworkIsAvailableForStudentsNow,
                            lessonRoomId: overviewResponse.data.id,
                            onlineRoomHasVideo: overviewResponse.data.hasVideo,
                            onlineRoomIsOpened: (
                                (overviewResponse.data.roomOpenedAt !== null)
                                && (overviewResponse.data.roomClosedAt === null)
                            )
                        }
                    },
                    () => {
                        if (this.state.needReconnectToToRoomAfterWsRecovery) {
                            this.joinToRoom();
                        }
                    }
                );
            })
            .catch((error) => {
                this.setState(() => {
                    return {
                        loadingState: LoadingState.ERROR
                    }
                });

                if (error instanceof RequestedDataNotFoundException) {
                    return;
                }

                const message = 'Error on fetch base lesson info: ';

                if (error instanceof NoConnection) {
                    this.logger.info(LoggerSectionsEnum.STONLINE_LESSONS_API, message, error);
                } else {
                    this.logger.error(LoggerSectionsEnum.STONLINE_LESSONS_API, message, error);
                }
            });
    }

    protected activeModeOnChange = (newValue: string) => {
        this.setState(() => {
            return {
                activeMode: newValue as PageModeEnum
            }
        });
    }

    protected updateStudentInLessonInContext = (newDto: DtoStudentInLessonListItem) => {
        const newStudentInLessonList = this.state.studentInLessonItems.map((item) => {
            if (item.id !== newDto.id) {
                return item;
            }

            return newDto;
        });

        this.setState(() => {
            return {
                studentInLessonItems: newStudentInLessonList
            }
        });
    }

    protected connectToConferenceRoom = async (connectionParams: RoomConnectionParams) => {
        if (this.props.userId === null) {
            throw new Error('Not found user id');
        }

        this.lessonConferenceRef.current?.startConnection(connectionParams);
    }

    protected joinToRoomOnClick = async (): Promise<void> => {
        const userDeviceIsReadyForCalls = await this.mediaDeviceService.userDeviceIsReadyForCalls();

        // Когда пользователь входит в комнату по собственному клику
        if ((this.state.onlineRoomHasVideo) && (!userDeviceIsReadyForCalls)) {
            this.setState(() => {
                return {
                    videoRoomError: VideoRoomErrorModalTypeEnum.NOT_FOUND_CAPTURE_DEVICES
                }
            });

            return;
        }

        await this.joinToRoom();
    }

    protected joinToRoom = async (): Promise<void> => {
        if ((this.state.lessonRoomId === null) || (!this.state.onlineRoomIsOpened)) {
            throw new Error(t`Не удалось выполнить операцию`);
        }

        if (this.state.onlineRoomHasVideo) {
            // Проверим - было ли ранее в этом сеансе дано разрешение на камеру
            if (this.props.accessToCameraAllowedInSession !== true) {
                this.props.setCameraRequestAccessNow(true);

                const cameraAccessPromise = new Promise<boolean>((resolve) => {
                    this.accessToCameraPromiseResolve = resolve;
                });

                const result = await cameraAccessPromise;

                if (!result) {
                    throw new Error(t`Не удалось получить доступ к камере и микрофону`);
                }
            }
        }

        if (!this.wsClient.connectionIsOpen()) {
            throw new Error(t`Подключение не полностью восстановлено`);
        }

        const requestDto = new DtoJoinToRoomRequest();
        requestDto.lessonId = this.state.lessonRoomId;

        const roomDetails = await this.wsClient.queryAsPromise<DtoJoinToRoomResponse>(
            ApiMethodEnum.LESSON_ROOM_JOIN_TO_ROOM,
            requestDto,
            DtoJoinToRoomResponse
        );

        let connectionParams: RoomConnectionParams | null = null;

        if (roomDetails.response.result.videoRoomParams !== null) {
            connectionParams = {
                turnServerEntrypoint: roomDetails.response.result.videoRoomParams.turnServerEntrypoint,
                turnServerTlsEntrypoint: roomDetails.response.result.videoRoomParams.turnServerTlsEntrypoint,
                turnServerLogin: roomDetails.response.result.videoRoomParams.turnServerLogin,
                turnServerPassword: roomDetails.response.result.videoRoomParams.turnServerPassword,
                janusWsApiEntrypoint: roomDetails.response.result.videoRoomParams.janusWsApiEntrypoint,
                janusApiKey: roomDetails.response.result.videoRoomParams.janusApiKey,
                janusRoomId: roomDetails.response.result.videoRoomParams.janusRoomId,
                janusJoinKey: roomDetails.response.result.videoRoomParams.janusJoinKey,
                expectedNumberOfStudents: this.state.students.length
            };
        }

        this.props.setBeforeUnloadMessage(t`Чтобы не выходить из комнаты не уходите со страницы`);

        this.props.setRoomConnectionState(LessonRoomStateTypes.ACTIVE);
        this.props.setRoomConnectionParams(connectionParams);
        this.props.setCurrentRoomId(this.state.lessonRoomId);

        if (connectionParams !== null) {
            await this.connectToConferenceRoom(connectionParams);
        }

        this.setState(() => {
            return {
                onlineRoomIsConnected: true,
                homeworkIsAvailableForStudents: true
            }
        });

        this.props.loadLessonRoomMembers(this.state.lessonRoomId);
    }

    protected startOnlineRoomOnClick = async (noVideo: boolean): Promise<void> => {
        const userDeviceIsReadyForCalls = await this.mediaDeviceService.userDeviceIsReadyForCalls();

        // Когда пользователь входит в комнату по собственному клику
        if ((!noVideo) && (!userDeviceIsReadyForCalls)) {
            this.setState(() => {
                return {
                    videoRoomError: VideoRoomErrorModalTypeEnum.NOT_FOUND_CAPTURE_DEVICES
                }
            });

            return;
        }

        await this.startOnlineRoom(noVideo);
    }

    protected startOnlineRoom = async (noVideo: boolean): Promise<void> => {
        if (
            (this.state.groupIntId === null)
            || (this.state.lessonDto === null)
            || (this.state.lessonIntId === null)
            || (this.props.userId === null)
            || (this.props.stToken === null)
        ) {
            throw new Error(t`Не удалось выполнить операцию`);
        }

        if (this.state.lessonDto?.isDraft === '1') {
            // Нужно вывести занятие из состояния "черновик"
            await this.stonlineApiClient.updateLessonData(
                this.props.stToken,
                parseInt(this.state.lessonDto.id),
                parseInt(this.state.lessonDto.teacherId),
                this.state.lessonDto.lessonDate,
                this.state.lessonDto.timeStart,
                this.state.lessonDto.timeEnd,
                this.state.lessonDto.homeTask,
                this.state.lessonDto.comment,
                this.state.lessonDto.isFake === '1',
                false
            );

            if (this.state.lessonDto !== null) {
                const newLessonDto = {...this.state.lessonDto};
                newLessonDto.isDraft = '0';

                this.setState(() => {
                    return {
                        lessonDto: newLessonDto
                    };
                })
            }

        }

        if (!noVideo) {
            // Проверим - было ли ранее в этом сеансе дано разрешение на камеру
            if (this.props.accessToCameraAllowedInSession !== true) {
                this.props.setCameraRequestAccessNow(true);

                const cameraAccessPromise = new Promise<boolean>((resolve) => {
                    this.accessToCameraPromiseResolve = resolve;
                });

                const result = await cameraAccessPromise;

                if (!result) {
                    throw new Error(t`Не удалось получить доступ к камере и микрофону`);
                }
            }
        }

        const wsClient = container.resolve<IWsApiClient>(DiTokens.WS_CLIENT);

        if (!wsClient.connectionIsOpen()) {
            throw new Error(t`Подключение не полностью восстановлено`);
        }

        const requestDto = new DtoTeacherStartOnlineLesson();
        requestDto.stGroupId = this.state.groupIntId;
        requestDto.stLessonId = this.state.lessonIntId;
        requestDto.noVideo = noVideo;

        // Инициализируем комнату
        const result = await wsClient.queryAsPromise<DtoTeacherStartOnlineLessonResult>(
            ApiMethodEnum.LESSON_TEACHER_START_ONLINE,
            requestDto,
            DtoTeacherStartOnlineLessonResult
        );

        if (result.response.status !== WsResponseStatusEnum.OK) {
            this.logger.error(
                LoggerSectionsEnum.LESSON_ROOM,
                `Error on start lesson room: `
                + `stGroupId ${this.state.groupIntId}, `
                + `stLessonId ${this.state.lessonIntId} `
                + `status ${result.response.status}, error text: `,
                JSON.stringify(result.response.errors)
            );

            throw new Error('Не удалось запустить комнату');
        }

        this.setState(() => {
            return {
                onlineRoomIsOpened: true,
                onlineRoomHasVideo: !noVideo
            }
        });

        await this.joinToRoom();
    }

    protected closeOnlineRoom = async (): Promise<void> => {
        if ((!this.state.onlineRoomIsConnected) || (!this.state.lessonRoomId)) {
            return;
        }

        const wsClient = container.resolve<IWsApiClient>(DiTokens.WS_CLIENT);

        const dto = new DtoStopOnlineLessonRequest();
        dto.lessonId = this.state.lessonRoomId;

        await wsClient.queryAsPromise<null>(
            ApiMethodEnum.LESSON_TEACHER_STOP_ONLINE,
            dto,
            null
        );

        this.leaveFromRoom(LeaveFromRoomReasonEnum.USER_WANT);
    }

    protected setSelectedRoomMemberId = (memberId: string | null) => {
        this.setState(() => {
            return {
                selectedRoomMemberId: memberId
            }
        })
    }

    protected updateLessonDto = (newLessonDto: DtoLessonDataResponse) => {
        this.setState(() => {
            return {
                lessonDto: newLessonDto
            }
        });
    }

    protected modePage = () => {
        return <>
            {/*GENERAL*/}
            <div style={{display: (this.state.activeMode === PageModeEnum.GENERAL) ? "block" : "none"}}
                 key={"general_mode"}>
                <GeneralModePage/>
            </div>

            {/*ON_LESSON*/}
            <div style={{display: (this.state.activeMode === PageModeEnum.ON_LESSON) ? "block" : "none"}}
                 key={"on_lesson_mode"}>
                <OnLessonModePage/>
            </div>

            {/*HOMEWORK*/}
            <div style={{display: (this.state.activeMode === PageModeEnum.HOMEWORK) ? "block" : "none"}}
                 key={"homework_mode"}>
                <HomeworkModePage/>
            </div>
        </>;
    }

    protected setLessonMaterialsList = (items: DtoLessonMaterialItem[]) => {
        this.setState(() => {
            return {
                lessonMaterialsList: items
            }
        });
    }

    protected setLessonHomeworkMaterialsList = (items: DtoLessonMaterialHomeworkItem[]) => {
        this.setState(() => {
            return {
                lessonHomeworkMaterialsList: items
            }
        });
    }

    protected content = () => {
        switch (this.state.loadingState) {
            case LoadingState.NOT_INIT:
            case LoadingState.LOADING: {
                return <PageLoaderWrapper><DefaultLoader/></PageLoaderWrapper>;
            }
            case LoadingState.ERROR: {
                return <ErrorLoadingContent retryBtnClick={() => this.fetchBaseLessonData(false)}/>;
            }
            case LoadingState.SUCCESS: {
                return <>
                    <PageMainContentWrapper>
                        <LessonConferenceStyled
                            ref={this.lessonConferenceRef}
                            lessonId={this.state.lessonRoomId ?? ''}
                            selectedMemberId={this.state.selectedRoomMemberId}
                            onMemberSelect={this.setSelectedRoomMemberId}
                            wsRoomIsConnected={this.state.onlineRoomIsConnected && this.state.onlineRoomHasVideo}
                        />
                        <HorizontalNavigation onItemChange={this.activeModeOnChange}
                                              activeItemId={this.state.activeMode}
                                              viewMode={ViewModeEnum.BOOKMARKS}
                                              items={[
                                                  {
                                                      id: PageModeEnum.GENERAL,
                                                      name: t`Основное`
                                                  },
                                                  {
                                                      id: PageModeEnum.ON_LESSON,
                                                      name: t`Работа на уроке`
                                                  },
                                                  {
                                                      id: PageModeEnum.HOMEWORK,
                                                      name: t`Домашняя работа`
                                                  }
                                              ]}
                        />
                        {this.modePage()}
                    </PageMainContentWrapper>
                    <RoomControlBlock showLower={
                        this.state.onlineRoomIsConnected
                        && this.state.onlineRoomHasVideo
                        && !this.state.videoAreaInViewport
                        && this.mediaQueryLarge.matches
                    }
                    />
                </>
            }
        }
    }

    render() {
        return <div>
            <LessonPageContextProvider value={{
                studentInLessonItems: this.state.studentInLessonItems,
                lessonRoomId: this.state.lessonRoomId,
                lessonDto: this.state.lessonDto,
                updateStudentInLessonInCache: this.updateStudentInLessonInContext,
                students: this.state.students,
                groupIntId: this.state.groupIntId,
                lessonIntId: this.state.lessonIntId,
                lessonMaterialsList: this.state.lessonMaterialsList,
                lessonHomeworkMaterialsList: this.state.lessonHomeworkMaterialsList,
                setLessonMaterialsList: this.setLessonMaterialsList,
                setLessonHomeworkMaterialsList: this.setLessonHomeworkMaterialsList,
                lessonMaterialsPageRef: this.lessonMaterialsPageRef,
                lessonHomeworkMaterialsPageRef: this.lessonHomeworkMaterialsPageRef,
                homeworkIsAvailableForStudents: this.state.homeworkIsAvailableForStudents,
                setHomeworkIsAvailableForStudents: this.setHomeworkIsAvailableForStudents,
                onlineRoomIsConnected: this.state.onlineRoomIsConnected,
                onlineRoomIsOpened: this.state.onlineRoomIsOpened,
                onlineRoomHasVideo: this.state.onlineRoomHasVideo,
                setSelectedRoomMemberId: this.setSelectedRoomMemberId,
                selectedRoomMemberId: this.state.selectedRoomMemberId,
                startOnlineRoom: this.startOnlineRoomOnClick,
                joinToRoom: this.joinToRoomOnClick,
                closeOnlineRoom: this.closeOnlineRoom,
                updateLessonDto: this.updateLessonDto
            }}>
                {
                    (!this.state.onlineRoomIsConnected)
                    && <PageBackLink text={t`Обратно к группе`} link={this.linkToGroup()}/>
                }
                <PageTitle><Trans>Комната урока</Trans></PageTitle>
                <PageWrapper>
                    {this.content()}
                </PageWrapper>
                {
                    (this.state.videoRoomError !== null)
                    && <VideoRoomErrorModal errorType={this.state.videoRoomError} onOk={() => {
                        return this.setState(() => {
                            return {
                                videoRoomError: null
                            }
                        });
                    }}/>
                }
            </LessonPageContextProvider>
        </div>;
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setRoomConnectionParams: (connectionParams: RoomConnectionParams | null) =>
        dispatch(setRoomConnectionParams(connectionParams)),
    setRoomConnectionState: (state: LessonRoomStateTypes) =>
        dispatch(setLessonRoomState(state)),
    setCurrentRoomId: (roomId: string | null) =>
        dispatch(setLessonRoomId(roomId)),
    setCameraRequestAccessNow: (value: boolean) =>
        dispatch(setCameraRequestAccessNow(value)),
    loadLessonRoomMembers: (roomId: string) =>
        dispatch(loadRoomMembersList(roomId)),
    clearActiveRoomState: () =>
        dispatch(clearRoomState()),
    leaveFromRoomViaRouter: () =>
        dispatch(handleLeaveFromRoomViaRouter()),
    resetLessonMaterialsState: () =>
        dispatch(resetLessonMaterialsState()),
    setBodyScrollAvailable: (value: boolean) =>
        dispatch(setBodyScrollAvailable(value)),
    setBeforeUnloadMessage: (value: string | null) => {
        dispatch(setRouteLeaveConfirmMessage(value));
    }
});

const mapStateToProps = (state: ApplicationState) => ({
    userId: (state.user.profileData?.id) ?? null,
    stToken: state.user.stToken,
    apiToken: state.user.sessionToken,
    currentStUserId: (state.user.profileData) ? state.user.profileData.schools[0].accounts[0].stId : null,
    accessToCameraAllowedInSession: state.app.cameraAccess.allowedInSession,
    accessToCameraRequestingNow: state.app.cameraAccess.requestAccessNow,
    currentTheme: state.layout.activeTheme,
    wsStatus: state.app.wsConnectionStatus,
    roomMembers: roomMembersSelector(state)
});

const connector = connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(TeacherLessonPage));
//
// export default withRouter(connect(mapStateToProps)(TeacherLessonPage));
