import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {PageLoadStatus} from "../../teacher/knowledge-base/common/Enums";
import {
    DtoStudentMainPageDataResponse
} from "../../../../components/HttpApiClient/ApiDto/Response/MainPage/DtoStudentMainPageDataResponse";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {ILogger} from "../../../../components/Logger/ILogger";
import {PopupActions} from "reactjs-popup/dist/types";
import {CanceledByUser} from "../../../../components/HttpApiClient/Exception/CanceledByUser";
import {NoConnection} from "../../../../components/HttpApiClient/Exception/NoConnection";
import {LoggerSectionsEnum} from "../../../../components/Logger/LoggerSectionsEnum";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../store/app/selector";
import {
    selectedAgreement as selectedAgreementSelector,
    selectedUserInSchool as selectedUserInSchoolSelector
} from "../../../../store/user/selector";
import {
    UserAgreementDto
} from "../../../../components/HttpApiClient/ApiDto/Response/User/GetUserAgreementResponse/UserAgreementDto";
import {LoaderWrapper, MainPageItemsWrapper} from "./style";
import {Header} from "./sections/Header";
import {PayOnlineResultPopup} from "../../../components/PayOnlineResultPopup";
import {DefaultLoader} from "../../../components/DefaultLoader";
import {ErrorLoadingContent} from "../../../components/Ui/Elements/ErrorLoadingContent";
import {SelfStudySubscriptionsLarge} from "./sections/SelfStudySubscriptionsLarge";
import {ScheduleAndHometask} from "./sections/ScheduleAndHometask";
import {BannersBlock} from "./sections/BannersBlock";
import {AverageValues} from "./sections/AverageValues";
import LastLessons from "./sections/LastLessons";
import {PayOnline} from "./sections/PayOnline";
import {EnablePushNotificationsBanner} from "../../../components/Ui/Elements/Banners/EnablePushNotificationsBanner";
import {
    pushNotificationsSupportedSelector,
    pushSubscriptionStateSelector
} from "../../../../store/serviceWorker/selector";
import {IDeviceDetector} from "../../../../components/DeviceDetector/IDeviceDetector";
import {pushNotificationBannerMutedSelector} from "../../../../store/commonPersisted/selector";

export const SchoolContractMainPage: React.FC = () => {
    const sessionToken = useSelector(sessionTokenSelector);
    const selectedAgreement = useSelector(selectedAgreementSelector);
    const selectedUserInSchool = useSelector(selectedUserInSchoolSelector);
    const pushSubscriptionState = useSelector(pushSubscriptionStateSelector);
    const pushSubscriptionSupported = useSelector(pushNotificationsSupportedSelector);
    const pushNotificationBannerMuted = useSelector(pushNotificationBannerMutedSelector);

    const [pageLoadStatus, setPageLoadStatus] = useState<PageLoadStatus>(PageLoadStatus.NOT_INIT);
    const [pageData, setPageData] = useState<DtoStudentMainPageDataResponse | null>(null);
    const [lastLessonTeacherPersonalComment, setLastLessonTeacherPersonalComment] = useState<string | null>(null);
    const [onlinePaymentId, setOnlinePaymentId] = useState<string | null>(null);

    const httpApiClient = useMemo(() => container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT), []);
    const logger = useMemo(() => container.resolve<ILogger>(DiTokens.LOGGER), []);
    const abortController = useRef<AbortController | null>(null);
    const payOnlineResultModal = useRef<PopupActions>(null);

    const checkPaymentResultParam = useCallback(() => {
        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);
        const paymentId = params.get('payment-id');

        if (paymentId === null) {
            return;
        }

        // Похоже, пользователь пришёл из онлайн оплаты. Покажем результат.
        setOnlinePaymentId(paymentId);
    }, []);

    useEffect(() => {
        if (onlinePaymentId !== null) {
            payOnlineResultModal.current?.open();
        }
    }, [onlinePaymentId])

    const fetchPageContent = useCallback((selectedAgreement: UserAgreementDto) => {
        if (abortController.current !== null) {
            abortController.current.abort();
            abortController.current = null;
        }

        if (!sessionToken) {
            setPageLoadStatus(PageLoadStatus.ERROR);

            return;
        }

        setPageLoadStatus(PageLoadStatus.LOADING);

        abortController.current = new AbortController();

        httpApiClient
            .getSchoolStudentMainPageContent(sessionToken, selectedAgreement.id)
            .then((response) => {

                const lastLessonItem = (response.data.lastLessons.list.length > 0) ? response.data.lastLessons.list[0] : null;
                let lastLessonTeacherPersonalComment: string | null = null;

                if (lastLessonItem) {
                    lastLessonTeacherPersonalComment = lastLessonItem.comment;
                }

                setPageLoadStatus(PageLoadStatus.SUCCESS);
                setPageData(response.data);
                setLastLessonTeacherPersonalComment(lastLessonTeacherPersonalComment)
            })
            .catch((error) => {
                if (error instanceof CanceledByUser) {
                    return;
                }

                const message = 'Error fetch school student main page content';

                if (error instanceof NoConnection) {
                    logger.info(LoggerSectionsEnum.SCHOOL_STUDENT_MAIN_PAGE, message, error);
                } else {
                    logger.error(LoggerSectionsEnum.SCHOOL_STUDENT_MAIN_PAGE, message, error);
                }

                setPageLoadStatus(PageLoadStatus.ERROR);
            });

    }, [httpApiClient, logger, sessionToken]);
    
    const pushNotificationsBannerVisible = useMemo(() => {
        const deviceDetector = container.resolve<IDeviceDetector>(DiTokens.DEVICE_DETECTOR);
        
        return  (
            (pushSubscriptionState === false)
            && pushSubscriptionSupported 
            && !deviceDetector.isMobileSafari()
            && !deviceDetector.isIPhone()
            && !pushNotificationBannerMuted
            // TODO Заблокировать на desktop, чтобы не вводить в заблуждение пользователя
            //  (вдруг подумает, что на телефоне автоматически фича включится)
        );
    }, [pushNotificationBannerMuted, pushSubscriptionState, pushSubscriptionSupported]);

    useEffect(() => {
        checkPaymentResultParam();

        return () => {
            if (abortController.current !== null) {
                abortController.current.abort();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedAgreement !== null) {
            fetchPageContent(selectedAgreement);
        }
    }, [fetchPageContent, selectedAgreement]);

    const content = useMemo(() => {
        switch (pageLoadStatus) {
            case PageLoadStatus.NOT_INIT:
            case PageLoadStatus.LOADING: {
                return <LoaderWrapper>
                    <DefaultLoader simple={true}/>
                </LoaderWrapper>;
            }
            case PageLoadStatus.ERROR: {
                return <ErrorLoadingContent retryBtnClick={() => {
                    if (selectedAgreement) {
                        fetchPageContent(selectedAgreement)
                    }
                }}/>;
            }

            case PageLoadStatus.SUCCESS: {
                if ((pageData === null) || (selectedAgreement === null)) {
                    return <></>;
                }

                return <>
                    {
                        (pageData.nextLessonInfo.totalCount > 0)
                        && <ScheduleAndHometask hometaskData={pageData.hometaskData}
                                                lastLessonTeacherPersonalComment={lastLessonTeacherPersonalComment ?? undefined}
                                                primaryTeacher={pageData.primaryTeacher}
                                                nextLessonInfo={pageData.nextLessonInfo.list[0]}
                                                nextLessonsList={pageData.nextLessonsList.list}
                        />
                    }
                    {
                        (pageData.selfStudyTrackSubscriptions.list)
                        && <SelfStudySubscriptionsLarge items={pageData.selfStudyTrackSubscriptions.list}/>
                    }
                    <BannersBlock/>
                    <AverageValues values={pageData.averageResults}/>
                    <LastLessons presetData={pageData.lastLessons}
                                 currentAgreementId={selectedAgreement.id}/>
                    {
                        (selectedUserInSchool) && (selectedUserInSchool.onlinePaymentEnabled)
                        && <PayOnline acquiringDocuments={pageData.onlinePaymentCommonDocuments.list}/>
                    }
                </>;
            }
        }
    }, [fetchPageContent, lastLessonTeacherPersonalComment, pageData, pageLoadStatus, selectedAgreement, selectedUserInSchool]);

    return <MainPageItemsWrapper>
        <Header/>
        {/*{*/}
        {/*    (pushNotificationsBannerVisible)*/}
        {/*    && <EnablePushNotificationsBanner/>*/}
        {/*}*/}
        {
            onlinePaymentId
            && <PayOnlineResultPopup orderId={onlinePaymentId}
                                     ref={payOnlineResultModal}/>
        }
        {content}
    </MainPageItemsWrapper>;
}

SchoolContractMainPage.displayName = 'SchoolContractMainPage';