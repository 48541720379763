import React, {useCallback, useRef} from "react";
import {t, Trans} from "@lingui/macro";
import {PopupActions} from "reactjs-popup/dist/types";
import {BaseBanner} from "./BaseBanner";
import {OtpAuthPopup} from "../../../OtpAuthPopup";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../../../store";
import {ITheme} from "../../../../../services/theme/ITheme";

export const SaveYourAchievementsBanner = () => {
    const otpModalRef = useRef<PopupActions>(null);

    const currentTheme = useSelector<ApplicationState>(
        ({layout}: ApplicationState) => layout.activeTheme
    ) as ITheme;

    const onClick = useCallback(() => {
        otpModalRef.current?.open();

        return Promise.resolve();
    }, []);

    return <BaseBanner
        title={t`Сохраните достижения!`}
        text={<Trans>Укажите email,<br/>чтобы не потерять прогресс.</Trans>}
        buttonLabel={t`Сохранить достижения`}
        accentColorHex={currentTheme.otherColors.saveYourAchievementsBannerPrimary}
        rectangleImgPath={'/img/banners/save-your-achievements/save-your-achievements-rectangle-v2.jpg'}
        squareImgPath={'/img/banners/save-your-achievements/save-your-achievements-square-v2.jpg'}
        onClick={onClick}
    >
        <OtpAuthPopup ref={otpModalRef}/>
    </BaseBanner>
}