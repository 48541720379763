import * as React from "react";
import {SelectedItemTitle} from "../index";
import {ModalResultType, TmDisciplineModal} from "./modal";
import {
    DtoTmDiscipline
} from "../../../../../../components/HttpApiClient/ApiDto/Response/TmDisciplines/DtoTmDiscipline";

interface DisciplineTitleItemProps {
    organizationId: string;
    item: DtoTmDiscipline;
    disciplines: DtoTmDiscipline[] | null;
    setDiscipline: (value: DtoTmDiscipline[] | null) => void;
    onUpdate?: () => void;
    onDelete?: () => void;
}

export const DisciplineTitleItem: React.FC<DisciplineTitleItemProps> = (props) => {

    const updateItemInDisciplinesPropsList = (newModel: DtoTmDiscipline) => {
        if (props.disciplines === null || props.setDiscipline === null) {
            return;
        }

        const newDisciplinesList = props.disciplines.map((item) => (
            (newModel.id === item.id) ? newModel : item
        ));

        props.setDiscipline(newDisciplinesList);

        if (props.onUpdate) {
            props.onUpdate();
        }
    }

    const deleteCurrentItemFromDisciplinesPropsList = () => {
        if (props.disciplines === null || props.setDiscipline === null) {
            return;
        }

        const newDisciplinesList = props.disciplines.filter((item) => (
            (props.item.id !== item.id)
        ));

        props.setDiscipline(newDisciplinesList);

        if (props.onDelete) {
            props.onDelete();
        }
    }

    const onResult = (action: ModalResultType, data: DtoTmDiscipline | null) => {
        if (action === ModalResultType.UPDATED) {
            if (data === null) {
                throw new Error('Received null model after discipline updated.');
            }

            updateItemInDisciplinesPropsList(data);

            return;
        }

        deleteCurrentItemFromDisciplinesPropsList();
    }

    return (
        <TmDisciplineModal
            organizationId={props.organizationId}
            disciplineItem={props.item}
            result={onResult}
            trigger={<SelectedItemTitle>{props.item.name}</SelectedItemTitle>}/>
    );
}