import {Reducer} from "redux";
import {LayoutActionTypes, LayoutState} from "./type";
import {initialState} from "./initialState";
import produce from "immer";

const reducer: Reducer<LayoutState> = produce((draft: LayoutState, action) => {
    switch (action.type) {
        case LayoutActionTypes.SET_SPLASH_SCREEN_VISIBLE: {
            draft.splashScreenVisible = action.payload;

            break;
        }
        case LayoutActionTypes.SET_RIGHT_PANEL_VISIBLE: {
            draft.rightPanelVisible = action.payload;

            break;
        }
        case LayoutActionTypes.SET_ACTIVE_THEME_NAME: {
            draft.activeThemeName = action.payload;

            break;
        }
        case LayoutActionTypes.SET_ACTIVE_THEME: {
            draft.activeTheme = action.payload;

            break;
        }
        case LayoutActionTypes.SET_BODY_SCROLL_AVAILABLE: {
            draft.bodyScrollAvailable = action.payload;

            break;
        }
        case LayoutActionTypes.SET_CONTENT_LAYOUT_SETTINGS: {
            draft.contentLayoutSettings = action.payload;

            break;
        }
    }
}, initialState);

export {reducer as layoutReducer}
