import React, {useCallback} from "react";
import {RoutesHelper} from "../../../../helpers/RoutesHelper";
import {RoutesList} from "../../../RoutesList";
import {ContentList, ContentListProps} from "../../../components/Ui/Elements/ContentList";
import {ListItem} from "../../../components/Ui/Elements/ContentList/ListItem";
import {
    DtoSelfStudyTrackListItem
} from "../../../../components/HttpApiClient/ApiDto/Response/SelfStudyTrack/DtoSelfStudyTrackListItem";

interface ItemsGridProps extends Omit<ContentListProps, 'children'> {
    items: DtoSelfStudyTrackListItem[];
}

export const ItemsList: React.FC<ItemsGridProps> = (
    {
        currentPageNum,
        totalItemsCount,
        itemsPerPage,
        onPageChange,
        items
    }
) => {
    const linkToTrack = useCallback((trackId: string): string => {
        return RoutesHelper.replaceParams(
            RoutesList.TEACHER_SELF_STUDY_TRACK_VIEW,
            [
                {
                    key: 'trackId',
                    value: trackId
                }
            ]
        );
    }, []);

    return <ContentList
        currentPageNum={currentPageNum}
        itemsPerPage={itemsPerPage}
        totalItemsCount={totalItemsCount}
        onPageChange={onPageChange}
    >
        {
            items.map((item) => {
                return <ListItem
                    key={`item${item.id}`}
                    link={linkToTrack(item.id)}
                    firstLine={item.name}
                    secondLine={
                        (item.internalDescription) ? <div>
                                {item.internalDescription}
                            </div>
                            : null
                    }
                />
            })
        }
    </ContentList>
}
