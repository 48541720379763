import * as React from "react";
import {useEffect, useMemo, useState} from "react";
import styled from 'styled-components';
import {BtnStyleEnum, Button} from "../../../components/Ui/Elements/Button";
import {t, Trans} from "@lingui/macro"
import {i18n} from '@lingui/core'
import {useLocation, useNavigate, useOutletContext, useParams} from "react-router-dom";
import {OutletContext} from "../index";
import {RoutesList} from "../../../RoutesList";
import {AnimatedContainer} from "./AnimatedContainer";
import {container} from 'tsyringe';
import {IHttpApiClient} from "../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {AccessDeniedException} from "../../../../components/HttpApiClient/Exception/AccessDeniedException";
import {RequestValidationError} from "../../../../components/HttpApiClient/Exception/RequestValidationError";
import {ILogger} from "../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../components/Logger/LoggerSectionsEnum";
import * as UserActionCreators from "../../../../store/user/actions";
import {useDispatch} from "react-redux";
import {UserProfileType} from "../../../../store/user/type";
import {matchPath} from "react-router";
import {BaseResponseDto} from "../../../../components/HttpApiClient/ApiDto/Response/BaseResponseDto";
import {AuthLoginDto} from "../../../../components/HttpApiClient/ApiDto/Response/Auth/LoginDto";


const Wrapper = styled.div`

`;

const InputsWrapper = styled.div`
  margin-bottom: 32px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const ButtonItem = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
`;

export enum SituationCodeEnum {
    PREPARING = 0,
    WAIT_FOR_RESPONSE = 1,
    SUCCESS = 2,
    INVALID_AUTH_TOKEN = 3,
    UNKNOWN_ERROR = 4
}

export interface LoginViaTokenFormProps {
}

export const LoginViaTokenForm: React.FC<LoginViaTokenFormProps> = (props: LoginViaTokenFormProps) => {
    const [situationCode, setSituationCode] = useState<SituationCodeEnum>(SituationCodeEnum.PREPARING);

    const dispatch = useDispatch();

    const outletContext = (useOutletContext() as OutletContext);
    const navigate = useNavigate();
    const params = useParams<{ token: string }>();
    const location = useLocation();

    const isTeacherLogin = useMemo<boolean>(() => {
        const pathname = location.pathname;

        return !!matchPath({path: RoutesList.TEACHER_LOGIN_VIA_TOKEN}, pathname);
    }, [location]);

    useEffect(() => {
        if ((situationCode === SituationCodeEnum.PREPARING) || (situationCode === SituationCodeEnum.WAIT_FOR_RESPONSE)) {
            outletContext.setLoader(true, i18n._(t`Автоматический вход...`));
        } else {
            outletContext.setLoader(false);
        }
    }, [situationCode, outletContext]);

    useEffect(() => {
        const method = async (): Promise<void> => {
            const setUserProfileType = (profileType: UserProfileType) => dispatch(UserActionCreators.setUserProfileType(profileType));
            const setSessionToken = (token: string) => dispatch(UserActionCreators.setUserSessionToken(token));
            const startLoadingUserProfile = () => dispatch(UserActionCreators.startLoadingUserProfile());

            // Выполняем запрос на получения токена сессии по токену быстрого входа
            const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
            const logger = container.resolve<ILogger>(DiTokens.LOGGER);

            try {
                if (!params.token) {
                    throw new Error("Empty auth token");
                }

                setSituationCode(SituationCodeEnum.WAIT_FOR_RESPONSE);
                let authResult: BaseResponseDto<AuthLoginDto>;

                if (isTeacherLogin) {
                    authResult = await httpApiClient.loginTeacherByAuthenticationToken(params.token, window.location.origin);
                } else {
                    authResult = await httpApiClient.loginByAuthenticationToken(params.token, window.location.origin);
                }

                // При внесении правок в процесс, не забыть про аналогичный блок
                // в {@see LoginForm} и {@see AppInit} и {@see TeacherLoginForm}.
                setUserProfileType((isTeacherLogin) ? UserProfileType.TEACHER : UserProfileType.STUDENT);
                setSessionToken(authResult.data.token);
                startLoadingUserProfile();

                setSituationCode(SituationCodeEnum.SUCCESS);
            } catch (e) {
                if (e instanceof AccessDeniedException) {
                    setSituationCode(SituationCodeEnum.UNKNOWN_ERROR);

                    return;
                }

                if (e instanceof RequestValidationError) {
                    setSituationCode(SituationCodeEnum.INVALID_AUTH_TOKEN);

                    return;
                }

                setSituationCode(SituationCodeEnum.UNKNOWN_ERROR);
                logger.error(LoggerSectionsEnum.UNKNOWN_API_RESPONSE_ERROR, e);

                return;
            }
        }

        if (situationCode === SituationCodeEnum.PREPARING) {
            method();
        }

    }, [situationCode, params.token, dispatch, isTeacherLogin]);

    const oldLinkText = useMemo(() => {
        if (isTeacherLogin) {
            return <Trans>Похоже, что ссылка для входа устарела. Попробуйте войти, используя номер телефона и
                пароль.</Trans>
        }

        return <Trans>Похоже, что ссылка для входа устарела. Попробуйте войти, используя свой e-mail адрес
            и пароль.</Trans>
    }, [isTeacherLogin]);

    const enterWithPassBtn = useMemo(() => {
        if (isTeacherLogin) {
            return <Button
                btnStyle={BtnStyleEnum.Primary}
                onClick={() => navigate(RoutesList.TEACHER_LOGIN_FORM)}>
                <Trans>Войти с паролем</Trans>
            </Button>;
        }

        return <Button
            btnStyle={BtnStyleEnum.Primary}
            onClick={() => navigate(RoutesList.LOGIN_FORM)}>
            <Trans>Войти с паролем</Trans>
        </Button>
    }, [isTeacherLogin, navigate]);

    const content = () => {
        if (situationCode !== SituationCodeEnum.SUCCESS) {
            return (
                <Wrapper>
                    <InputsWrapper>
                        <h2 style={{marginBottom: "1.5em"}}><Trans>Ошибка автоматического входа</Trans></h2>
                        <p>{oldLinkText}</p>
                        <p style={{fontSize: "11px", color: "gray"}}>
                            <Trans id="Код ситуации: {situationCode}" values={{situationCode: situationCode}}/>
                        </p>
                    </InputsWrapper>
                    <ButtonsWrapper>
                        <ButtonItem>
                            {enterWithPassBtn}
                        </ButtonItem>
                    </ButtonsWrapper>
                </Wrapper>
            );
        }

        return <div/>;
    }

    return (
        <AnimatedContainer>
            {content()}
        </AnimatedContainer>
    );
}