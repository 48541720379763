import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {repoStateSelector} from "../../../../../../store/fileFormatsRepo/selector";
import {FileFetchInfoProcessStatus, FileInfo} from "../../../../../../store/fileFormatsRepo/type";
import {QualityItem} from "../../../../Ui/Elements/AudioPlayer";
import * as FileFormatsRepoActionCreators from "../../../../../../store/fileFormatsRepo/actions";
import {VoiceoverPlayerMethods} from "../VoiceoverPlayerMethods";
import {SmallVoiceoverPlayer} from "./SmallVoiceoverPlayer";
import styled from "styled-components";
import {PlayerTypeEnum} from "./PlayerTypeEnum";


const VoiceoverPlayerPlaceholder = styled.div`
    min-height: 40px;
`;

interface VoiceoverPlayerProps {
    audioFileId: string;
    playerType: PlayerTypeEnum;
    onReady: () => void;
    onError: () => void;
    onEnd: () => void;
    alreadyCompleted: boolean;
}

export const VoiceoverPlayer = forwardRef<VoiceoverPlayerMethods, VoiceoverPlayerProps>(
    (
        {
            audioFileId,
            playerType,
            onReady,
            onError,
            onEnd,
            alreadyCompleted
        },
        ref
    ) => {

        const fileRepoState = useSelector(repoStateSelector);
        const audioPlayerRef = useRef<VoiceoverPlayerMethods>(null);

        const dispatch = useDispatch();

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({
            startPlay: () => {
                audioPlayerRef.current?.startPlay();
            }
        }));

        const currentFileInFileRepo = useMemo<FileInfo | null>(() => {
            const itemIndex = fileRepoState.indexByFileId[audioFileId];

            if (itemIndex === undefined) {
                return null;
            }

            return fileRepoState.files[itemIndex] ?? null;
        }, [fileRepoState, audioFileId]);


        const fileLinks = useMemo<QualityItem[] | null>(() => {
            if (currentFileInFileRepo === null) {
                return null;
            }

            if (currentFileInFileRepo.availableFormats.length === 0) {
                return null;
            }

            return currentFileInFileRepo.availableFormats.map((item): QualityItem => {
                return {
                    mimeType: item.mimeType,
                    url: item.url,
                    format: item.formatType
                }
            });
        }, [currentFileInFileRepo]);

        useEffect(() => {
            const fetchNewFileInfo = (fileId: string) =>
                dispatch(FileFormatsRepoActionCreators.fetchNewFileInfo(fileId));

            if (currentFileInFileRepo === null) {
                // Запросим ссылку
                fetchNewFileInfo(audioFileId);

                return;
            }

            switch (currentFileInFileRepo.fetchInfoStatus) {
                case FileFetchInfoProcessStatus.WAIT_FOR_START:
                case FileFetchInfoProcessStatus.SUCCESS:
                case FileFetchInfoProcessStatus.IN_PROCESS: {

                    break;
                }
                default: {
                    onError();

                    break;
                }
            }
        }, [currentFileInFileRepo, dispatch, audioFileId, onReady, onError]);

        const onPlayerReady = useCallback(() => {
            onReady();
        }, [onReady]);

        if (!fileLinks) {
            return <VoiceoverPlayerPlaceholder/>;
        }

        return <SmallVoiceoverPlayer
            ref={audioPlayerRef}
            playerSize={playerType}
            onReady={onPlayerReady}
            onError={onError}
            onEnd={onEnd}
            fileLinks={fileLinks}
            alreadyCompleted={alreadyCompleted}
        />
    }
)