import {ReactComponent as LogoSvg} from "../../../components/Ui/Svg/Logo.svg";
import {ReactComponent as LogoEnSvg} from "../../../components/Ui/Svg/LogoEn.svg";
import {ReactComponent as LogoDarkThemeSvg} from "../../../components/Ui/Svg/LogoDarkTheme.svg";
import {ReactComponent as LogoEnDarkThemeSvg} from "../../../components/Ui/Svg/LogoEnDarkTheme.svg";
import * as React from "react";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../../store";
import {ThemeEnum} from "../../../../services/theme/ThemeEnum";
import {useMemo} from "react";
import {container} from "tsyringe";
import {Ii18nService} from "../../../../services/i18n/Ii18nService";
import {DiTokens} from "../../../../di-factory/DiTokens";
import {LocaleEnum} from "../../../../store/i18n/type";

interface LogoProps {
    width: string;
}

export const Logo: React.FunctionComponent<LogoProps> = (props) => {
    const i18nService = useMemo(() => container.resolve<Ii18nService>(DiTokens.I18N_SERVICE), []);

    const DefaultLogo = useMemo(() => {
        return (i18nService.locale() === LocaleEnum.EN) ? LogoEnSvg : LogoSvg;
    }, [i18nService]);

    const DarkThemeLogo = useMemo(() => {
        return (i18nService.locale() === LocaleEnum.EN) ? LogoEnDarkThemeSvg : LogoDarkThemeSvg;
    }, [i18nService]);

    const themeName = useSelector<ApplicationState>(
        ({layout}: ApplicationState) => layout.activeThemeName
    ) as ThemeEnum;

    const LogoComponent = useMemo(() => {
        return (themeName === ThemeEnum.light) ? DefaultLogo : DarkThemeLogo;
    }, [DarkThemeLogo, DefaultLogo, themeName]);


    return <LogoComponent style={{width: props.width}}/>;
};