import React, {useCallback, useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {UserFileTypeEnum} from "../../../../../enums/UserFileEnums";
import * as UploadQueueActionCreators from "../../../../../store/uploadQueue/actions";
import {sessionTokenSelector} from "../../../../../store/app/selector";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {FileUploadProcessState} from "../../../../../store/uploadQueue/type";
import {VideoPlaceholder} from "./VideoPlaceholder";
import {ControlPanel} from "./ControlPanel";
import {t} from "@lingui/macro";
import {ElementContext, IElementContext} from "./ElementContext";
import {ElementStateEnum} from "../../../SlidePlayerEditorCommonParts/components/Video/Common";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";

interface UploadComponentProps {
}

export const UploadComponent: React.FC<UploadComponentProps> = () => {
    const elementContext = useContext<IElementContext>(ElementContext);

    const {
        elementData,
        elementState,
        setElementState,
        onChangeElementData,
        currentFileInUploadQueue
    } = elementContext;

    const [previewFileUrl, setPreviewFileUrl] = useState<string | null>(null);
    const [previewFile, setPreviewFile] = useState<File | null>(null);

    const dispatch = useDispatch();

    const startUploadNewFile = (fileId: string, fileType: UserFileTypeEnum, uploadUrl: string, file: File) =>
        dispatch(UploadQueueActionCreators.uploadNewFile(fileId, fileType, uploadUrl, file));

    const sessionToken = useSelector(sessionTokenSelector);

    const onNewFileSelect = useCallback((selectedFile: File) => {
        const fReader = new FileReader();

        fReader.onload = (event) => {
            if ((event.target) && (event.target.result) && (typeof event.target.result === "string")) {
                setPreviewFile(selectedFile);
                setPreviewFileUrl(event.target.result);
                setElementState(ElementStateEnum.WAIT_FOR_UPLOADING);
            }
        }

        fReader.readAsDataURL(selectedFile);
    }, [setElementState]);

    const setFileId = useCallback((fileId: string | null) => {
        onChangeElementData({
            ...elementData,
            videoId: fileId
        })
    }, [elementData, onChangeElementData]);

    // Подготовка к отправке файла
    useEffect(() => {
        if (
            (elementState !== ElementStateEnum.WAIT_FOR_UPLOADING)
            || (!previewFile)
            || (!sessionToken)
        ) {
            return;
        }

        const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);

        httpApiClient.fileGetUrlForUpload(
            sessionToken,
            UserFileTypeEnum.TM_SLIDE_VIDEO,
            previewFile.size.toString(10)
        )
            .then((data) => {
                setFileId(data.data.fileId);

                startUploadNewFile(
                    data.data.fileId,
                    UserFileTypeEnum.TM_SLIDE_VIDEO,
                    data.data.uploadUrl,
                    previewFile
                );
            })
            .catch((err) => {
                const logger = container.resolve<ILogger>(DiTokens.LOGGER);

                logger.error(LoggerSectionsEnum.TM_VIDEO_PLAYER, err);

                setElementState(ElementStateEnum.UPLOAD_ERROR);
            })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementState]);

    const cancelUploadBtn = useCallback(() => {
        if (!currentFileInUploadQueue) {
            return false;
        }

        const cancelUploadFile = (fileId: string) =>
            dispatch(UploadQueueActionCreators.cancelUploadFile(fileId));

        cancelUploadFile(currentFileInUploadQueue.fileId);
    }, [currentFileInUploadQueue, dispatch]);

    const retryUploadBtn = useCallback(() => {
        if (!currentFileInUploadQueue) {
            // Похоже, что загрузка завалилась ещё до постановки файла в очередь.
            // Просто поменяем состояние компонента.
            setElementState(ElementStateEnum.WAIT_FOR_UPLOADING);

            return false;
        }

        if (currentFileInUploadQueue.state !== FileUploadProcessState.FAILED) {
            return false;
        }

        const restartFileUploading = (fileId: string) =>
            dispatch(UploadQueueActionCreators.restartFileUploading(fileId));

        restartFileUploading(currentFileInUploadQueue.fileId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFileInUploadQueue, dispatch])

    useEffect(() => {
        if ((currentFileInUploadQueue) && (previewFile !== null)) {
            // Файл есть в очереди, но ещё остался в локальном preview
            // - значит он успешно перешёл к отправке
            setPreviewFile(null);

            return;
        }
    }, [currentFileInUploadQueue, previewFile]);

    useEffect(() => {
        if (currentFileInUploadQueue && (previewFileUrl === null)) {
            const fReader = new FileReader();

            fReader.onload = (event) => {
                if ((event.target) && (event.target.result) && (typeof event.target.result === "string")) {
                    setPreviewFileUrl(event.target.result);
                }
            }

            fReader.readAsDataURL(currentFileInUploadQueue.file);
        }
    }, [currentFileInUploadQueue, previewFileUrl]);

    useEffect(() => {
        if (elementState === ElementStateEnum.NO_VIDEO) {
            setPreviewFileUrl(null);
            setFileId(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementState]);

    const content = () => {
        switch (elementState) {
            case ElementStateEnum.WAIT_INIT:
            case ElementStateEnum.NO_VIDEO: {
                return <VideoPlaceholder onNewFileSelect={onNewFileSelect}/>;
            }
            case ElementStateEnum.WAIT_FOR_UPLOADING: {
                return (
                    <>
                        <VideoPlaceholder/>
                        <ControlPanel statusText={t`Обработка...`}/>
                    </>
                );
            }

            case ElementStateEnum.UPLOADING_NOW: {
                return (
                    <>
                        <VideoPlaceholder/>
                        <ControlPanel
                            statusText={t`Сохранение` + " " + ((currentFileInUploadQueue) ? currentFileInUploadQueue.completedInPercent.toString(10) : "0") + '%'}
                            showProgressBar={true}
                            progressBarPosition={(currentFileInUploadQueue) ? currentFileInUploadQueue.completedInPercent : 0}
                            showCancelUploadBtn={true}
                            cancelUploadBtnClick={cancelUploadBtn}
                        />
                    </>
                );
            }

            case ElementStateEnum.UPLOAD_ERROR: {
                return (
                    <>
                        <VideoPlaceholder/>
                        <ControlPanel
                            statusText={t`Не удалось сохранить видео`}
                            showRetryBtn={true}
                            retryBtnClick={retryUploadBtn}
                        />
                    </>
                );
            }
        }
    }

    return <>{content()}</>;
}