import React, {useCallback, useMemo} from "react";
import {PaymentTypesEnum} from "./PaymentTypesEnum";
import styled from "styled-components";
import {Form, FormItem, useForm} from "../Ui/Elements/Form";
import {t, Trans} from "@lingui/macro";
import {DatePicker} from "../Ui/Elements/DatePicker";
import moment from "moment";
import {PopupMultipleSelector} from "../Ui/Elements/Selectors/PopupMultipleSelector";
import {PopupMultiselectItem} from "../Ui/Elements/PopupMultiselect";

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;

  @media (${({theme}) => theme.media.small}) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const DateSelectorFormItem = styled(FormItem)`
  width: 100%;
  flex-grow: 1;

  @media (${({theme}) => theme.media.medium}) {
    width: auto;
  }
`;

const TypeSelectorFormItem = styled(DateSelectorFormItem)`
`;

enum FormFieldNames {
    fromDate = 'fromDate',
    toDate = 'toDate',
    selectedTypes = 'selectedTypes'
}

interface FiltersProps {
    fromDate: Date | null;
    toDate: Date | null;
    selectedTypes: PaymentTypesEnum[];
    onChange: (fromDate: Date, toDate: Date, newItems: PaymentTypesEnum[]) => void;
}

export const Filters: React.FC<FiltersProps> = (
    {fromDate, toDate, selectedTypes, onChange}
) => {
    const [form] = useForm();

    const onValuesChange = useCallback(() => {
        const formFromDate = form.getFieldValue(FormFieldNames.fromDate);
        const formToDate = form.getFieldValue(FormFieldNames.toDate);
        const formSelectedTypes = form.getFieldValue(FormFieldNames.selectedTypes);

        const formFromDateAsDateObject = (formFromDate) ? formFromDate.toDate() : null;
        const formToDateAsDateObject = (formToDate) ? formToDate.toDate() : null;

        onChange(formFromDateAsDateObject, formToDateAsDateObject, formSelectedTypes);
    }, [form, onChange]);

    const allTypesForMultipleSelector = useMemo<PopupMultiselectItem[]>(() => {
        return [
            {
                key: PaymentTypesEnum.ADMISSION as unknown as string,
                text: t`Пополнения счёта`
            },
            {
                key: PaymentTypesEnum.LESSON_PAYMENTS as unknown as string,
                text: t`Списания за занятия`
            },
            {
                key: PaymentTypesEnum.CONSUMPTION as unknown as string,
                text: t`Прочие списания`
            },
        ]
    }, []);

    return <div>
        <Form form={form} layout={"vertical"} onValuesChange={onValuesChange}>
            <FormWrapper>
                <DateSelectorFormItem
                    name={FormFieldNames.fromDate}
                    initialValue={fromDate ? moment(fromDate) : null}
                    label={<Trans>Начало периода</Trans>}
                >
                    <DatePicker allowClear={true} suffixIcon={false}/>
                </DateSelectorFormItem>
                <DateSelectorFormItem
                    name={FormFieldNames.toDate}
                    initialValue={toDate ? moment(toDate) : null}
                    label={<Trans>Конец периода</Trans>}
                >
                    <DatePicker allowClear={true} suffixIcon={false}/>
                </DateSelectorFormItem>
                <TypeSelectorFormItem
                    name={FormFieldNames.selectedTypes}
                    initialValue={selectedTypes}
                    label={<Trans>Типы операций</Trans>}
                >
                    <PopupMultipleSelector
                        items={allTypesForMultipleSelector}
                        placeholder={t`Выберите операции`}
                    />
                </TypeSelectorFormItem>
            </FormWrapper>
        </Form>
    </div>
}
