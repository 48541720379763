import {Editor, Element as SlateElement, Range, Transforms} from "slate";
import {elementTypeIsActive} from "../CommonMethods";
import {ExerciseIdGenerator} from "../../ExerciseIdGenerator";
import {IBaseElement} from "../../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/IBaseElement";
import {
    ICorrectExistInInput
} from "../../../../SlidePlayerEditorCommonParts/TextEditorElementTypes/ICorrectExistInInput";
import {ElementTypes} from "../../../../SlidePlayerEditorCommonParts/TextEditorElementTypeEnum";

export const toggleCorrectExistInInput = (editor: Editor) => {
    const {selection} = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);

    if (elementTypeIsActive(editor, ElementTypes.EXERCISE_CORRECT_EXIST_IN_INPUT)) {
        if ((isCollapsed) || (selection === null)) {
            return;
        }

        // Находим упражнения, входящие в выделенную область
        const match = Array.from(Editor.nodes(editor, {
            at: Editor.range(editor, selection),
            match: (node) => {
                if (Editor.isEditor(node)) {
                    return false;
                }

                if (!SlateElement.isElement(node)) {
                    return false;
                }

                return node.type === ElementTypes.EXERCISE_CORRECT_EXIST_IN_INPUT;
            },
            mode: "all",
            voids: true
        }));

        for (const found of match) {
            const [node, path] = found;
            const strings = ((node as ICorrectExistInInput).values as string[]).toString();

            const newProperties: IBaseElement = {
                type: ElementTypes.PARAGRAPH,
                children: [{text: strings}]
            };

            Transforms.insertFragment(editor, [newProperties], {at: path});
        }

        Transforms.unwrapNodes(editor, {
            match: (node) => {
                if (Editor.isEditor(node)) {
                    return false;
                }

                if (!SlateElement.isElement(node)) {
                    return false;
                }

                return node.type === ElementTypes.EXERCISE_CORRECT_EXIST_IN_INPUT;
            },
            split: true,
        });

        Transforms.collapse(editor, {edge: 'end'});

        return;
    }

    const inpputElement: ICorrectExistInInput = {
        id: ExerciseIdGenerator.generateId(),
        type: ElementTypes.EXERCISE_CORRECT_EXIST_IN_INPUT,
        children: [],
        values: []
    };
    inpputElement.children = isCollapsed ? [{text: ""}] : [];

    if (isCollapsed) {
        Transforms.insertNodes(editor, inpputElement)
    } else {
        Transforms.wrapNodes(editor, inpputElement, {split: true})
        Transforms.collapse(editor, {edge: 'end'})
    }
}