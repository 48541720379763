import {call, put, takeEvery} from 'redux-saga/effects';
import {WorkerPayloadType} from "../WorkerPayloadType";
import {
    ActionParamExerciseUserAdmitDefeat,
    ActionParamExerciseUserAnswer,
    SlideWorkDataActionTypes
} from "../../store/slidesWorkData/type";
import {prepareOrFindExerciseObject} from "./common/prepareOrFindExerciseObject";
import {setExerciseAnswer} from "../../store/slidesWorkData/actions";
import {DataTypeEnum, pushDataToSaveQueue} from "./common/pushDataToSaveQueue";
import {MAX_AWARD_SCORE} from "../../Constants";

export function* watchHandleUserEventAdmitDefeat() {
    yield takeEvery<WorkerPayloadType<ActionParamExerciseUserAdmitDefeat>>(
        SlideWorkDataActionTypes.HANDLE_USER_EVENT_ADMIT_DEFEAT,
        handleUserEventAdmitDefeat
    );
}

function* handleUserEventAdmitDefeat(data: WorkerPayloadType<ActionParamExerciseUserAdmitDefeat>) {
    const [slideIndex, slideItemIndex, exerciseIndex]
        = (
        yield call(
            prepareOrFindExerciseObject,
            {
                playerId: data.payload.playerId,
                slideId: data.payload.slideId,
                slideItemId: data.payload.slideItemId,
                exerciseId: data.payload.exerciseId,
                value: null
            }
        )
    ) as [number, number, number];

    yield put(setExerciseAnswer({
        slideIndex: slideIndex,
        slideItemIndex: slideItemIndex,
        exerciseIndex: exerciseIndex,
        value: data.payload.value,
        slideExercisesCount: data.payload.slideExercisesCount,
        award: 0,
        missedAward: MAX_AWARD_SCORE,
        answerIsCorrect: true
    }));

    const queuePayload: ActionParamExerciseUserAnswer = {
        playerId: data.payload.playerId,
        slideId: data.payload.slideId,
        slideItemId: data.payload.slideItemId,
        exerciseId: data.payload.exerciseId,
        value: data.payload.value,
        award: 0,
        missedAward: MAX_AWARD_SCORE,
        slideExercisesCount: data.payload.slideExercisesCount,
        answerIsCorrect: true
    }

    yield call(pushDataToSaveQueue, DataTypeEnum.ANSWER, queuePayload);

    return;
}
