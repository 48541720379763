import React, {useMemo} from "react";
import styled from "styled-components";
import {RegularTextCss} from "../../../../../styles/global-elements";
import {
    DtoNotificationGroup
} from "../../../../../../components/HttpApiClient/ApiDto/Response/Notifications/DtoNotificationGroup";
import {NotificationGroupsEnum} from "../../../../../../enums/NotificationGroupsEnum";
import {t} from "@lingui/macro";
import {Index as ChannelBtn} from "./ChannelButton/index";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;

    @media (${({theme}) => theme.media.small}) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
`;

const GroupItemTitle = styled.div`
    ${RegularTextCss};
`;

const GroupItemButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
`;

interface GroupItemProps {
    data: DtoNotificationGroup;
    className?: string;
}

export const GroupItem: React.FC<GroupItemProps> = ({data, className}) => {
    const groupTitle = useMemo(() => {
        switch (data.alias as NotificationGroupsEnum) {
            case NotificationGroupsEnum.LESSON_REMINDER: {
                return t`Уведомления об уроках`;
            }
            case NotificationGroupsEnum.SELF_STUDY_EPISODES: {
                return t`Эпизоды self-study треков`;
            }
        }
    }, [data.alias]);

    return <Wrapper className={className}>
        <GroupItemTitle>{groupTitle}</GroupItemTitle>
        <GroupItemButtons>
            {
                data.list.map((item) => <ChannelBtn key={item.alias} groupId={data.id} channelDto={item}/>)
            }
        </GroupItemButtons>
    </Wrapper>
}