import {action} from "typesafe-actions";

import {MessageFromServiceWorker, ServiceWorkerActionTypes, ServiceWorkerStatusEnum, SupportedFeatures} from "./type";

export const check = () => {
    return action(ServiceWorkerActionTypes.CHECK);
}

export const setStatus = (newStatus: ServiceWorkerStatusEnum) => {
    return action(ServiceWorkerActionTypes.SET_STATUS, newStatus);
}

export const setVersion = (version: string) => {
    return action(ServiceWorkerActionTypes.SET_VERSION, version);
}

export const setSupportedFeatures = (supportedFeatures: SupportedFeatures) => {
    return action(ServiceWorkerActionTypes.SET_SUPPORTED_FEATURES, supportedFeatures);
}

export const setPushSubscriptionState = (state: boolean) => {
    return action(ServiceWorkerActionTypes.SET_PUSH_SUBSCRIPTION_STATE, state);
}

export const setRegisteredPushTokenHash = (hash: string | null) => {
    return action(ServiceWorkerActionTypes.SET_REGISTERED_PUSH_TOKEN_HASH, hash);
}

export const onMessage = <T>(data: MessageFromServiceWorker<T>) => {
    return action(ServiceWorkerActionTypes.ON_MESSAGE, data);
}