import {call, put, takeEvery} from "redux-saga/effects";
import {
    MessageFromServiceWorker,
    MessageToServiceWorker,
    MessageToServiceWorkerTypeEnum,
    ServiceWorkerActionTypes,
    ServiceWorkerStatusEnum,
} from "../../store/serviceWorker/type";
import * as ServiceWorkerActions from "../../store/serviceWorker/actions";
import {ILogger} from "../../components/Logger/ILogger";
import {container} from "tsyringe";
import {DiTokens} from "../../di-factory/DiTokens";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";
import {IStoreService} from "../../services/store/IStoreService";

/**
 * Сага отвечает за инициализацию service worker в рамках приложения (не за регистрацию).
 */
export function* watchSwCheck() {
    yield takeEvery(
        ServiceWorkerActionTypes.CHECK,
        checkSw
    );
}

function* checkSw() {
    const logger: ILogger = container.resolve<ILogger>(DiTokens.LOGGER);

    logger.info(LoggerSectionsEnum.SERVICE_WORKER_CHECK, 'Start checking...');

    if (!('serviceWorker' in navigator)) {
        logger.info(LoggerSectionsEnum.SERVICE_WORKER_CHECK, 'SW not supported');

        yield put(ServiceWorkerActions.setStatus(ServiceWorkerStatusEnum.NOT_SUPPORTED));

        return;
    }

    yield put(ServiceWorkerActions.setStatus(ServiceWorkerStatusEnum.CHECKING));

    let registrationInfo: ServiceWorkerRegistration | null = null;

    try {
        registrationInfo =
            (yield call(() => navigator.serviceWorker.ready)) as ServiceWorkerRegistration;
    } catch (error) {
        logger.warning(LoggerSectionsEnum.SERVICE_WORKER_CHECK, 'SW registration error: ', error);

        return;
    }

    if (registrationInfo.active === null) {
        logger.warning(LoggerSectionsEnum.SERVICE_WORKER_CHECK, 'SW is ready, but registration.active is null');

        return;
    }

    logger.info(LoggerSectionsEnum.SERVICE_WORKER_CHECK, 'SW is ready. Checking version.');

    const checkVersionMessage: MessageToServiceWorker<null> = {
        type: MessageToServiceWorkerTypeEnum.CHECK_VERSION,
        payload: null
    };

    const storeService = container.resolve<IStoreService>(DiTokens.STORE_SERVICE);

    navigator.serviceWorker.addEventListener('message', event => {
        const data = event.data as MessageFromServiceWorker<any>;

        storeService.store.dispatch(ServiceWorkerActions.onMessage(data));
    });

    registrationInfo.active.postMessage(checkVersionMessage);
}