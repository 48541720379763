import React, {useCallback, useMemo} from 'react';
import {EditorData} from "../SlidePlayerEditorCommonParts/EditorData";
import styled from "styled-components";
import ElementClass from "./ElementClass";
import {SlideControlContextProvider} from "./SlideControlContext";
import {SlideItemsParamsBySlide} from "./SlideItemParamsStore";

const SlidePlayerWrapper = styled.div`
`;

SlidePlayerWrapper.displayName = 'SlidePlayerWrapper';

export interface SlidePlayerProps {
    playerId: string;
    slideId: string;
    slideContent: EditorData;
    slideItemsParams: SlideItemsParamsBySlide;
    className?: string;
    readOnly?: boolean;
    showCorrectAnswers?: boolean;
}

export const SlidePlayer: React.FC<SlidePlayerProps> = (props) => {
    const focusToNextItemId = useCallback((callerSlideId: string, callerSlideItemId: string, callerExerciseId: string) => {
        if (!props.slideId) {
            return;
        }

        // Поиск слайда
        const currentSlide = document.querySelectorAll(
            '[data-player-id="' + props.playerId + '"][data-slide-id="' + props.slideId + '"]'
        );

        if ((!currentSlide) || (currentSlide.length !== 1)) {
            return;
        }

        const slideNode = currentSlide[0];

        // Поиск целевых элементов слайда
        const slideItems = slideNode.querySelectorAll("[data-slide-item-id]");

        if ((!slideItems) || (slideItems.length === 0)) {
            return;
        }

        let callerSlideItemIndex: number | null = null;

        for (let index = 0; index < slideItems.length; index++) {
            if (slideItems[index].getAttribute('data-slide-item-id') === callerSlideItemId) {
                callerSlideItemIndex = index;

                break;
            }
        }

        if (callerSlideItemIndex === null) {
            return;
        }

        // Упражнения, в которых будем искать поля ввода и возьмём первое попавшееся
        const exerciseElements: Array<Element> = [];

        // Выполним поиск упражнений в текущем слайде
        const exercisesInCallerItem = slideItems[callerSlideItemIndex].querySelectorAll('[data-exercise-id]');
        let callerExerciseItemFound = false;

        exercisesInCallerItem.forEach((item) => {
            if (!callerExerciseItemFound) {
                if (item.getAttribute('data-exercise-id') === callerExerciseId) {
                    callerExerciseItemFound = true;
                }
            } else {
                exerciseElements.push(item);
            }
        });

        // Поиск упражнений в следующих слайдах
        for (let slideItemIndex = callerSlideItemIndex + 1; slideItemIndex < slideItems.length; slideItemIndex++) {
            // Ищем упражнение для фокусировки в каждом слайде, начиная с текущего вызывающего
            const exercises = slideItems[slideItemIndex].querySelectorAll('[data-exercise-id]');

            exerciseElements.push(
                ...Array.from(exercises)
            );
        }

        // Поиск доступного инпута в упражнениях
        for (let exerciseIndex = 0; exerciseIndex < exerciseElements.length; exerciseIndex++) {
            const input = exerciseElements[exerciseIndex].querySelector('[data-exercise-input]');

            if (input) {
                if ((input as HTMLElement).focus) {
                    (input as HTMLElement).focus();

                    return;
                }
            }
        }
    }, [props.playerId, props.slideId]);
    
    const filteredItems = useMemo(() => {
        return props.slideContent.items
            .filter(item => {
                if (item.data === null) {
                    return false;
                }

                if (props.slideItemsParams[item.id] === undefined) {
                    return true;
                }

                return props.slideItemsParams[item.id].visible;
            });
    },[props.slideContent.items, props.slideItemsParams]);

    return <SlidePlayerWrapper
        data-player-id={props.playerId}
        data-slide-id={props.slideId}
        className={props.className}>
        <SlideControlContextProvider value={{
            goFocusToNextExercise: focusToNextItemId
        }}>
            {
                (props.slideId !== null)
                && filteredItems.map((slideElement) => {
                        return <ElementClass key={slideElement.id}
                                             showCorrectAnswers={props.showCorrectAnswers}
                                             readOnly={props.readOnly}
                                             element={slideElement}
                                             playerId={props.playerId}
                                             slideId={props.slideId}
                        />;
                    })
            }
        </SlideControlContextProvider>
    </SlidePlayerWrapper>;
}
