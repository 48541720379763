import {motion, Variants} from 'framer-motion';
import * as React from "react";
import {ReactChild, ReactChildren} from "react";

export interface AnimatedAreaProps {
    children: ReactChild | ReactChildren,
}

const animations: Variants = {
    initial: {opacity: 0, y: 40},
    animate: {opacity: 1, y: 0},
    //exit: {opacity: 0, y: -40},
}

export const AnimatedContainer: React.FC<AnimatedAreaProps> = ({children}: AnimatedAreaProps) => {
    return (
        <motion.div
            variants={animations}
            initial="initial"
            animate="animate"
            //exit="exit"
            transition={{type: "tween", ease: "easeOut", duration: .2}} //*/[.25, .73, .66, .86]
        >
            {children}
        </motion.div>
    );
}