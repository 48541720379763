import {call, delay, put, putResolve, select, takeEvery} from 'redux-saga/effects';
import {UserActionTypes} from "../../store/user/type";
import * as CommonPersistedActions from "../../store/commonPersisted/actions";
import * as AppActions from "../../store/app/actions";
import * as LayoutActions from "../../store/layout/actions";
import {container} from "tsyringe";
import {IStoreService} from "../../services/store/IStoreService";
import {DiTokens} from "../../di-factory/DiTokens";
import {ICustomStorage} from "../../components/CustomStorage/ICustomStorage";
import {TargetsEnum} from "../../components/CustomStorage/TargetsEnum";
import {IPagesBroadcastService} from "../../services/pages-broadcast-service/IPagesBroadcastService";
import {currentUserIdSelector} from "../../store/user/selector";
import * as SlidesWorkDataSaveQueueActions from "../../store/slidesWorkDataSaveQueue/actions";

/**
 * Сага отвечает за выход пользователя.
 */
export function* watchLogoutUser() {
    yield takeEvery(
        UserActionTypes.LOGOUT_USER,
        logoutUser
    );
}

function* logoutUser() {
    yield putResolve(LayoutActions.setSplashScreenVisible(true));

    yield delay(500);

    const customStorage = container.resolve<ICustomStorage>(DiTokens.CUSTOM_STORAGE);
    const currentUserId = (yield select(currentUserIdSelector)) as string | null;

    if (customStorage.getCurrentTarget() !== TargetsEnum.LOCAL_STORAGE) {
        // Если не используется LOCAL_STORAGE, просто очищаем хранилище
        customStorage.clear();

        yield put(AppActions.rebootApp());

        return;
    }

    const storeService = container.resolve<IStoreService>(DiTokens.STORE_SERVICE);
    const pageBroadcastService = container.resolve<IPagesBroadcastService>(DiTokens.PAGES_BROADCAST_SERVICE);

    // Удаляем все данные из persisted
    yield putResolve(CommonPersistedActions.clearAllData());
    yield putResolve(SlidesWorkDataSaveQueueActions.clearAll());
    yield call(() => {
        return storeService.persistor.flush();
    });

    // Посылаем окнам информацию о выходе
    if (currentUserId) {
        pageBroadcastService.noticeAboutLogout(currentUserId);
    }

    // Перезапускаем приложение
    yield put(AppActions.rebootApp());
}
