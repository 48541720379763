import {put, putResolve, select, takeEvery} from 'redux-saga/effects';
import {WorkerPayloadType} from "../WorkerPayloadType";
import {setNewItem, startHandleCycle} from "../../store/slidesWorkDataSaveQueue/actions";
import {
    QueueItem,
    QueueItemContext,
    QueueSendCycleStateEnum,
    SlidesWorkDataSaveQueueActionTypes
} from "../../store/slidesWorkDataSaveQueue/type";
import {handleCycleEnabledSelector, handleCycleStatusSelector} from "../../store/slidesWorkDataSaveQueue/selector";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";
import {container} from "tsyringe";
import {ILogger} from "../../components/Logger/ILogger";
import {DiTokens} from "../../di-factory/DiTokens";

export function* watchNewItemToQueue() {
    yield takeEvery<WorkerPayloadType<QueueItem<QueueItemContext>>>(
        SlidesWorkDataSaveQueueActionTypes.NEW_ITEM_TO_QUEUE,
        newItemToQueue
    );
}

function* newItemToQueue(data: WorkerPayloadType<QueueItem<QueueItemContext>>) {
    const logger = container.resolve<ILogger>(DiTokens.LOGGER);
    logger.info(LoggerSectionsEnum.SLIDES_WORK_DATA_SAVE_QUEUE, 'Queue received new item: ', data.payload);

    yield putResolve(setNewItem(data.payload));

    const handleCycleStatus = (yield select(handleCycleStatusSelector)) as QueueSendCycleStateEnum;
    const handleCycleEnabled = (yield select(handleCycleEnabledSelector)) as boolean;

    if (handleCycleEnabled && handleCycleStatus === QueueSendCycleStateEnum.STOPPED) {
        yield put(startHandleCycle());
    }
}
