import React, {useCallback, useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import {
    DtoSelfStudyTrackEpisodeSurvey
} from "../../../../../../components/HttpApiClient/ApiDto/Response/SelfStudyTrack/DtoSelfStudyTrackEpisodeSurvey";
import {ButtonStyled, NextButtonWrapper} from "../styles";
import {BtnStyleEnum} from "../../../../../components/Ui/Elements/Button";
import {t, Trans} from "@lingui/macro";
import {AnswerButton} from "./AnswerButton";
import {AnswerTypeEnum} from "./AnswerTypeEnum";
import {
    DtoSelfStudyTrackSubscriptionUserOverviewItem
} from "../../../../../../components/HttpApiClient/ApiDto/Response/SelfStudyTrack/DtoSelfStudyTrackSubscriptionUserOverviewItem";
import {EpisodeCompleteTitle} from "../EpisodeCompleteTitle";
import {TextArea} from "../../../../../components/Ui/Elements/TextArea";
import {DefaultLoader} from "../../../../../components/DefaultLoader";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../../../store/app/selector";
import {container} from "tsyringe";
import {ILogger} from "../../../../../../components/Logger/ILogger";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {IHttpApiClient} from "../../../../../../components/HttpApiClient/IHttpApiClient";
import {NoConnection} from "../../../../../../components/HttpApiClient/Exception/NoConnection";
import {LoggerSectionsEnum} from "../../../../../../components/Logger/LoggerSectionsEnum";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    //min-height: 100%;
    text-align: center;
`;

const ActivityWrapper = styled.div`
    min-width: 50%;
`;

const ButtonsList = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0;
`;

const CommentTextArea = styled(TextArea)`
    border: 1px solid ${({theme}) => theme.colors.backgroundInverse};
    border-radius: 10px;
    padding: 10px;

    &:hover {
        border: 1px solid ${({theme}) => theme.colors.backgroundInverse};
    }

    &:focus {
        border: 1px solid ${({theme}) => theme.colors.backgroundInverse};
    }
`;

interface EpisodeSurveyProps {
    episodeId: string;
    surveyData: DtoSelfStudyTrackEpisodeSurvey;
    subscriptionOverview: DtoSelfStudyTrackSubscriptionUserOverviewItem;
    goNext: () => void;
}

export const EpisodeSurvey: React.FC<EpisodeSurveyProps> = (
    {
        episodeId,
        surveyData,
        subscriptionOverview,
        goNext
    }
) => {
    const sessionToken = useSelector(sessionTokenSelector);

    const [activeVariant, setActiveVariant] = useState<AnswerTypeEnum | null>(null);
    const [nowLoading, setNowLoading] = useState<boolean>(false);
    const [comment, setComment] = useState<string>('');

    const toggleVariant = useCallback((newVariant) => {
        if (activeVariant === newVariant) {
            setActiveVariant(null);

            return;
        }

        setActiveVariant(newVariant);
    }, [activeVariant]);

    const allowComment = useMemo(() => {
        return (activeVariant === AnswerTypeEnum.NORMAL || activeVariant === AnswerTypeEnum.BAD)
    }, [activeVariant]);

    const saveAnswer = useCallback(async () => {
        if (!sessionToken || (activeVariant === null)) {
            return;
        }

        const logger = container.resolve<ILogger>(DiTokens.LOGGER);
        const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);

        setNowLoading(true);

        const commentFiltered = (comment.trim() === '') ? null : comment.trim();

        try {
            await httpApiClient.saveEpisodeSurveyAnswer(
                sessionToken,
                episodeId,
                surveyData.questionId,
                activeVariant,
                commentFiltered
            );
        } catch (e) {
            if (e instanceof NoConnection) {
                return;
            }

            logger.error(
                LoggerSectionsEnum.SELF_STUDY_TRACK_EPISODE_SURVEY,
                `Error save answer to episode ${episodeId}. Comment was: "${comment}"`,
                e
            );
        }

        goNext();
    }, [activeVariant, comment, episodeId, goNext, sessionToken, surveyData.questionId]);


    useEffect(() => {
        if (activeVariant === AnswerTypeEnum.GOOD) {
            saveAnswer();

            return;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeVariant]);

    return <Wrapper>
        <EpisodeCompleteTitle
            episodeName={subscriptionOverview.name}
            lastEpisodeNumber={subscriptionOverview.lastEpisodeNumber}
            lastEpisodeId={subscriptionOverview.lastEpisodeId}
        />

        <div/>

        {
            (nowLoading)
                ? <DefaultLoader/>
                : <>
                    <div>
                        <h2>{surveyData.question}</h2>
                    </div>

                    <ActivityWrapper>
                        <ButtonsList>
                            <AnswerButton
                                active={activeVariant === AnswerTypeEnum.GOOD}
                                answerType={AnswerTypeEnum.GOOD}
                                label={surveyData.goodAnswer}
                                onClick={() => toggleVariant(AnswerTypeEnum.GOOD)}
                            />
                            <AnswerButton
                                active={activeVariant === AnswerTypeEnum.NORMAL}
                                answerType={AnswerTypeEnum.NORMAL}
                                label={surveyData.normalAnswer}
                                onClick={() => toggleVariant(AnswerTypeEnum.NORMAL)}
                            />
                            <AnswerButton
                                active={activeVariant === AnswerTypeEnum.BAD}
                                answerType={AnswerTypeEnum.BAD}
                                label={surveyData.badAnswer}
                                onClick={() => toggleVariant(AnswerTypeEnum.BAD)}
                            />
                        </ButtonsList>

                        {
                            allowComment && <CommentTextArea
                                placeholder={t`Как можно улучшить?`}
                                value={comment}
                                onChange={(event) => setComment(event.target.value)}
                                maxLength={200}
                            />
                        }
                    </ActivityWrapper>

                    {
                        allowComment
                        && <NextButtonWrapper>
                            <ButtonStyled
                                btnStyle={BtnStyleEnum.Primary}
                                onClick={saveAnswer}
                            >
                                <Trans>Продолжить</Trans>
                            </ButtonStyled>
                        </NextButtonWrapper>
                    }
                </>
        }
    </Wrapper>
}