import styled from "styled-components";
import {BlockWithBorder, RegularText} from "../../../../styles/global-elements";
import React, {CSSProperties, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {BtnStyleEnum, Button} from "../Button";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../../../store";
import {ITheme} from "../../../../../services/theme/ITheme";
import useMediaQuery from "../../../../../services/hooks/useMediaQuery";
import {ReactComponent as CloseCrossIcon} from "../../../Ui/Svg/CloseCross.svg";

const Wrapper = styled(BlockWithBorder)`
  border: none;
  position: relative;

  width: 100%;
  overflow: hidden;

  padding-top: 200px;

  @media (min-width: 644px) {
    padding-top: 230px;
  }

  @media (${({theme}) => theme.media.large}) {
    padding-top: unset;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
`;

const ImageWrapper = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 0;
  top: 0;

  @media (${({theme}) => theme.media.large}) {
    background-size: contain;
    background-position: 0 0;
  }
`;

const CloseBtnWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  z-index: 1;
  text-align: right;
  padding: 5px;

  opacity: 0.5;
  transition: opacity 0.3s ease;
  cursor: pointer;

  @media (${({theme}) => theme.media.large}) {
    opacity: 0.2;
  }

  &:hover {
    opacity: 1;
  }
`;

const CloseBtnIconStyled = styled(CloseCrossIcon)`
  width: 30px;
  height: 30px;
`;

// const Gradient = styled.div`
//   width: 100%;
//   height: 55px;
//   position: relative;
//
//   @media (${({theme}) => theme.media.large}) {
//     width: 55px;
//     height: auto;
//   }
// `;

const TextBackground = styled.div`
  padding: 20px 10px 20px 10px;
  position: relative;

  @media (${({theme}) => theme.media.large}) {
    padding: 30px 40px 30px 100px;
    width: 50%;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  max-width: 260px;
  margin: auto;

  @media (${({theme}) => theme.media.small}) {
    max-width: 360px;
    gap: 20px;
  }

  @media (${({theme}) => theme.media.large}) {
    gap: 10px;
    align-items: flex-end;
    margin: 0;
  }
`;

const Text = styled(RegularText)`
  display: inline;
  text-align: center;
  margin-bottom: 5px;

  @media (${({theme}) => theme.media.large}) {
    text-align: right;
    margin-bottom: 0;
  }
`;

const Title = styled(Text)`
  font-weight: bold;
`;

const ButtonStyled = styled(Button)`
  @media (${({theme}) => theme.media.large}) {
    margin-top: 10px;
    display: inline-block;
  }
`;

interface BaseBannerProps {
    title: React.ReactNode;
    text: React.ReactNode;
    buttonLabel: string;
    accentColorHex: string;
    rectangleImgPath: string;
    squareImgPath: string;
    onClick: () => Promise<void>;
    children?: React.ReactNode;
    btnCloseOnClick?: () => void;
}

export const BaseBanner: React.FC<BaseBannerProps> = (props) => {
    const isMounted = useRef<boolean>(false);

    const currentTheme = useSelector<ApplicationState>(
        ({layout}: ApplicationState) => layout.activeTheme
    ) as ITheme;

    const [btnLoading, setBtnLoading] = useState<boolean>(false);

    const isLarge = useMediaQuery(`(${currentTheme.media.large})`);

    const onClick = useCallback(() => {
        setBtnLoading(true);

        props.onClick().finally(() => {
            if (isMounted.current) {
                setBtnLoading(false);
            }
        });
    }, [props]);

    const imageWrapperStyle = useMemo(() => {
        const result: CSSProperties = {}

        if (!isLarge) {
            result['backgroundImage'] = `url("${props.squareImgPath}")`;
        } else {
            result['backgroundImage'] = `url("${props.rectangleImgPath}")`;
        }

        return result;
    }, [isLarge, props.rectangleImgPath, props.squareImgPath]);

    // const gradientStyle = useMemo<CSSProperties>(() => {
    //     const result: CSSProperties = {}
    //
    //     if (!isLarge) {
    //         result['background'] = `linear-gradient(180deg, ${props.accentColorHex}00 0%, ${props.accentColorHex}B2 70%, ${props.accentColorHex}CC 100%)`;
    //     } else {
    //         result['background'] = `linear-gradient(90deg, ${props.accentColorHex}00 0%, ${props.accentColorHex}B2 70%, ${props.accentColorHex} 100%)`;
    //     }
    //
    //     return result;
    // }, [isLarge, props.accentColorHex]);

    const textBackgroundStyle = useMemo<CSSProperties>(() => {
        const result: CSSProperties = {}

        // if (!isLarge) {
        //     result["backgroundColor"] = `${props.accentColorHex}CC`;
        // } else {
        //     result["backgroundColor"] = props.accentColorHex;
        // }

        result["backgroundColor"] = props.accentColorHex;

        return result;
    }, [/*isLarge, */props.accentColorHex]);

    const showCloseBtn = useMemo(() => {
        return !!props.btnCloseOnClick;
    }, [props.btnCloseOnClick]);

    useEffect(() => {
        isMounted.current = true;

        return () => {
            isMounted.current = false;
        }
    }, []);

    return <Wrapper>
        <ImageWrapper style={imageWrapperStyle}/>

        {
            (showCloseBtn) && <CloseBtnWrapper onClick={props.btnCloseOnClick}><CloseBtnIconStyled/></CloseBtnWrapper>
        }

        {/*<Gradient style={gradientStyle}/>*/}

        <TextBackground style={textBackgroundStyle}>
            <TextWrapper>
                <Title>{props.title}</Title>

                <Text>{props.text}</Text>

                <ButtonStyled btnStyle={BtnStyleEnum.Primary} onClick={onClick} loading={btnLoading}>
                    {props.buttonLabel}
                </ButtonStyled>
            </TextWrapper>
        </TextBackground>

        {props.children}
    </Wrapper>
}