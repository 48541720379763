import React from "react";
import {BtnDefaultProps} from "./BtnDefaultProps";
import {BtnStyleEnum, Button} from "../../../../../../components/Ui/Elements/Button";
import {ReactComponent as EmailIcon} from "../../../../../../components/Ui/Svg/EmailReadNoPadding.svg";

export const EmailBtn: React.FC<BtnDefaultProps> = (
    {
        groupId,
        channelDto
    }
) => {
    return <Button btnStyle={
        (channelDto.enabled && channelDto.ready) ? BtnStyleEnum.Primary : BtnStyleEnum.Secondary
    }>
        <EmailIcon/>
    </Button>
}