import {DefaultTheme} from "styled-components";
import {ThemeEnum} from "./ThemeEnum";
import {BaseTheme} from "./BaseTheme";

export const DarkTheme: DefaultTheme = {
    type: ThemeEnum.dark,

    colors: {
        ...BaseTheme.colors,

        browserTheme: "#000000",

        textPrimary: "#FFFFFF",
        textSecondary: "#6D6D6D",
        textTertiary: "#6D6D6D", //"#38404C",
        textInverse: "#141719",
        textPrimaryDark: "#FFFFFF",
        textAlwaysWhite: "#FFFFFF",

        accentPrimary: "#FFFFFF",
        accentSecondary: "#F23635",
        accentDivider: "#2B2E33",

        backgroundPrimary: "#212326",
        backgroundSecondary: "#272A2E",
        backgroundInverse: "#FFFFFF",
        backgroundAlwaysDark: "#212326",

        backgroundHover: "rgba(255,255,255,0.07)",
        backgroundInverseHover: "rgba(33,35,38,0.07)",

        coloredNoticeBackground: "#2c3642",

        chartPrimary: "#1979FF",
        chartYellow: "#FFC400",
        chartGreen: "#00FFA7",
        chartIndigo: "#B14AFF",

        availableLessonRoomHeaderFirst: "rgb(33, 35, 38)",
        availableLessonRoomHeaderSecond: "rgb(79,88,98)",

        warningHeader: "rgba(82,82,82,0.8)",
        fatalErrorHeader: "rgba(137,53,53,0.8)",


        // Старые токены

        alertErrorBorder: "#85342c",
        alertErrorBg: "#af5a4d",
        alertErrorText: "#ffd0d0",

        headerDivider: "#25272b",

        disabledButtonBackground: "#38404C",

        constructorCorrectAnswer: "#6f9d42",
        constructorIncorrectAnswer: "#c75757",
        constructorIncorrectAnswerFont: "#e3e3e3",

        progressBarBackground: "#a7a7a7",

        walletPlusGreen: "#4bb692",
        walletMinusSum: "#c92d2c"
    },

    slideContent: {
        ...BaseTheme.slideContent,
        blueBg: "#364d6a",
        playerAccent: "#0e5bc5",

        textInputDefaultBg: "#272A2E",
        textInputCorrectBg: "#556e37",

        radioBorder: "#a7a7a7",

        teacherNotesBg: "#210a30",
        teacherCanSayBg: "#101b28",
        teacherAlsoDiscussBg: "#131c19",

        historyTipIncorrectText: "#c75757",
        historyTipCorrectText: "#6f9d42",
    },

    otherColors: {
        ...BaseTheme.otherColors,
        saveYourAchievementsBannerPrimary: '#753132',
        enablePushNotificationsBanner: '#212b3c'
    },

    filters: {
        ...BaseTheme.filters,
    },

    media: {
        ...BaseTheme.media,
    },

    size: {
        ...BaseTheme.size
    },

    opacity: {
        ...BaseTheme.opacity
    },

    zIndices: {
        ...BaseTheme.zIndices
    }
}
