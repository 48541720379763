import {instanceCachingFactory} from 'tsyringe';
import {
    IPushNotificationSubscriptionService
} from "../services/push-notifications/IPushNotificationSubscriptionService";
import {PushNotificationSubscriptionService} from "../services/push-notifications/PushNotificationSubscriptionService";
import {IHttpApiClient} from "../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "./DiTokens";

export default instanceCachingFactory<IPushNotificationSubscriptionService>((container) => {
    const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);

    return new PushNotificationSubscriptionService(
        process.env.REACT_APP_VAPID_PUBLIC as string,
        httpApiClient
    );
});
