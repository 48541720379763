import styled, {css} from "styled-components";
import {ReactComponent as VolumeUpSvg} from "../../../../Ui/Svg/VolumeUp24.svg";
import {ReactComponent as Stop24Svg} from "../../../../Ui/Svg/Stop24.svg";
import React, {forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState} from "react";
import {QualityItem} from "../../../../Ui/Elements/AudioPlayer";
import {VoiceoverPlayerMethods} from "../VoiceoverPlayerMethods";
import {ReactComponent as VoiceoverWavesSvg} from "../../../../Ui/Svg/VoiceoverWaves.svg";
import {PlayerTypeEnum} from "./PlayerTypeEnum";
import classNames from "classnames";
import {container} from "tsyringe";
import {IDeviceDetector} from "../../../../../../components/DeviceDetector/IDeviceDetector";
import {DiTokens} from "../../../../../../di-factory/DiTokens";

const VoiceoverWavesStyled = styled(VoiceoverWavesSvg)`
  color: rgb(28, 176, 246);
  width: auto;
  height: 34px;
`;

const PlayerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.full-size {
    display: block;
  }
`;

const PlayerPlayBtnWrapper = styled.div`
  color: rgb(28, 176, 246);
  cursor: pointer;
  height: 20px;

  &.full-size {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    min-height: 40px;
  }
`;

const PlayStopBtnSize = css`
  width: 20px;
  height: 20px;

  &.full-size {
    width: 30px;
    height: 30px;
  }
`;

const PlayerPlayBtn = styled(VolumeUpSvg)`
  ${PlayStopBtnSize};
`;

const PlayerStopBtn = styled(Stop24Svg)`
  ${PlayStopBtnSize};
`;

const ProgressBar = styled.div`
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  height: 4px;
  opacity: 0.3;
  background-color: rgb(28, 176, 246);
  width: 0;
`;

interface SmallVoiceoverPlayerProps {
    playerSize: PlayerTypeEnum;
    fileLinks: QualityItem[];
    onReady: () => void;
    onEnd: () => void;
    onError: () => void;
    alreadyCompleted: boolean;
}

export const SmallVoiceoverPlayer = forwardRef<VoiceoverPlayerMethods, SmallVoiceoverPlayerProps>(
    ({fileLinks, onReady, onError, onEnd, playerSize, alreadyCompleted}, ref) => {
        
        const deviceDetector = useMemo(() => container.resolve<IDeviceDetector>(DiTokens.DEVICE_DETECTOR), []);
        const smallAudioRef = useRef<HTMLAudioElement>(null);

        const [playing, setPlaying] = useState<boolean>(false);

        const [positionInSeconds, setPositionInSeconds] = useState<number | null>(null);
        const [durationInSeconds, setDurationInSeconds] = useState<number | null>(null);

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({
            startPlay: () => {
                smallAudioRef.current?.play();
            }
        }));

        const onPlayingStop = useCallback(() => {
            setPlaying(false);
            onEnd();
        }, [onEnd]);

        const togglePlay = useCallback(() => {
            if (!smallAudioRef.current) {
                return;
            }

            if (smallAudioRef.current.paused) {
                smallAudioRef.current.currentTime = 0;
                smallAudioRef.current.play();
            } else {
                smallAudioRef.current.pause();
            }
        }, []);

        const onCanPlay = useCallback((event: React.SyntheticEvent) => {
            if (!deviceDetector.isMobileSafari()) {
                setDurationInSeconds(Math.floor((event.target as HTMLAudioElement).duration));
                onReady();
            }
        }, [deviceDetector, onReady]);


        const onLoadedMetadata = useCallback((event: React.SyntheticEvent) => {
            if (deviceDetector.isMobileSafari()) {
                // В сафари не работает onCanPlay, поэтому стартуем здесь
                setDurationInSeconds(Math.floor((event.target as HTMLAudioElement).duration));
                onReady();
            }
        }, [deviceDetector, onReady]);

        const onProgress = useCallback((event: React.SyntheticEvent) => {
            setPositionInSeconds(Math.floor((event.target as HTMLAudioElement).currentTime));
        }, []);

        const progressBarWidth = useMemo<string>(() => {
            if (alreadyCompleted) {
                return "100%";
            }

            if (playing) {
                return "100%";
            }

            if (durationInSeconds !== null && durationInSeconds === positionInSeconds) {
                return "100%";
            }

            return "0%";

        }, [alreadyCompleted, durationInSeconds, playing, positionInSeconds]);

        return <PlayerWrapper
            className={classNames(playerSize === PlayerTypeEnum.FULL && 'full-size')}
        >
            <PlayerPlayBtnWrapper
                onClick={togglePlay}
                className={classNames(playerSize === PlayerTypeEnum.FULL && 'full-size')}
            >
                {
                    (!playing)
                        ? <PlayerPlayBtn
                            className={classNames(playerSize === PlayerTypeEnum.FULL && 'full-size')}
                        />
                        : <PlayerStopBtn
                            className={classNames(playerSize === PlayerTypeEnum.FULL && 'full-size')}
                        />
                }
                {
                    (playerSize === PlayerTypeEnum.FULL)
                    && <VoiceoverWavesStyled/>
                }
            </PlayerPlayBtnWrapper>
            <audio ref={smallAudioRef}
                   onCanPlay={onCanPlay}
                   onLoadedMetadata={onLoadedMetadata}
                   onTimeUpdate={onProgress}
                   onError={() => onError()}
                   onPlay={() => setPlaying(true)}
                   onPause={() => onPlayingStop()}
                   onEnded={() => onPlayingStop()}
            >
                {
                    fileLinks.map((linkItem) => {
                        return <source
                            key={linkItem.url}
                            src={linkItem.url}
                            type={linkItem.mimeType}
                        />
                    })
                }
            </audio>

            <ProgressBar className={'progressbar'} style={{
                width: progressBarWidth,
                transition: (durationInSeconds) ? `width ${durationInSeconds + 1}s linear` : 'unset'
            }}/>
        </PlayerWrapper>
    }
)