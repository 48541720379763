import {DiFactoryList} from '../components/DiFactory/DiFactoryList';
import {DiFactoryType} from '../components/DiFactory/DiFactoryType';
import {DiTokens} from '../di-factory/DiTokens';
import DateHelperServiceFactory from '../di-factory/DateHelperServiceFactory';
import HttpApiClientFactory from '../di-factory/HttpApiClientFactory';
import HttpClientFactory from '../di-factory/HttpClientFactory';
import I18nServiceFactory from '../di-factory/I18nServiceFactory';
import LoggerFactory from '../di-factory/LoggerFactory';
import LocaleDownloaderFactory from '../di-factory/LocaleDownloaderFactory';
import StoreServiceFactory from "../di-factory/StoreServiceFactory";
import SoundPlayerFactory from "../di-factory/SoundPlayerFactory";
import StonlineClientFactory from "../di-factory/StonlineClientFactory";
import WsStreamEventServiceFactory from "../di-factory/WsStreamEventServiceFactory";
import WsResponseRouterFactory from "../di-factory/WsResponseRouterFactory";
import WsApiClientFactory from "../di-factory/WsApiClientFactory";
import FeatureToggleFactory from "../di-factory/FeatureToggleFactory";
import RouterServiceFactory from "../di-factory/RouterServiceFactory";
import SentryLoggerFactory from "../di-factory/SentryLoggerFactory";
import AnswerCheckerFactory from "../di-factory/AnswerCheckerFactory";
import MediaDeviceServiceFactory from "../di-factory/MediaDeviceServiceFactory";
import AppVersionHelperFactory from "../di-factory/AppVersionHelperFactory";
import InternetConnectionCheckerFactory from "../di-factory/InternetConnectionCheckerFactory";
import WsDisconnectorFactory from "../di-factory/WsDisconnectorFactory";
import DeviceDetectorFactory from "../di-factory/DeviceDetectorFactory";
import SlidePlayerPatternHelperFactory from "../di-factory/SlidePlayerPatternHelperFactory";
import ClipboardHelperFactory from "../di-factory/ClipboardHelperFactory";
import GroupScheduleReaderFactory from "../di-factory/GroupScheduleReaderFactory";
import CustomStorageFactory from "../di-factory/CustomStorageFactory";
import PagesBroadcastServiceFactory from "../di-factory/PagesBroadcastServiceFactory";
import ScreenSaverLockFactory from "../di-factory/ScreenSaverLockFactory";
import DeviceMediaStreamFetcherFactory from "../di-factory/DeviceMediaStreamFetcherFactory";
import DeviceOrientationFactory from "../di-factory/DeviceOrientationFactory";
import JanusConnectionFactory from "../di-factory/JanusConnectionFactory";
import PushNotificationSubscriptionServiceFactory from "../di-factory/PushNotificationSubscriptionServiceFactory";

/**
 * Перечисление имеющихся контейнеров
 */
const diDependencies: DiFactoryList = [
    {
        token: DiTokens.SENTRY_LOGGER,
        factoryType: DiFactoryType.CACHING_FACTORY,
        factory: SentryLoggerFactory,
    },
    {
        token: DiTokens.CLIPBOARD_HELPER,
        factoryType: DiFactoryType.CACHING_FACTORY,
        factory: ClipboardHelperFactory,
    },
    {
        token: DiTokens.INTERNET_CONNECTION_CHECKER,
        factoryType: DiFactoryType.CACHING_FACTORY,
        factory: InternetConnectionCheckerFactory,
    },
    {
        token: DiTokens.DEVICE_DETECTOR,
        factoryType: DiFactoryType.CACHING_FACTORY,
        factory: DeviceDetectorFactory,
    },
    {
        token: DiTokens.ROUTER_SERVICE,
        factoryType: DiFactoryType.CACHING_FACTORY,
        factory: RouterServiceFactory,
    },
    {
        token: DiTokens.SLIDE_PLAYER_PATTERN_HELPER,
        factoryType: DiFactoryType.CACHING_FACTORY,
        factory: SlidePlayerPatternHelperFactory,
    },
    {
        token: DiTokens.DATE_HELPER_SERVICE,
        factoryType: DiFactoryType.CACHING_FACTORY,
        factory: DateHelperServiceFactory,
    },
    {
        token: DiTokens.HTTP_API_CLIENT,
        factoryType: DiFactoryType.CACHING_FACTORY,
        factory: HttpApiClientFactory,
    },
    {
        token: DiTokens.APP_VERSION_HELPER,
        factoryType: DiFactoryType.CACHING_FACTORY,
        factory: AppVersionHelperFactory,
    },
    {
        token: DiTokens.STONLINE_CLIENT,
        factoryType: DiFactoryType.CACHING_FACTORY,
        factory: StonlineClientFactory,
    },
    {
        token: DiTokens.HTTP_CLIENT,
        factoryType: DiFactoryType.CACHING_FACTORY,
        factory: HttpClientFactory,
    },
    {
        token: DiTokens.DEVICE_MEDIA_STREAM_FETCHER,
        factoryType: DiFactoryType.CACHING_FACTORY,
        factory: DeviceMediaStreamFetcherFactory
    },
    {
        token: DiTokens.DEVICE_ORIENTATION,
        factoryType: DiFactoryType.CACHING_FACTORY,
        factory: DeviceOrientationFactory
    },
    {
        token: DiTokens.ANSWER_CHECKER,
        factoryType: DiFactoryType.CACHING_FACTORY,
        factory: AnswerCheckerFactory,
    },
    {
        token: DiTokens.GROUP_SCHEDULE_READER,
        factoryType: DiFactoryType.CACHING_FACTORY,
        factory: GroupScheduleReaderFactory,
    },
    {
        token: DiTokens.I18N_SERVICE,
        factoryType: DiFactoryType.CACHING_FACTORY,
        factory: I18nServiceFactory,
    },
    {
        token: DiTokens.LOGGER,
        factoryType: DiFactoryType.CACHING_FACTORY,
        factory: LoggerFactory,
    },
    {
        token: DiTokens.CUSTOM_STORAGE,
        factoryType: DiFactoryType.CACHING_FACTORY,
        factory: CustomStorageFactory,
    },
    {
        token: DiTokens.PAGES_BROADCAST_SERVICE,
        factoryType: DiFactoryType.CACHING_FACTORY,
        factory: PagesBroadcastServiceFactory,
    },
    {
        token: DiTokens.SCREEN_SAVER_LOCK,
        factoryType: DiFactoryType.CACHING_FACTORY,
        factory: ScreenSaverLockFactory,
    },
    {
        token: DiTokens.LOCALE_DICTIONARY_DOWNLOADER,
        factoryType: DiFactoryType.CACHING_FACTORY,
        factory: LocaleDownloaderFactory,
    },
    {
        token: DiTokens.STORE_SERVICE,
        factoryType: DiFactoryType.CACHING_FACTORY,
        factory: StoreServiceFactory,
    },
    {
        token: DiTokens.SOUND_PLAYER,
        factoryType: DiFactoryType.CACHING_FACTORY,
        factory: SoundPlayerFactory,
    },
    {
        token: DiTokens.WS_RESPONSE_ROUTER,
        factoryType: DiFactoryType.CACHING_FACTORY,
        factory: WsResponseRouterFactory,
    },
    {
        token: DiTokens.WS_STREAM_EVENT_SERVICE,
        factoryType: DiFactoryType.CACHING_FACTORY,
        factory: WsStreamEventServiceFactory,
    },
    {
        token: DiTokens.WS_CLIENT,
        factoryType: DiFactoryType.CACHING_FACTORY,
        factory: WsApiClientFactory,
    },
    {
        token: DiTokens.WS_DISCONNECTOR,
        factoryType: DiFactoryType.CACHING_FACTORY,
        factory: WsDisconnectorFactory,
    },
    {
        token: DiTokens.JANUS_CONNECTION,
        factoryType: DiFactoryType.CACHING_FACTORY,
        factory: JanusConnectionFactory,
    },
    {
        token: DiTokens.FEATURE_TOGGLE,
        factoryType: DiFactoryType.CACHING_FACTORY,
        factory: FeatureToggleFactory,
    },
    {
        token: DiTokens.MEDIA_DEVICE_SERVICE,
        factoryType: DiFactoryType.CACHING_FACTORY,
        factory: MediaDeviceServiceFactory,
    },
    {
        token: DiTokens.PUSH_NOTIFICATION_SUBSCRIPTION_SERVICE,
        factoryType: DiFactoryType.CACHING_FACTORY,
        factory: PushNotificationSubscriptionServiceFactory,
    },
];

export default diDependencies;
