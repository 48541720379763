import {
    DtoLessonPlanItem
} from "../../../../../../../components/HttpApiClient/ApiDto/Response/Lessons/DtoLessonPlanItem";
import React from "react";
import styled from "styled-components";
import {Item} from "./Item";

const TableWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const TableItem = styled(Item)`
    padding: 10px 0;
    border-bottom: 1px solid ${({theme}) => theme.colors.headerDivider};
    
    &:nth-last-child(1) {
        border-bottom-style: none;
    }
`;

interface TableProps {
    items: DtoLessonPlanItem[]
}

export const Table: React.FC<TableProps> = (
    {
        items
    }
) => {
    return <TableWrapper>
        {
            items.map((item) => {
                return <TableItem key={item.utcStartDateTime} item={item}/>
            })
        }
    </TableWrapper>
}