import {IsBoolean, IsNotEmpty, IsString} from 'class-validator';

/**
 * DTO канала уведомлений
 */
export class DtoNotificationChannel {
    @IsNotEmpty()
    @IsString()
    alias:string;

    @IsBoolean()
    ready: boolean;

    @IsBoolean()
    enabled: boolean;
}