import {t} from "@lingui/macro";
import {MessageDialog} from "../Ui/Elements/MessageDialog";
import React, {forwardRef, useImperativeHandle, useMemo, useRef} from "react";
import {container} from "tsyringe";
import {IDeviceDetector} from "../../../components/DeviceDetector/IDeviceDetector";
import {DiTokens} from "../../../di-factory/DiTokens";
import {ChromeManual} from "./ChromeManual";
import {FirefoxManual} from "./FirefoxManual";
import {IphoneManual} from "./IphoneManual";
import {UnknownManual} from "./UnknownManual";
import {PopupActions as ReactjsPopupActions, PopupActions} from "reactjs-popup/dist/types";

export const WebPushEnableManualModal = forwardRef<PopupActions, {}>(
    (props, ref) => {
        const modalRef = useRef<ReactjsPopupActions>(null);

        // Методы, доступные родителю
        useImperativeHandle(ref, () => ({
            close: () => {
                modalRef.current?.close()
            },
            open: () => {
                modalRef.current?.open()
            },
            toggle: () => {
                modalRef.current?.toggle()
            },
        }));

        const content = useMemo<React.ReactNode>(() => {
            const deviceDetector = container.resolve<IDeviceDetector>(DiTokens.DEVICE_DETECTOR);

            if (
                deviceDetector.isEdge()
                || deviceDetector.isChrome()
                || deviceDetector.isYandexBrowser()
                || deviceDetector.isOpera()
            ) {
                return <ChromeManual/>;
            }

            if (deviceDetector.isFirefox()) {
                return <FirefoxManual/>;
            }

            if (deviceDetector.isIPhone()) {
                return <IphoneManual/>
            }

            return <UnknownManual/>
        }, []);

        return <MessageDialog
            ref={modalRef}
            title={t`Как включить уведомления на устройстве`}
            text={content}
            okText={t`Закрыть`}
        />
    });