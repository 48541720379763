import {Reducer} from "redux";
import {
    ItemHandleState,
    QueueItem,
    QueueItemContext,
    QueueSendCycleStateEnum,
    SlidesWorkDataSaveQueueActionTypes,
    SlidesWorkDataSaveQueueState
} from "./type";
import {initialState} from "./initialState";
import produce from "immer";
import {cloneDeep} from "lodash";

const reducer: Reducer<SlidesWorkDataSaveQueueState> = produce((draft, action) => {
    if (draft === undefined) {
        return;
    }

    switch (action.type) {
        case SlidesWorkDataSaveQueueActionTypes.SET_NEW_ITEM: {
            const payload = action.payload as QueueItem<QueueItemContext>;

            payload.itemNumber = draft.lastItemNumber + 1;
            draft.lastItemNumber = payload.itemNumber;

            draft.queue.push(action.payload);

            break;
        }
        case SlidesWorkDataSaveQueueActionTypes.SET_ITEMS_HANDLE_STATE: {
            const payload = action.payload as { itemIds: number[], newState: ItemHandleState };

            draft.queue = draft.queue.map((item) => {
                if (payload.itemIds.includes(item.itemNumber)) {
                    return {
                        ...item,
                        handleState: payload.newState
                    }
                } else {
                    return item;
                }
            });

            break;
        }
        case SlidesWorkDataSaveQueueActionTypes.SET_CYCLE_ENABLED_VALUE: {
            draft.sendCycleEnabled = action.payload as boolean;

            break;
        }
        case SlidesWorkDataSaveQueueActionTypes.SET_CYCLE_STATE_VALUE: {
            draft.sendCycleState = action.payload as QueueSendCycleStateEnum;

            break;
        }
        case SlidesWorkDataSaveQueueActionTypes.DELETE_ITEMS: {
            const payload = action.payload as number[];

            draft.queue = draft.queue.filter((item) => {
                return !payload.includes(item.itemNumber);
            });

            break;
        }
        case SlidesWorkDataSaveQueueActionTypes.CLEAR_ALL: {
            draft.queue = cloneDeep(initialState.queue);
            draft.sendCycleState = initialState.sendCycleState;
            draft.sendCycleEnabled = initialState.sendCycleEnabled;
            draft.lastItemNumber = initialState.lastItemNumber;

            break;
        }
    }
}, initialState);

export {reducer as slidesWorkDataSaveQueueReducer}