import {Ii18nService} from "./Ii18nService";
import {I18n, i18n, Messages} from "@lingui/core";
import {LocaleEnum} from "../../store/i18n/type";
import {PluralCategory} from "make-plural";

export class i18nService implements Ii18nService {
    protected i18nLocal: I18n;

    constructor() {
        this.i18nLocal = i18n;
    }

    /**
     * @inheritDoc
     */
    loadLocale(localeName: LocaleEnum, locale: Messages, plurals: (n: number, ordinal?: boolean | undefined) => PluralCategory): void {
        i18n.loadLocaleData({
            [localeName]: {
                plurals: plurals
            }
        });

        i18n.load(localeName, locale);
    }

    /**
     * @inheritDoc
     */
    activateLocale(localeName: LocaleEnum): void {
        i18n.activate(localeName);
    }

    /**
     * {@inheritDoc}
     */
    t(key: string): string {
        return this.i18nLocal._(key);
    }

    /**
     * {@inheritDoc}
     */
    locale(): string {
        return this.i18nLocal.locale;
    }
}