import {
    IsString,
    Length,
    IsNotEmpty, IsOptional,
} from 'class-validator';
import { BaseRequestDto } from '../BaseRequestDto';
import { LengthEnum } from '../../../Enums/LengthEnum';

/**
 * DTO параметров запроса авторизации пользователя-преподавателя.
 */
export class LoginTeacherDto extends BaseRequestDto {
    @IsNotEmpty()
    @IsString()
    @Length(1, LengthEnum.USER_LOGIN_LENGTH)
    phone: string;

    @IsNotEmpty()
    @IsString()
    @Length(1, LengthEnum.USER_PASSWORD_LENGTH)
    password: string;

    @IsOptional()
    @IsString()
    @Length(1, 250)
    origin: string;
}
