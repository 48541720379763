import React from "react";
import {NotificationChannelAliasEnum} from "../../../../../../../enums/NotificationChannelEnum";
import {BtnDefaultProps} from "./BtnDefaultProps";
import {EmailBtn} from "./EmailBtn";
import {WebPushBtn} from "./WebPushBtn";

export const Index: React.FC<BtnDefaultProps> = (props) => {
    switch (props.channelDto.alias as NotificationChannelAliasEnum) {
        case NotificationChannelAliasEnum.EMAIL: {
            return <EmailBtn groupId={props.groupId} channelDto={props.channelDto}/>;
        }
        case NotificationChannelAliasEnum.WEB_PUSH: {
            return <WebPushBtn groupId={props.groupId} channelDto={props.channelDto}/>;
        }
    }

    return null;
}