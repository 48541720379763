export enum ServiceWorkerActionTypes {
    CHECK = '@@serviceWorker/CHECK',
    ON_MESSAGE = '@@serviceWorker/ON_MESSAGE',
    SET_STATUS = '@@serviceWorker/ON_MESSAGE',
    SET_VERSION = '@@serviceWorker/SET_VERSION',
    SET_SUPPORTED_FEATURES = '@@serviceWorker/SET_SUPPORTED_FEATURES',
    SET_PUSH_SUBSCRIPTION_STATE = '@@serviceWorker/SET_PUSH_SUBSCRIPTION_STATE',
    SET_REGISTERED_PUSH_TOKEN_HASH = '@@serviceWorker/SET_REGISTERED_PUSH_TOKEN_HASH',
}

export enum PushNotificationActionEnum {
    OPEN_LINK = 'open-link'
}

export interface PushNotificationPayload {
    id: string;
    action: PushNotificationActionEnum;
    url: string;
    title: string;
    body: string;
    icon: string;
    picture: string | null;
}

export enum MessageFromServiceWorkerTypeEnum {
    CHECK_VERSION = 'general/version',
    ACTIVATED = 'general/activated'
}

export interface MessageFromServiceWorker<T> {
    type: MessageFromServiceWorkerTypeEnum;
    payload: T;
}

export enum MessageToServiceWorkerTypeEnum {
    CHECK_VERSION = 'general/version'
}

export interface MessageToServiceWorker<T> {
    type: MessageToServiceWorkerTypeEnum;
    payload: T;
}

export enum ServiceWorkerStatusEnum {
    UNKNOWN,
    CHECKING,
    READY,
    NOT_SUPPORTED
}

export interface SupportedFeatures {
    pushManager: boolean | null
}

export interface ServiceWorkerDataState {
    status: ServiceWorkerStatusEnum;
    version: string | null;
    pushSubscriptionState: null | boolean;
    supportedFeatures: SupportedFeatures;
    registeredPushTokenHash: string | null;
}

