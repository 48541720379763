import {IPushNotificationSubscriptionService} from "./IPushNotificationSubscriptionService";
import {IHttpApiClient} from "../../components/HttpApiClient/IHttpApiClient";
import md5 from "md5";

export class PushNotificationSubscriptionService implements IPushNotificationSubscriptionService {
    protected vapidPublic: string;
    protected httpApiClient: IHttpApiClient;


    constructor(vapidPublic: string, httpApiClient: IHttpApiClient) {
        this.vapidPublic = vapidPublic;
        this.httpApiClient = httpApiClient;
    }

    async subscribe(needRequestPermission: boolean): Promise<PushSubscription> {
        if (needRequestPermission) {
            const permission = await Notification.requestPermission();

            if (permission !== "granted") {
                throw new Error('Not granted');
            }
        }

        const swRegistration = await navigator.serviceWorker.ready;

        return await swRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: this.urlBase64ToUint8Array(this.vapidPublic)
        });
    }

    async registerSubscription(apiToken: string | null, subscriptionData: PushSubscriptionJSON): Promise<PushSubscriptionJSON> {
        if (apiToken === null) {
            throw new Error('Http api token is null');
        }

        this.validateSubscriptionData(subscriptionData);

        await this.httpApiClient.registerWebPushToken(
            apiToken,
            subscriptionData.endpoint ?? '',
            (subscriptionData.keys) ? subscriptionData.keys['p256dh'] : '',
            (subscriptionData.keys) ? subscriptionData.keys['auth'] : ''
        );

        return subscriptionData;
    }

    getTokenHash(subscriptionData: PushSubscriptionJSON): string {
        this.validateSubscriptionData(subscriptionData);

        return md5(
            (subscriptionData.endpoint ?? '')
            + ((subscriptionData.keys) ? subscriptionData.keys['p256dh'] : '')
            + ((subscriptionData.keys) ? subscriptionData.keys['auth'] : '')
        );
    }

    protected validateSubscriptionData(subscriptionData: PushSubscriptionJSON): void {
        if (
            !subscriptionData.endpoint
            || !subscriptionData.keys
            || !subscriptionData.keys['p256dh']
            || !subscriptionData.keys['auth']
        ) {
            throw new Error('Subscription data is incorrect: ' + JSON.stringify(subscriptionData));
        }
    }

    protected urlBase64ToUint8Array(base64String: string): Uint8Array {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }

        return outputArray;
    }
}