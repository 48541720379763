import {Reducer} from "redux";
import {CommonPersistedActionTypes, CommonPersistedState} from "./type";
import {initialState, muteConfigInitialState} from "./initialState";
import produce from "immer";

const reducer: Reducer<CommonPersistedState> = produce((draft, action) => {
    if (draft === undefined) {
        return;
    }

    switch (action.type) {
        case CommonPersistedActionTypes.SET_PROFILE_TYPE: {
            draft.profileType = action.payload;

            break;
        }
        case CommonPersistedActionTypes.SET_AUTH_TOKEN: {
            draft.authToken = action.payload;

            break;
        }
        case CommonPersistedActionTypes.SET_LOCALE: {
            draft.locale = action.payload;

            break;
        }
        case CommonPersistedActionTypes.SET_THEME_NAME: {
            draft.themeName = action.payload;

            break;
        }
        case CommonPersistedActionTypes.SET_ALLOWED_TO_USER_CAMERA_EARLY: {
            draft.allowedToUseCameraEarly = action.payload;

            break;
        }
        case CommonPersistedActionTypes.SET_LAST_AUDIO_INPUT_DEVICE_ID: {
            draft.lastAudioInputDeviceId = action.payload;

            break;
        }
        case CommonPersistedActionTypes.SET_LAST_AUDIO_OUTPUT_DEVICE_ID: {
            draft.lastAudioOutputDeviceId = action.payload;

            break;
        }
        case CommonPersistedActionTypes.SET_LAST_VIDEO_INPUT_DEVICE_ID: {
            draft.lastVideoInputDeviceId = action.payload;

            break;
        }
        case CommonPersistedActionTypes.SET_OTP_EMAIL: {
            draft.otpEmail = action.payload;

            break;
        }
        case CommonPersistedActionTypes.SET_UTM_FOR_SAVE: {
            draft.utmForSave = action.payload;

            break;
        }
        case CommonPersistedActionTypes.SET_PUSH_NOTIFICATION_BANNER_MUTE: {
            if (draft.muteConfig === undefined) {
                draft.muteConfig = {
                    ...muteConfigInitialState,
                    pushNotificationsBanner: true
                };
            } else {
                draft.muteConfig = {
                    ...draft.muteConfig,
                    pushNotificationsBanner: true
                };
            }

            break;
        }
        case CommonPersistedActionTypes.CLEAR_ALL_DATA: {
            draft.locale = initialState.locale;
            draft.themeName = initialState.themeName;
            draft.authToken = initialState.authToken;
            draft.muteConfig = initialState.muteConfig;

            break;
        }
    }
}, initialState);

export {reducer as commonPersistedReducer}
