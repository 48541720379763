import {IsBoolean, IsNotEmpty, IsOptional, IsString, ValidateNested,} from 'class-validator';
import {SchoolAccountDto} from "./SchoolAccountDto";

/**
 * Корневая DTO параметров ответа на запрос данных профиля пользователя
 */
export class UserProfileDataDto {
    @IsString()
    @IsNotEmpty()
    id: string;

    @IsOptional()
    @IsString()
    email: string | null;

    @IsString()
    @IsOptional()
    avatarFileId: string | null;

    @IsBoolean()
    deviceInfoAlreadySaved: boolean;

    @IsOptional()
    @IsString()
    userSavedTimezone: string | null;

    @IsBoolean()
    isTempProfile: boolean;

    @IsNotEmpty()
    @IsString()
    uiLocaleId: string;

    @IsOptional()
    @IsString()
    webPushTokenHash: string | null;

    @ValidateNested()
    schools: SchoolAccountDto[];
}
