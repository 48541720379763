import styled, {css} from "styled-components";
import {RegularTextCss} from "../../styles/global-elements";

export const ActionsList = styled.ol`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const ActionsListItem = styled.li`
    ${RegularTextCss};
    
    line-height: 27px;
`;

export const InlineIconCss = css`
    display: inline;
    vertical-align: middle;
`;