import React, {useMemo} from "react";
import {
    DtoAverageResultResponse
} from "../../../../../../components/HttpApiClient/ApiDto/Response/Lessons/DtoAverageResultResponse";
import {useSelector} from "react-redux";
import {selectedSchoolActualParams, selectedSchoolActualParamsCount} from "../../../../../../store/user/selector";
import {NoticeBlock, NoticeBlockText} from "../../../../../components/Ui/Elements/NoticeBlock";
import {t, Trans} from "@lingui/macro";
import {PageSubtitle} from "../../../../../styles/global-elements";
import {AverageValuesComponent, MetricItem} from "../../../common/AverageValuesComponent";
import {StudentRateParamPositionEnum} from "../../../../../../enums/StudentRateParamPositionEnum";
import {MainPageSection} from "../../style";

interface AverageValuesBlockProps {
    values: DtoAverageResultResponse;
}

export const AverageValues: React.FC<AverageValuesBlockProps> = ({values}) => {
    const params = useSelector(selectedSchoolActualParams);
    const paramsCount = useSelector(selectedSchoolActualParamsCount);

    const totalAverageItem = useMemo<MetricItem>(() => {
        if (paramsCount === 1) {
            return {
                name: t`Средняя оценка`,
                value: values.param1,
                paramPosition: null
            }
        }

        return {
            name: t`Общее среднее`,
            value: values.totalAverage,
            paramPosition: null
        }
    }, [paramsCount, values.param1, values.totalAverage]);

    const detailValues = useMemo<MetricItem[]>(() => {
        const result: MetricItem[] = [];

        if (paramsCount === 1) {
            return result;
        }

        if (params?.first) {
            result.push({
                name: params.first,
                value: values.param1,
                paramPosition: StudentRateParamPositionEnum.FIRST,
            });
        }

        if (params?.second) {
            result.push({
                name: params.second,
                value: values.param2 ?? 0,
                paramPosition: StudentRateParamPositionEnum.SECOND,
            });
        }

        if (params?.third) {
            result.push({
                name: params.third,
                value: values.param3 ?? 0,
                paramPosition: StudentRateParamPositionEnum.THIRD,
            });
        }

        if (params?.fourth) {
            result.push({
                name: params.fourth,
                value: values.param4 ?? 0,
                paramPosition: StudentRateParamPositionEnum.FOURTH,
            });
        }

        if (params?.fifth) {
            result.push({
                name: params.fifth,
                value: values.param5 ?? 0,
                paramPosition: StudentRateParamPositionEnum.FIFTH,
            });
        }

        return result;
    }, [params, values, paramsCount]);

    const valuesExist = useMemo<boolean>(() => {
        return values.totalAverage !== 0;
    }, [values]);

    const noValues = useMemo<JSX.Element>(() => {
        return <NoticeBlock>
            <NoticeBlockText>
                <Trans>Значений пока нет. Данные появятся как только вы посетите первый урок.</Trans>
            </NoticeBlockText>
        </NoticeBlock>
    }, []);

    return <MainPageSection>
        <PageSubtitle><Trans>Успеваемость</Trans></PageSubtitle>
        {
            (!valuesExist)
                ? noValues
                : <AverageValuesComponent totalAverageItem={totalAverageItem} detailValues={detailValues}/>
        }
    </MainPageSection>
}

AverageValues.displayName = 'AverageValues';