import "reflect-metadata";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './views/App';
import * as serviceWorkerRegistration from './components/ServiceWorkerRegistrator/index';
// import reportWebVitals from './reportWebVitals';
import {DiFactoryRegistrator} from './components/DiFactory/DiFactoryRegistrator';
import diDependencies from './config/Container';
import {PersistGate} from 'redux-persist/es/integration/react'
import {container} from "tsyringe";
import {IStoreService} from "./services/store/IStoreService";
import {DiTokens} from "./di-factory/DiTokens";
import {i18n} from "@lingui/core";
import {LocaleEnum} from "./store/i18n/type";
import {ru} from "make-plural";
import {ISentryLogger} from "./components/SentryLogger/ISentryLogger";

// 0. Регистрация DI зависимостей.
DiFactoryRegistrator.registerFactories(diDependencies);

// 1. Регистрация Sentry, если это разрешено
if (!process.env.REACT_APP_SENTRY_LOGGER_ENABLED) {
    throw new Error('Check REACT_APP_SENTRY_LOGGER_ENABLED env parameter');
}

if (process.env.REACT_APP_SENTRY_LOGGER_ENABLED === "true") {
    if (!process.env.REACT_APP_SENTRY_DSN) {
        throw new Error('Check REACT_APP_SENTRY_DSN env parameter');
    }

    const sentryLogger = container.resolve<ISentryLogger>(DiTokens.SENTRY_LOGGER);
    sentryLogger.init(process.env.REACT_APP_SENTRY_DSN);
}

const storeService = container.resolve<IStoreService>(DiTokens.STORE_SERVICE);

// Инициализация i18n пустым словарём
i18n.load(LocaleEnum.RU, {});
i18n.loadLocaleData({
    [LocaleEnum.RU]: {plurals: ru}
});
i18n.activate(LocaleEnum.RU);

ReactDOM.render(
    <React.StrictMode>
        <PersistGate persistor={storeService.persistor}>
            <App store={storeService.store}/>
        </PersistGate>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
