import React from "react";
import {t} from "@lingui/macro";
import {SettingsSectionsEnum} from "./SettingsSectionsEnum";
import {PageWithNavigation} from "../../../components/PageWithNavigation";
import GeneralSection from "./sections/general/GeneralSection";
import {NotificationsSection} from "./sections/notifications/index";
import {SecuritySection} from "./sections/security/SecuritySection";

export const ProfileSettingsPage: React.FC = () => {
    return <PageWithNavigation
        title={t`Настройки профиля`}
        activeSection={SettingsSectionsEnum.GENERAL}
        items={[
            {
                id: SettingsSectionsEnum.GENERAL,
                name: t`Основные`,
                body: <GeneralSection/>
            },
            {
                id: SettingsSectionsEnum.SECURITY,
                name: t`Безопасность`,
                body: <SecuritySection/>
            },
            {
                id: SettingsSectionsEnum.NOTIFICATIONS,
                name: t`Уведомления`,
                body: <NotificationsSection/>
            }
        ]}
    />
}
