import {IsBoolean, IsNotEmpty, IsNumber, IsOptional, IsString, Length, ValidateNested,} from 'class-validator';
import {ActualParamsDto} from "./ActualParamsDto";
import {LengthEnum} from "../../../../Enums/LengthEnum";

/**
 * Корневая DTO параметров ответа на запрос данных профиля пользователя-преподавателя
 */
export class TeacherProfileDataDto {
    @IsString()
    @IsNotEmpty()
    id: string;

    @IsNotEmpty()
    @IsString()
    @Length(LengthEnum.UUID_LENGTH, LengthEnum.UUID_LENGTH)
    schoolId: string;

    @IsOptional()
    @IsString()
    @Length(LengthEnum.UUID_LENGTH, LengthEnum.UUID_LENGTH)
    avatarFileId: string | null;

    @IsNotEmpty()
    @IsNumber()
    teacherIdInSchool: number;

    @IsNotEmpty()
    @IsString()
    @Length(1, LengthEnum.SCHOOL_NAME_LENGTH)
    schoolName: string;

    @IsNotEmpty()
    @IsString()
    @Length(1, LengthEnum.TIMEZONE_NAME_LENGTH)
    schoolTimezoneName: string;

    @IsNotEmpty()
    @ValidateNested()
    actualParams: ActualParamsDto;

    @IsNotEmpty()
    @IsString()
    @Length(1, LengthEnum.USER_NAME_LENGTH)
    name: string;

    @IsString()
    @IsOptional()
    dateBorn: string | null;

    @IsNotEmpty()
    @IsString()
    @Length(1, LengthEnum.ST_TOKEN_LENGTH)
    stToken: string;

    @IsNotEmpty()
    @IsString()
    uiLocaleId: string;

    @IsBoolean()
    isModerator: boolean;

    @IsBoolean()
    needInitialCustomizer: boolean;

    @IsBoolean()
    deviceInfoAlreadySaved: boolean;

    @IsOptional()
    @IsString()
    webPushTokenHash: string | null;

    @IsOptional()
    @IsString()
    userSavedTimezone: string | null;
}
