import {Reducer} from "redux";
import {ServiceWorkerActionTypes, ServiceWorkerDataState} from "./type";
import produce from "immer";
import {initialState} from "./initialState";

const reducer: Reducer<ServiceWorkerDataState> = produce((draft, action) => {
    if (draft === undefined) {
        return;
    }

    switch (action.type) {
        case ServiceWorkerActionTypes.SET_STATUS: {
            draft.status = action.payload;

            break;
        }
        case ServiceWorkerActionTypes.SET_VERSION: {
            draft.version = action.payload;

            break;
        }
        case ServiceWorkerActionTypes.SET_SUPPORTED_FEATURES: {
            draft.supportedFeatures = action.payload;

            break;
        }
        case ServiceWorkerActionTypes.SET_PUSH_SUBSCRIPTION_STATE: {
            draft.pushSubscriptionState = action.payload;

            break;
        }
        case ServiceWorkerActionTypes.SET_REGISTERED_PUSH_TOKEN_HASH: {
            draft.registeredPushTokenHash = action.payload;

            break;
        }
    }
}, initialState);

export {reducer as serviceWorkerReducer}
