import {Reducer} from "redux";
import {initialState} from "./initialState";
import {
    ActiveSubscriptionUserItem,
    OnlineStateEnum,
    UserOnlineStateSubscriptionsActionTypes,
    UserOnlineStateSubscriptionsState
} from "./type";
import produce from "immer";

const reducer: Reducer<UserOnlineStateSubscriptionsState> = produce((draft: UserOnlineStateSubscriptionsState, action) => {
    switch (action.type) {
        case UserOnlineStateSubscriptionsActionTypes.PUSH_USERS_TO_WAIT_LIST: {
            draft.waitForSubscribe.push(...action.payload);

            break;
        }
        case UserOnlineStateSubscriptionsActionTypes.DELETE_USERS_FROM_WAIT_LIST: {
            const itemsForDelete: Array<number> = action.payload;

            draft.waitForSubscribe = draft.waitForSubscribe.filter((userId: number) => {
                return (itemsForDelete.indexOf(userId) < 0);
            });

            break;
        }
        case UserOnlineStateSubscriptionsActionTypes.PUSH_TO_ACTIVE_SUBSCRIPTIONS_LIST: {
            const input: { [userId: number]: ActiveSubscriptionUserItem } = action.payload;

            draft.activeSubscriptions = {...draft.activeSubscriptions};

            for (let currentUserId in input) {
                if (!draft.activeSubscriptions.items[currentUserId]) {
                    draft.activeSubscriptions.itemsCount = draft.activeSubscriptions.itemsCount + 1;
                }

                draft.activeSubscriptions.items[currentUserId] = input[currentUserId];
            }

            break;
        }
        case UserOnlineStateSubscriptionsActionTypes.UPDATE_STATE_IN_ACTIVE_SUBSCRIPTIONS_LIST: {
            const input: { [id: number]: OnlineStateEnum } = action.payload;

            for (let currentUserId in input) {
                if (!draft.activeSubscriptions.items[currentUserId]) {
                    continue;
                }

                draft.activeSubscriptions.items[currentUserId].updatedAt = new Date();
                draft.activeSubscriptions.items[currentUserId].currentState = input[currentUserId];
            }

            break;
        }
        case UserOnlineStateSubscriptionsActionTypes.DELETE_FROM_ACTIVE_SUBSCRIPTIONS_LIST: {
            const itemsForRemove: Array<number> = action.payload;
            const itemsForRemoveCount = itemsForRemove.length;

            draft.activeSubscriptions = {...draft.activeSubscriptions};

            for (let index = 0; index < itemsForRemoveCount; index++) {
                if (draft.activeSubscriptions.items[itemsForRemove[index]]) {
                    delete draft.activeSubscriptions.items[itemsForRemove[index]];
                    draft.activeSubscriptions.itemsCount = draft.activeSubscriptions.itemsCount - 1;
                }
            }

            break;
        }
        case UserOnlineStateSubscriptionsActionTypes.CLEAR_ACTIVE_SUBSCRIPTIONS_LIST: {
            draft.activeSubscriptions = {...initialState.activeSubscriptions};

            break;
        }
    }
}, initialState);

export {reducer as userOnlineStateSubscriptionsReducer}