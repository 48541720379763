import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import styled from "styled-components";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {ILogger} from "../../../../../../components/Logger/ILogger";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../../../store/app/selector";
import {PageLoadingStateEnum} from "../../../../../../enums/PageLoadingStateEnum";
import {
    DtoNotificationGroup
} from "../../../../../../components/HttpApiClient/ApiDto/Response/Notifications/DtoNotificationGroup";
import {LoggerSectionsEnum} from "../../../../../../components/Logger/LoggerSectionsEnum";
import {DefaultLoader} from "../../../../../components/DefaultLoader";
import {ErrorLoadingContent} from "../../../../../components/Ui/Elements/ErrorLoadingContent";
import {GroupItem} from "./GroupItem";
import {PageSubtitle2} from "../../../../../styles/global-elements";
import {Trans} from "@lingui/macro";
import {NotificationsSectionContextProvider} from "./Context";
import {NoticeBlock, NoticeBlockText} from "../../../../../components/Ui/Elements/NoticeBlock";
import {IDeviceDetector} from "../../../../../../components/DeviceDetector/IDeviceDetector";
import {
    pushNotificationsSupportedSelector,
    pushSubscriptionStateSelector, serviceWorkerStatusSelector, serviceWorkerVersionSelector
} from "../../../../../../store/serviceWorker/selector";

const Wrapper = styled.div`
`;

const GroupsList = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
`;

const GroupItemStyled = styled(GroupItem)`
    padding: 10px 0px;
    border-bottom: 1px ${({theme}) => theme.colors.accentDivider} solid;
    transition: border-bottom-color 0.3s ease;

    &:hover {
        border-bottom-color: ${({theme}) => theme.colors.backgroundInverse};
    }
`;

export const NotificationsSection: React.FC = () => {
    const sessionToken = useSelector(sessionTokenSelector);
    const pushSubscriptionState = useSelector(pushSubscriptionStateSelector);
    const pushNotificationsSupported = useSelector(pushNotificationsSupportedSelector);
    const serviceWorkerStatus = useSelector(serviceWorkerStatusSelector);
    const serviceWorkerVersion = useSelector(serviceWorkerVersionSelector);

    const tapCounter = useRef<number>(0);
    const [loadingState, setLoadingState] = useState<PageLoadingStateEnum>(PageLoadingStateEnum.NOT_INIT);
    const [settingsList, setSettingsList] = useState<DtoNotificationGroup[] | null>(null);
    const [showWebPushDetails, setShowWebPushDetails] = useState<boolean>(false);

    const fetchContent = useCallback((silent: boolean = false) => {
        if (!sessionToken) {
            setLoadingState(PageLoadingStateEnum.ERROR);

            return Promise.reject();
        }

        if (!silent) {
            setLoadingState(PageLoadingStateEnum.LOADING);
        }

        const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        const logger = container.resolve<ILogger>(DiTokens.LOGGER);

        return httpApiClient.notificationsGetSettingsList(sessionToken)
            .then((data) => {
                setSettingsList(data.data.list);
                setLoadingState(PageLoadingStateEnum.SUCCESS);
            })
            .catch((e) => {
                setLoadingState(PageLoadingStateEnum.ERROR);

                logger.error(LoggerSectionsEnum.NOTIFICATION_SETTINGS_LIST, 'Error loading settings list: ', e);
            })
    }, [sessionToken]);

    const fetchContentSilent = useCallback(() => {
        return fetchContent(true);
    }, [fetchContent]);

    useEffect(() => {
        fetchContent();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const noticeOnClick = useCallback(() => {
        tapCounter.current = tapCounter.current + 1;

        if (tapCounter.current > 5) {
            setShowWebPushDetails(true);
        }
    }, []);

    const details = useMemo(() => {
        const deviceDetector = container.resolve<IDeviceDetector>(DiTokens.DEVICE_DETECTOR);

        let result = '';

        result = 'PushManager in window: ' + (('PushManager' in window) ? 'true' : 'false');
        result = result + '\n';
        result = result + 'deviceDetector.webPushIsSupported: ' + ((deviceDetector.webPushIsSupported) ? 'true' : 'false');
        result = result + '\n\n';
        result = result + 'pushSubscriptionState: ' + ((pushSubscriptionState) ? 'true' : 'false');
        result = result + '\n';
        result = result + 'serviceWorkerStatus: ' + serviceWorkerStatus + '\n(0 - not init, 1 - checking, 2 - ready, 3 - not supported)';
        result = result + '\n';
        result = result + 'serviceWorkerVersion: ' + serviceWorkerVersion;
        result = result + '\n';
        result = result + 'pushNotificationsSupported: ' + ((pushNotificationsSupported) ? 'true' : 'false');
        result = result + '\n\n';

        result = result + JSON.stringify(deviceDetector.getInfo(), null, "\t");

        return result;
    }, [pushNotificationsSupported, pushSubscriptionState, serviceWorkerStatus, serviceWorkerVersion]);

    const content = useMemo(() => {
        switch (loadingState) {
            case PageLoadingStateEnum.NOT_INIT:
            case PageLoadingStateEnum.LOADING: {
                return <DefaultLoader/>;
            }
            case PageLoadingStateEnum.ERROR: {
                return <ErrorLoadingContent retryBtnClick={fetchContent}/>
            }
            default: {
                return <GroupsList>
                    {
                        settingsList?.map((item) => {
                            return <GroupItemStyled key={item.alias} data={item}/>
                        })
                    }
                </GroupsList>
            }
        }
    }, [fetchContent, loadingState, settingsList]);

    return <Wrapper>
        <PageSubtitle2><Trans>Уведомления</Trans></PageSubtitle2>
        <NoticeBlock onClick={noticeOnClick}>
            <NoticeBlockText>
                <Trans>Уведомления работают в тестовом режиме.</Trans>
            </NoticeBlockText>
        </NoticeBlock>
        {
            (showWebPushDetails)
            && <>
            <pre>
                {details}
            </pre>
            </>
        }
        <br/>
        <NotificationsSectionContextProvider value={{
            reloadSettings: fetchContentSilent
        }}>
            {content}
        </NotificationsSectionContextProvider>
    </Wrapper>
}