import React from "react";
import {ActionsList, ActionsListItem, InlineIconCss} from "./styles";
import {ReactComponent as ChromeSettingsIcon} from "../Ui/Svg/ChromeSettingsNoBorder.svg";
import {ReactComponent as RingNoBorderIcon} from "../Ui/Svg/RingNoBorder.svg";
import {ReactComponent as ToggleOnNoBorderIcon} from "../Ui/Svg/ToggleOnNoBorder.svg";
import {Trans} from "@lingui/macro";
import styled from "styled-components";

const ChromeSettingsIconStyled = styled(ChromeSettingsIcon)`
    ${InlineIconCss};
`;

const RingNoBorderIconStyled = styled(RingNoBorderIcon)`
    ${InlineIconCss};
`;

const ToggleOnNoBorderIconStyled = styled(ToggleOnNoBorderIcon)`
    ${InlineIconCss};
`;

export const ChromeManual: React.FC = () => {
    return <ActionsList>
        <ActionsListItem>
            <Trans>
                Нажмите на значок <ChromeSettingsIconStyled/> в адресной строке браузера (в верхней части экрана).
            </Trans>
        </ActionsListItem>
        <ActionsListItem>
            <Trans>
                Для пункта «<RingNoBorderIconStyled/> Уведомления» выберите «Разрешить» или <ToggleOnNoBorderIconStyled/>.
            </Trans>
        </ActionsListItem>
    </ActionsList>
}