import React from "react";

export interface INotificationsSectionContext {
    reloadSettings: () => Promise<void>;
}

export const NotificationsSectionContext  = React.createContext<INotificationsSectionContext>({
    reloadSettings: () => Promise.resolve()
});

export const NotificationsSectionContextProvider = NotificationsSectionContext.Provider;