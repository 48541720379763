import {call, put, select, takeEvery} from "redux-saga/effects";
import {
    MessageFromServiceWorker,
    MessageFromServiceWorkerTypeEnum,
    ServiceWorkerActionTypes,
    ServiceWorkerStatusEnum,
    SupportedFeatures
} from "../../store/serviceWorker/type";
import * as ServiceWorkerActions from "../../store/serviceWorker/actions";
import {ILogger} from "../../components/Logger/ILogger";
import {container} from "tsyringe";
import {DiTokens} from "../../di-factory/DiTokens";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";
import {WorkerPayloadType} from "../WorkerPayloadType";
import {registeredPushTokenHashSelector} from "../../store/serviceWorker/selector";
import {
    IPushNotificationSubscriptionService
} from "../../services/push-notifications/IPushNotificationSubscriptionService";
import {IHttpApiClient} from "../../components/HttpApiClient/IHttpApiClient";
import {sessionTokenSelector} from "../../store/app/selector";
import {IDeviceDetector} from "../../components/DeviceDetector/IDeviceDetector";

/**
 * Сага отвечает за инициализацию service worker в рамках приложения (не за регистрацию).
 */
export function* watchSwOnMessage() {
    yield takeEvery<WorkerPayloadType<MessageFromServiceWorker<any>>>(
        ServiceWorkerActionTypes.ON_MESSAGE,
        swOnMessage
    );
}

function* swOnMessage(data: WorkerPayloadType<MessageFromServiceWorker<any>>) {
    const logger = container.resolve<ILogger>(DiTokens.LOGGER);
    const pushSubscriptionService = container.resolve<IPushNotificationSubscriptionService>(DiTokens.PUSH_NOTIFICATION_SUBSCRIPTION_SERVICE);
    const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
    const deviceDetector = container.resolve<IDeviceDetector>(DiTokens.DEVICE_DETECTOR);

    const sessionToken = (yield select(sessionTokenSelector)) as string | null;

    if (sessionToken === null) {
        logger.error(LoggerSectionsEnum.SERVICE_WORKER_ON_MESSAGE, 'Called without session token');

        return;
    }

    const receivedMessage = data.payload;

    switch (receivedMessage.type) {
        case MessageFromServiceWorkerTypeEnum.CHECK_VERSION: {
            let workerRegistration: ServiceWorkerRegistration | null = null;

            try {
                workerRegistration =
                    (yield call(() => navigator.serviceWorker.ready)) as ServiceWorkerRegistration;
            } catch (error) {
                logger.warning(LoggerSectionsEnum.SERVICE_WORKER_ON_MESSAGE, 'SW registration error: ', error);

                return;
            }

            if (workerRegistration === null) {
                logger.warning(LoggerSectionsEnum.SERVICE_WORKER_ON_MESSAGE, 'SW registration error: registration is null');

                return;
            }

            yield put(ServiceWorkerActions.setStatus(ServiceWorkerStatusEnum.READY));
            yield put(ServiceWorkerActions.setVersion(receivedMessage.payload));

            const supportedFeatures: SupportedFeatures = {
                pushManager: deviceDetector.webPushIsSupported(),
            }

            const featuresAsString = Object.keys(supportedFeatures).filter((key) => (supportedFeatures as unknown as { [id: string]: boolean })[key]).join(', ');

            logger.info(LoggerSectionsEnum.SERVICE_WORKER_ON_MESSAGE, `Version of SW: ${receivedMessage.payload}, supported features: ${featuresAsString}`);

            yield put(ServiceWorkerActions.setSupportedFeatures(supportedFeatures));

            const pushSubscriptionInfo = (yield call(() => {
                if (workerRegistration === null) {
                    return Promise.resolve(null);
                }

                // У safari может не быть pushManager
                if (!workerRegistration.pushManager) {
                    return Promise.resolve(null);
                }

                return workerRegistration.pushManager.getSubscription();
            })) as null | PushSubscription;

            let notificationsAllowed = false;

            if (pushSubscriptionInfo) {
                // Подписка есть, но пользователь мог запретить уведомления в настройках браузера.
                notificationsAllowed = Notification.permission === 'granted';
            }

            // Сверим подписку с сохранённой в текущей сессии
            const registeredSubscriptionHash = (yield select(registeredPushTokenHashSelector)) as string | null;

            if (pushSubscriptionInfo && notificationsAllowed) {
                // Если в браузере пуши разрешены и подписка есть
                const pushSubscriptionJson = pushSubscriptionInfo.toJSON();
                const subscriptionHash = pushSubscriptionService.getTokenHash(pushSubscriptionJson);

                if (registeredSubscriptionHash !== subscriptionHash) {
                    try {
                        // Нужно зарегистрировать имеющийся токен в текущей сессии
                        yield call(() => pushSubscriptionService.registerSubscription(sessionToken, pushSubscriptionJson));
                        logger.info(LoggerSectionsEnum.SERVICE_WORKER_ON_MESSAGE, 'Push token successfully registered in session');
                    } catch (e) {
                        logger.error(LoggerSectionsEnum.SERVICE_WORKER_ON_MESSAGE, 'Update registered token error: ', e);
                    }
                } else {
                    logger.info(LoggerSectionsEnum.SERVICE_WORKER_ON_MESSAGE, 'Token, registered in session, is up to date');
                }
            } else {
                // Если в браузере пуши не разрешены или подписка не оформлена
                if (registeredSubscriptionHash !== null) {
                    // Если в сессии подписка есть (но пользователь отключил пуши в настройках браузера)
                    try {
                        // Нужно удалить имеющийся токен в текущей сессии
                        yield call(() => httpApiClient.clearSessionWebPushToken(sessionToken));
                        logger.info(LoggerSectionsEnum.SERVICE_WORKER_ON_MESSAGE, 'Outdated push token successfully unregistered from session');
                    } catch (e) {
                        logger.error(
                            LoggerSectionsEnum.SERVICE_WORKER_ON_MESSAGE,
                            'Clear registered in session token error: ',
                            e
                        );
                    }
                }
            }

            yield put(ServiceWorkerActions.setPushSubscriptionState(!!pushSubscriptionInfo && notificationsAllowed));

            break;
        }

        case MessageFromServiceWorkerTypeEnum.ACTIVATED: {
            logger.info(LoggerSectionsEnum.SERVICE_WORKER_ON_MESSAGE, `Activated new version of SW: ${receivedMessage.payload}`);

            yield put(ServiceWorkerActions.setVersion(receivedMessage.payload));

            break;
        }
    }
}