import {VcsStateActionTypes} from "./types";
import {action} from "typesafe-actions";
import {LessonConferenceStatusEnum} from "../../views/components/LessonConference/contexts/CommonContext";
import {
    AudioRoomConnectionState,
    ParticipantItem,
    VideoRoomConnectionState
} from "../../views/components/LessonConference/Types";

export const setStatus = (newStatus: LessonConferenceStatusEnum) => {
    return action(VcsStateActionTypes.SET_STATUS, newStatus);
};

export const setParticipants = (participantsList: ParticipantItem[]) => {
    return action(VcsStateActionTypes.SET_PARTICIPANTS, participantsList);
};

export const setAudioRoomState = (audioRoomState: AudioRoomConnectionState) => {
    return action(VcsStateActionTypes.SET_AUDIO_ROOM_STATE, audioRoomState);
};

export const setVideoRoomState = (videoRoomState: VideoRoomConnectionState) => {
    return action(VcsStateActionTypes.SET_VIDEO_ROOM_STATE, videoRoomState);
};

export const resetState = () => {
    return action(VcsStateActionTypes.RESET_STATE);
};
