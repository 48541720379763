/* eslint-disable no-extra-label */
import {call, delay, put, putResolve, select, takeEvery} from 'redux-saga/effects';
import {
    AdditionalDataPayload,
    AnswerPayload,
    ItemHandleState,
    OverriddenParamsPayload,
    QueueItem,
    QueueItemContext,
    QueueSendCycleStateEnum,
    SlidesWorkDataSaveQueueActionTypes,
    ValuePayload
} from "../../store/slidesWorkDataSaveQueue/type";
import {
    handleCycleEnabledSelector,
    handleCycleStatusSelector,
    itemsForSendSelector
} from "../../store/slidesWorkDataSaveQueue/selector";
import {
    deleteItems,
    setCycleEnabledValue,
    setCycleStateValue,
    setItemsHandleState
} from "../../store/slidesWorkDataSaveQueue/actions";
import {container} from "tsyringe";
import {IWsApiClient} from "../../components/WsApiClient/IWsApiClient";
import {DiTokens} from "../../di-factory/DiTokens";
import {
    DtoSaveSlideWorkDataQueueRequest
} from "../../components/WsApiClient/ApiDto/Request/SlidesWorkData/DtoSaveSlideWorkDataQueueRequest";
import {
    QueueItem as WsQueueItem,
    QueueItemDataTypeEnum
} from "../../components/WsApiClient/ApiDto/Request/SlidesWorkData/QueueItem";
import {
    QueueItemValuePayload as WsQueueItemValuePayload
} from "../../components/WsApiClient/ApiDto/Request/SlidesWorkData/QueueItemValuePayload";
import {
    QueueItemAdditionalDataPayload as WsQueueItemAdditionalDataPayload
} from "../../components/WsApiClient/ApiDto/Request/SlidesWorkData/QueueItemAdditionalDataPayload";
import {
    QueueItemOverriddenParamsPayload as WsQueueItemOverriddenParamsPayload
} from "../../components/WsApiClient/ApiDto/Request/SlidesWorkData/QueueItemOverriddenParamsPayload";
import {
    QueueItemAnswerPayload as WsQueueItemAnswerPayload
} from "../../components/WsApiClient/ApiDto/Request/SlidesWorkData/QueueItemAnswerPayload";
import {ApiMethodEnum} from "../../components/WsApiClient/ApiMethodEnum";
import {
    DtoSaveSlideWorkDataQueueResponse
} from "../../components/WsApiClient/ApiDto/Request/SlidesWorkData/DtoSaveSlideWorkDataQueueResponse";
import {ResponseActionCreatorPayload} from "../../components/WsApiClient/ResponseActionCreatorPayload";
import {ResponseBaseDto} from "../../components/WsApiClient/ApiDto/Response/ResponseBaseDto";
import {WsResponseStatusEnum} from "../../components/WsApiClient/WsResponseStatusEnum";
import {ILogger} from "../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";
import {setInFatalErrorState} from "../../store/app/actions";

export function* watchSendQueue() {
    yield takeEvery(
        SlidesWorkDataSaveQueueActionTypes.SEND_QUEUE,
        sendQueue
    );
}

function* sendQueue() {
    const handleCycleStatus = (yield select(handleCycleStatusSelector)) as QueueSendCycleStateEnum;
    let handleCycleEnabled = (yield select(handleCycleEnabledSelector)) as boolean;

    const logger = container.resolve<ILogger>(DiTokens.LOGGER);

    if (!handleCycleEnabled || handleCycleStatus !== QueueSendCycleStateEnum.STOPPED) {
        logger.warning(LoggerSectionsEnum.SLIDES_WORK_DATA_SAVE_QUEUE, 'Redundant call sendQueue');

        return;
    }

    logger.info(LoggerSectionsEnum.SLIDES_WORK_DATA_SAVE_QUEUE, 'Send queue started');

    // Устанавливаем в store текущий режим
    yield putResolve(setCycleStateValue(QueueSendCycleStateEnum.WORKING));

    const wsApiClient = container.resolve<IWsApiClient>(DiTokens.WS_CLIENT);

    mainCycleLoop:
        while (true) {
            // Проверяем разрешение на работу
            handleCycleEnabled = (yield select(handleCycleEnabledSelector)) as boolean;

            if (!handleCycleEnabled) {
                break;
            }

            // Берём элементы очереди
            const itemsForSend = (yield select(itemsForSendSelector)) as QueueItem<QueueItemContext>[];

            if (itemsForSend.length === 0) {
                break;
            }

            // Устанавливаем для элементов состояние "отправляется"
            yield putResolve(setItemsHandleState(itemsForSend.map(item => item.itemNumber), ItemHandleState.IN_PROCESS));

            // Отправляем пачку
            const requestDto = new DtoSaveSlideWorkDataQueueRequest();
            requestDto.items = [];

            const itemsCount = itemsForSend.length;

            for (let index = 0; index < itemsCount; index++) {
                const queueItemDto = new WsQueueItem();

                queueItemDto.itemNumber = itemsForSend[index].itemNumber;
                queueItemDto.targetType = itemsForSend[index].targetType;
                queueItemDto.targetUserId = itemsForSend[index].targetUserId;
                queueItemDto.slideItemId = itemsForSend[index].slideItemId;
                queueItemDto.exerciseId = itemsForSend[index].exerciseId;
                queueItemDto.dataType = itemsForSend[index].dataType;
                queueItemDto.contextData = itemsForSend[index].contextData;

                switch (queueItemDto.dataType) {
                    case QueueItemDataTypeEnum.VALUE: {
                        const payloadDto = new WsQueueItemValuePayload();

                        payloadDto.value = (itemsForSend[index].payload as ValuePayload).value;
                        queueItemDto.payload = payloadDto;

                        break;
                    }
                    case QueueItemDataTypeEnum.ANSWER: {
                        const payloadDto = new WsQueueItemAnswerPayload();

                        const queueItemPayload = itemsForSend[index].payload as AnswerPayload;

                        payloadDto.value = queueItemPayload.value;
                        payloadDto.missedAward = queueItemPayload.missedAward;
                        payloadDto.award = queueItemPayload.award;
                        payloadDto.answerIsCorrect = queueItemPayload.answerIsCorrect;

                        queueItemDto.payload = payloadDto;

                        break;
                    }
                    case QueueItemDataTypeEnum.ADDITIONAL_DATA: {
                        const payloadDto = new WsQueueItemAdditionalDataPayload();

                        payloadDto.value = (itemsForSend[index].payload as AdditionalDataPayload).value;
                        queueItemDto.payload = payloadDto;

                        break;
                    }
                    case QueueItemDataTypeEnum.OVERRIDDEN_PARAMS: {
                        const payloadDto = new WsQueueItemOverriddenParamsPayload();

                        payloadDto.visible = (itemsForSend[index].payload as OverriddenParamsPayload).visible;
                        queueItemDto.payload = payloadDto;

                        break;
                    }
                }

                requestDto.items.push(queueItemDto);
            }

            logger.info(
                LoggerSectionsEnum.SLIDES_WORK_DATA_SAVE_QUEUE,
                'Send batch with items: ' + requestDto.items.map(item => item.itemNumber).join(', ')
            );

            const sendResult = (yield call(() => {
                return wsApiClient.queryAsPromise(
                    ApiMethodEnum.SLIDES_WORK_DATA_SAVE_QUEUE_ITEMS,
                    requestDto,
                    DtoSaveSlideWorkDataQueueResponse
                )
            })) as ResponseActionCreatorPayload<ResponseBaseDto<DtoSaveSlideWorkDataQueueResponse>, null>

            if (sendResult.response.status !== WsResponseStatusEnum.OK) {
                // Что-то не получилось

                // Возвращаем состояние ожидания элементам
                yield putResolve(setItemsHandleState(itemsForSend.map(item => item.itemNumber), ItemHandleState.WAIT));

                // Обрабатываем ситуацию
                if (sendResult.response.status === WsResponseStatusEnum.CONNECTION_ERROR){
                    // Проверим - знаем ли мы, что сети нет?
                    if (wsApiClient.connectionIsOpen()) {
                        // Про возможное отсутствие сети мы ещё не знаем, но скорее всего её нет.
                        // Разрываем существующее соединение, т.к. оно скорее всего уже не активное
                        wsApiClient.closeConnection();
                    }

                    // Соединения нет. Останавливаемся - при восстановлении соединения очередь запустится снова.
                    yield putResolve(setCycleEnabledValue(false));

                    logger.error(LoggerSectionsEnum.SLIDES_WORK_DATA_SAVE_QUEUE, 'Queue disabled due connection error');

                    // noinspection UnnecessaryLabelOnBreakStatementJS
                    break mainCycleLoop;
                }

                if (sendResult.response.status === WsResponseStatusEnum.VALIDATION_ERROR) {
                    // Проблема сама не устранится. Удаляем останавливаемся и вводим приложение в ошибку.
                    // Нет сети. Останавливаемся.
                    yield putResolve(setCycleEnabledValue(false));

                    logger.error(LoggerSectionsEnum.SLIDES_WORK_DATA_SAVE_QUEUE, 'Queue disabled due fatal validation error');

                    yield put(setInFatalErrorState(true));

                    // noinspection UnnecessaryLabelOnBreakStatementJS
                    break mainCycleLoop;
                }

                // Какая-то иная ошибка. Попробуем ещё разок позднее.
                yield delay(2000);

                // noinspection UnnecessaryLabelOnContinueStatementJS
                continue mainCycleLoop;
            }

            // Если пачка отправилась успешно

            // Удалим принятые элементы из очереди
            if (sendResult.response.result.list.length > 0) {
                yield putResolve(deleteItems(sendResult.response.result.list));
            }

            // Пишем в лог о необработанных элементах очереди
            if (itemsForSend.length !== sendResult.response.result.list.length) {
                const rejectedItems = itemsForSend
                    .filter(item => !sendResult.response.result.list.includes(item.itemNumber));

                if (rejectedItems.length > 0) {
                    logger.error(
                        LoggerSectionsEnum.SLIDES_WORK_DATA_SAVE_QUEUE,
                        'Found rejected items: ',
                        rejectedItems
                    );
                }

                // Удаляем элементы из очереди и останавливаем очередь
                yield putResolve(deleteItems(rejectedItems.map(item => item.itemNumber)));

                yield putResolve(setCycleEnabledValue(false));

                logger.error(LoggerSectionsEnum.SLIDES_WORK_DATA_SAVE_QUEUE, 'Queue disabled due fatal error');

                yield put(setInFatalErrorState(true));

                // noinspection UnnecessaryLabelOnBreakStatementJS
                break mainCycleLoop;
            }

            // Ожидаем секунду перед следующим запуском
            yield delay(1000);
        }

    logger.info(LoggerSectionsEnum.SLIDES_WORK_DATA_SAVE_QUEUE, 'Send queue stopped');
    yield putResolve(setCycleStateValue(QueueSendCycleStateEnum.STOPPED));
}
