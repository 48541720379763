import React, {useCallback, useMemo, useState} from "react";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import {ITheme} from "../../../services/theme/ITheme";
import useMediaQuery from "../../../services/hooks/useMediaQuery";
import {PageTitle} from "../../styles/global-elements";
import {Trans} from "@lingui/macro";
import {RightNavigationArea} from "../PageNavigation/RightNavigationArea";
import {PageBackLink} from "../Ui/Elements/PageBackLink";
import {HorizontalNavigation} from "../PageNavigation/HorizontalNavigation";
import {HorizontalNavigationItemType} from "../PageNavigation/HorizontalNavigation/HorizontalNavigationItemType";
import {ViewModeEnum} from "../PageNavigation/HorizontalNavigation/ViewModeEnum";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    @media (${({theme}) => theme.media.large}) {
        flex-direction: row;
        justify-content: space-between;
    }
`;

const ContentWrapper = styled.div`
    flex-grow: 1;
    max-width: 680px;
    padding-bottom: 100px;

    @media (${({theme}) => theme.media.large}) {
        margin-right: 20px;
        padding-bottom: 40px;
    }
`;

interface BackLink {
    name: string;
    link: string;
}

interface ItemType extends HorizontalNavigationItemType
{
    body: React.ReactNode;
}

interface PageWithNavigationProps {
    title: string;
    activeSection: string;
    items: ItemType[];
    backLink?: BackLink;
}

export const PageWithNavigation: React.FC<PageWithNavigationProps> = (
    {
        title,
        items,
        backLink
    }
) => {
    const [activeSection, setActiveSection] = useState<string>(() => items[0].id)

    const currentTheme = useSelector<ApplicationState>(
        ({layout}: ApplicationState) => layout.activeTheme
    ) as ITheme;

    const isLarge = useMediaQuery(`(${currentTheme.media.large})`);

    const activeSectionIndex = useMemo<number | null>(() => {
        const index = items.findIndex(item => item.id === activeSection);

        return (index < 0) ? null : index;
    }, [activeSection, items]);

    const onSelectSectionCb = useCallback((itemId: string) => {
        setActiveSection(itemId)
    }, []);

    const content = useMemo(() => {
        if (activeSectionIndex === null) {
            return;
        }

        return <Wrapper>
            {
                (!isLarge)
                && <HorizontalNavigation
                    items={items}
                    activeItemId={activeSection}
                    onItemChange={onSelectSectionCb}
                    viewMode={ViewModeEnum.BOOKMARKS}
                />
            }

            <ContentWrapper>{items[activeSectionIndex].body}</ContentWrapper>

            {
                (isLarge)
                && <RightNavigationArea
                    items={items}
                    activeItemId={activeSection}
                    onItemChange={onSelectSectionCb}
                />
            }
        </Wrapper>
    }, [activeSection, activeSectionIndex, isLarge, items, onSelectSectionCb]);

    return <>
        {
            (backLink) && <PageBackLink text={backLink.name} link={backLink.link}/>
        }
        <PageTitle><Trans>{title}</Trans></PageTitle>
        {content}
    </>
}
