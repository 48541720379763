import {instanceCachingFactory} from 'tsyringe';
import {configureStore} from "../store";
import {IStoreService} from "../services/store/IStoreService";
import {StoreService} from "../services/store/StoreService";

export default instanceCachingFactory<IStoreService>(() => {
    const {persistor, store} = configureStore();

    return new StoreService(
        persistor,
        store
    );
});
