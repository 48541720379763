import {instanceCachingFactory} from 'tsyringe';
import {IDeviceDetector} from "../components/DeviceDetector/IDeviceDetector";
import {DeviceDetector} from "../components/DeviceDetector/DeviceDetector";
import {IDateHelperService} from "../services/date-helper/IDateHelperService";
import {DiTokens} from "./DiTokens";
import {ILogger} from "../components/Logger/ILogger";

export default instanceCachingFactory<IDeviceDetector>((container) => {
    return new DeviceDetector(
        container.resolve<ILogger>(DiTokens.LOGGER),
        container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE)
    );
});
