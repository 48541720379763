import React, {useCallback, useMemo, useRef, useState} from "react";
import {BtnStyleEnum, Button} from "../../Button";
import {t, Trans} from "@lingui/macro";
import {Modal} from "../../Modal";
import {PageTitle} from "../../../../../styles/global-elements";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../../../store/app/selector";
import {PageBackLink} from "../../PageBackLink";
import {TmOrganizationAndDisciplineSection} from "../../../../TeachingMaterialsCatalog/organization-and-discipline";
import {TmLevelDetailsSection} from "../../../../TeachingMaterialsCatalog/level-details";
import {DtoTmSection} from "../../../../../../components/HttpApiClient/ApiDto/Response/TmSection/DtoTmSection";
import {PopupActions} from "reactjs-popup/dist/types";

enum ModalShowMode {
    ORGANIZATIONS_AND_DISCIPLINE,
    LEVEL,
    SECTION
}

interface SubjectAreaSelectModalWindowProps {
    trigger: React.ReactElement;
    onChange: (value: DtoTmSection) => void;
}

export const TmSectionSelectModalWindow: React.FC<SubjectAreaSelectModalWindowProps> = (
    {
        trigger,
        onChange
    }
) => {
    const apiToken = useSelector(sessionTokenSelector);

    const modalRef = useRef<PopupActions>();

    const [modalShowMode, setModalShowMode] = useState<ModalShowMode>(ModalShowMode.ORGANIZATIONS_AND_DISCIPLINE);
    const [selectedLevelId, setSelectedLevelId] = useState<string | null>(null);

    const selectSection = useCallback((dtoSectionId: DtoTmSection) => {
        onChange(dtoSectionId);

        modalRef.current?.close();
    }, [onChange]);
    
    const goToLevel = (levelId: string) => {
        setModalShowMode(ModalShowMode.LEVEL);
        setSelectedLevelId(levelId);
    }
    
    const backBtn = useMemo(() => {
        switch (modalShowMode) {
            case ModalShowMode.ORGANIZATIONS_AND_DISCIPLINE: {
                return;
            }
            case ModalShowMode.LEVEL: {
                return <PageBackLink onClick={() => setModalShowMode(ModalShowMode.ORGANIZATIONS_AND_DISCIPLINE)} text={t`К уровням и дисциплинам`}/>
            }
            case ModalShowMode.SECTION: {
                return <PageBackLink
                    onClick={() => {
                        if (selectedLevelId !== null) {
                            goToLevel(selectedLevelId);
                        }
                    }}
                    text={t`К уровням`}/>
            }
        }
    }, [modalShowMode, selectedLevelId]);

    const body = useMemo(() => {
        if (apiToken === null) {
            return;
        }
        
        switch (modalShowMode) {
            case ModalShowMode.ORGANIZATIONS_AND_DISCIPLINE: {
                return <TmOrganizationAndDisciplineSection
                    apiToken={apiToken}
                    selectMode={true}
                    usedDisciplineIds={[]}
                    usedLevelIds={[]}
                    navigateToLevelMethod={goToLevel}/>;
            }
            case ModalShowMode.LEVEL: {
                if (selectedLevelId !== null) {
                    return <TmLevelDetailsSection apiToken={apiToken}
                                                  levelId={selectedLevelId}
                                                  selectMode={true}
                                                  usedSectionIds={[]}
                                                  onSelectSection={selectSection}
                    />;
                }

                return;
            }
        }
    }, [apiToken, modalShowMode, selectedLevelId, selectSection]);

    return <Modal trigger={trigger}
                  innerRef={modalRef}
                  // onOpen={onOpenWindow}
                  // onClose={onCloseWindow}
                  closeAllowed={true}
                  footer={(controls) => {
                      return <div>
                          <Button btnStyle={BtnStyleEnum.Secondary}
                                  onClick={() => controls.closeModal()}><Trans>Закрыть</Trans></Button>
                      </div>
                  }}
                  children={(controls) => {
                      return <div>
                          <PageTitle><Trans>Выбор раздела с материалами для трека</Trans></PageTitle>
                          <div>
                              {backBtn}
                              {body}
                          </div>
                      </div>
                  }}
    />
}