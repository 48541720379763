import React, {forwardRef, useEffect, useMemo, useRef, useState} from 'react';
import {ItemRefMethodsInterface} from "../../components/ItemPropsInterface";
import styled from "styled-components";
import {VoiceoverUploadComponent} from "./VoiceoverUploadComponent";
import {IVoiceoverElementContext, VoiceoverElementContextProvider} from "./VoiceoverElementContext";
import {
    AUDIO_ELEMENT_HEIGHT,
    ElementStateEnum,
    UploadStateList
} from "../../../SlidePlayerEditorCommonParts/components/Audio/Common";
import {FileUploadProcessState, UploadProcessDetails} from "../../../../../store/uploadQueue/type";
import {useSelector} from "react-redux";
import {queueStateSelector} from "../../../../../store/uploadQueue/selector";
import {PresenterComponent} from "../../../SlidePlayerEditorCommonParts/components/Audio/PresenterComponent";

const AudioElementWrapper = styled.div`
    width: 100%;
`;

interface VoiceoverProps {
    fileId: string | null;
    onChange: (fileId: string | null) => void;
}

interface VoiceoverRefMethods extends ItemRefMethodsInterface {
}

export const Voiceover = forwardRef<VoiceoverRefMethods, VoiceoverProps>(
        (props, ref) => {

            const {fileId, onChange} = props;
            const queueState = useSelector(queueStateSelector);

            const [elementState, setElementState] = useState<ElementStateEnum>(ElementStateEnum.WAIT_INIT);
            const elementWrapperRef = useRef<HTMLDivElement>(null);

            const currentFileInUploadQueue = useMemo<UploadProcessDetails | null>(() => {
                if (fileId === null) {
                    return null
                }

                const itemIndex = queueState.indexByFileId[fileId];

                if (itemIndex === undefined) {
                    return null;
                }

                return queueState.process[itemIndex] ?? null;
            }, [queueState, fileId]);

            const elementProviderData = useMemo<IVoiceoverElementContext>(() => {
                return {
                    fileId: fileId,
                    elementState: elementState,
                    setElementState: setElementState,
                    onChangeFileId: onChange,
                    currentFileInUploadQueue: currentFileInUploadQueue
                }
            }, [currentFileInUploadQueue, fileId, elementState, onChange]);

            useEffect(() => {
                // Обновление статуса компонента
                if (!currentFileInUploadQueue) {
                    if (fileId !== null) {
                        setElementState(ElementStateEnum.UPLOAD_SUCCESSFULLY);
                    } else {
                        setElementState(ElementStateEnum.NO_AUDIO);
                    }

                    return;
                }

                if (elementState === ElementStateEnum.NO_AUDIO) {
                    return;
                }

                switch (currentFileInUploadQueue.state) {
                    case FileUploadProcessState.WAIT_FOR_START:
                    case FileUploadProcessState.IN_PROCESS: {
                        if (elementState !== ElementStateEnum.UPLOADING_NOW) {
                            setElementState(ElementStateEnum.UPLOADING_NOW);
                        }

                        break;
                    }
                    case FileUploadProcessState.CANCELLED: {
                        setElementState(ElementStateEnum.NO_AUDIO);
                        // Данные файла и preview обнули UploadComponent

                        break;
                    }
                    case FileUploadProcessState.FAILED: {
                        if (elementState !== ElementStateEnum.UPLOAD_ERROR) {
                            setElementState(ElementStateEnum.UPLOAD_ERROR);
                        }

                        break;
                    }
                    case FileUploadProcessState.SUCCESS: {
                        setElementState(ElementStateEnum.UPLOAD_SUCCESSFULLY);
                    }
                }

                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [currentFileInUploadQueue]);

            useEffect(() => {
                // Если аудио было, но сейчас ID файла обнулился
                if (
                    (fileId === null)
                    && (elementState !== ElementStateEnum.NO_AUDIO)
                    && (elementState !== ElementStateEnum.WAIT_INIT)
                ) {
                    setElementState(ElementStateEnum.NO_AUDIO);
                }
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [fileId]);

            return <AudioElementWrapper style={{height: AUDIO_ELEMENT_HEIGHT.toString(10) + 'px'}}
                                        ref={elementWrapperRef}>
                <VoiceoverElementContextProvider value={elementProviderData}>
                    {
                        (UploadStateList.includes(elementState))
                            ? <VoiceoverUploadComponent/>
                            : <PresenterComponent
                                noVolume={true}
                                elementData={{audioId: fileId}}
                                inConstructor={true}
                            />
                    }
                </VoiceoverElementContextProvider>
            </AudioElementWrapper>;
        }
    )
;