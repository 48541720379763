import {action} from "typesafe-actions";

import {
    ActionParamExerciseUserAdmitDefeat,
    ActionParamExerciseUserAnswer,
    ActionParamExerciseUserAnswerByIndexes,
    ActionParamExerciseUserValue,
    ActionParamExerciseValueByIndexes,
    ActionParamItemOverriddenParams,
    ActionParamSetSlideTotalValues,
    ActionParamWithSlideIdAndValue,
    LoadSlideWorkDataActionParams,
    SlideItemWorkData,
    SlideWorkDataActionTypes,
    SlideWorkDataLoadingStateEnum
} from "./type";
import {
    DtoStudentHomeworkDataItem
} from "../../components/WsApiClient/ApiDto/Response/Homework/DtoStudentHomeworkDataItem";
import {
    DtoStudentLessonWorkDataItem
} from "../../components/WsApiClient/ApiDto/Response/Lesson/DtoStudentLessonWorkDataItem";
import {
    DtoSEStudentLessonWorkDataItem
} from "../../components/WsApiClient/ApiDto/Response/Lesson/DtoSEStudentLessonWorkDataItem";
import {ResponseActionCreatorPayload} from "../../components/WsApiClient/ResponseActionCreatorPayload";
import {ResponseBaseDto} from "../../components/WsApiClient/ApiDto/Response/ResponseBaseDto";
import {
    DtoSEStudentSlideWorkDataUpdate
} from "../../components/WsApiClient/ApiDto/Response/Lesson/DtoSEStudentSlideWorkDataUpdate";
import {EditorItemDataParams} from "../../views/components/SlidePlayerEditorCommonParts/EditorData";

export const loadSlideWorkData = (params: LoadSlideWorkDataActionParams) => {
    return action(SlideWorkDataActionTypes.LOAD_SLIDES_WORK_DATA, params);
}

export const setSlideWorkDataEmptyObject = (params: ActionParamWithSlideIdAndValue<null>) => {
    return action(SlideWorkDataActionTypes.SET_SLIDE_WORK_DATA_EMPTY_OBJECT, params);
}

export const setSlideWorkDataAwardValues = (params: ActionParamWithSlideIdAndValue<{ totalAward: number, totalMissedAward: number }>) => {
    return action(SlideWorkDataActionTypes.SET_SLIDE_WORK_DATA_AWARD_VALUES, params);
}

export const setSlideExerciseWorkDataEmptyObject = (params: ActionParamExerciseUserValue<null>) => {
    return action(SlideWorkDataActionTypes.SET_SLIDE_EXERCISE_WORK_DATA_EMPTY_OBJECT, params);
}

export const handleUserEventValueChange = (params: ActionParamExerciseUserValue<string>) => {
    return action(SlideWorkDataActionTypes.HANDLE_USER_EVENT_VALUE_CHANGE, params);
}

export const handleUserEventAddAnswer = (params: ActionParamExerciseUserAnswer) => {
    return action(SlideWorkDataActionTypes.HANDLE_USER_EVENT_ADD_ANSWER, params);
}

export const handleUserEventAdmitDefeat = (params: ActionParamExerciseUserAdmitDefeat) => {
    return action(SlideWorkDataActionTypes.HANDLE_USER_EVENT_ADMIT_DEFEAT, params);
}

export const handleUserEventChangeAdditionalData = (params: ActionParamExerciseUserValue<string | null>) => {
    return action(SlideWorkDataActionTypes.HANDLE_USER_EVENT_CHANGE_ADDITIONAL_DATA, params);
}

export const handleUserEventSetOverriddenParams = (params: ActionParamItemOverriddenParams) => {
    return action(SlideWorkDataActionTypes.HANDLE_USER_EVENT_SET_OVERRIDDEN_PARAMS, params);
}

export const handleSEStudentLessonWorkDataSummaryItem = (data: ResponseActionCreatorPayload<ResponseBaseDto<DtoSEStudentLessonWorkDataItem>, string>) => {
    return action(SlideWorkDataActionTypes.HANDLE_SE_STUDENT_LESSON_WORK_DATA_SUMMARY_ITEM, data);
}

export const handleSEStudentLessonSlideWorkDataUpdated = (data: ResponseActionCreatorPayload<ResponseBaseDto<DtoSEStudentSlideWorkDataUpdate>, string>) => {
    return action(SlideWorkDataActionTypes.HANDLE_SE_STUDENT_LESSON_SLIDE_WORK_DATA_UPDATED, data);
}

export const processStudentLessonWorkDataItemsList = (items: { lessonId: string, items: DtoStudentLessonWorkDataItem[] }) => {
    return action(SlideWorkDataActionTypes.PROCESS_STUDENT_LESSON_WORK_DATA_ITEMS_LIST, items);
}

export const processStudentHomeworkDataItemsList = (items: { lessonId: string, items: DtoStudentHomeworkDataItem[] }) => {
    return action(SlideWorkDataActionTypes.PROCESS_STUDENT_HOMEWORK_DATA_ITEMS_LIST, items);
}

export const setExerciseValue = (params: ActionParamExerciseValueByIndexes<string>) => {
    return action(SlideWorkDataActionTypes.SET_EXERCISE_VALUE, params);
}

export const setExerciseAnswer = (params: ActionParamExerciseUserAnswerByIndexes) => {
    return action(SlideWorkDataActionTypes.SET_EXERCISE_ANSWER, params);
}

export const setExerciseAdditionalData = (params: ActionParamExerciseValueByIndexes<string>) => {
    return action(SlideWorkDataActionTypes.SET_SLIDE_ITEM_ADDITIONAL_DATA, params);
}

export const setSlideItemOverriddenParams = (params: ActionParamExerciseValueByIndexes<Partial<EditorItemDataParams>>) => {
    return action(SlideWorkDataActionTypes.SET_SLIDE_ITEM_OVERRIDDEN_PARAMS, params);
}

export const setSlideWorkDataLoadingState = (params: ActionParamWithSlideIdAndValue<SlideWorkDataLoadingStateEnum>) => {
    return action(SlideWorkDataActionTypes.SET_SLIDE_WORK_DATA_LOADING_STATE, params);
}

export const setSlideWorkDataItemsList = (params: ActionParamWithSlideIdAndValue<SlideItemWorkData[]>) => {
    return action(SlideWorkDataActionTypes.SET_SLIDE_WORK_DATA_ITEMS_LIST, params);
}

export const setSlideWorkDataItemsListItemUpdate = (params: ActionParamWithSlideIdAndValue<SlideItemWorkData>) => {
    return action(SlideWorkDataActionTypes.SET_SLIDE_WORK_DATA_ITEMS_LIST_ITEM_UPDATE, params);
}

export const setSlideTotalValues = (params: ActionParamWithSlideIdAndValue<ActionParamSetSlideTotalValues>) => {
    return action(SlideWorkDataActionTypes.SET_SLIDE_TOTAL_VALUES, params);
}

export const resetAllWorkDataState = () => {
    return action(SlideWorkDataActionTypes.RESET_ALL_WORK_DATA_STATE);
}
