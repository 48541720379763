import * as React from "react";
import {Dispatch} from "redux";
import {LessonRoomStateTypes, RoomConnectionParams} from "../../../../../store/lessonRoom/type";
import {
    clearRoomState,
    handleLeaveFromRoomViaRouter,
    loadRoomMembersList,
    setLessonRoomId,
    setLessonRoomState,
    setRoomConnectionParams
} from "../../../../../store/lessonRoom/actions";
import {setCameraRequestAccessNow} from "../../../../../store/app/actions";
import {resetLessonMaterialsState} from "../../../../../store/lessonMaterials/actions";
import {ApplicationState} from "../../../../../store";
import {connect, ConnectedProps} from "react-redux";
import {HorizontalNavigation} from "../../../../components/PageNavigation/HorizontalNavigation";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {t} from "@lingui/macro";
import {LessonPageContext} from "../LessonPageContext";
import {StudentsInLessonModePage} from "./StudentsInLessonMode/StudentsInLessonModePage";
import {LessonMaterialsModePage} from "./LessonMaterialsMode/LessonMaterialsModePage";
import LessonWorkResultsMode from "./LessonResultsMode/index";
import {IFeatureToggle} from "../../../../../components/FeatureToggle/IFeatureToggle";
import {ViewModeEnum} from "../../../../components/PageNavigation/HorizontalNavigation/ViewModeEnum";

enum SubpageModeEnum {
    LESSON_MATERIALS = 'lesson_materials',
    LESSON_WORK_RESULTS = 'lesson_work_results',
    RESULTS = 'results'
}

interface OnLessonModePageProps extends PropsFromRedux {

}

interface OnLessonModePageState {
    activeSubpageMode: SubpageModeEnum;
}

class OnLessonModePage extends React.Component<OnLessonModePageProps, OnLessonModePageState> {
    protected logger: ILogger;
    protected featureToggle: IFeatureToggle;

    static contextType = LessonPageContext;
    context!: React.ContextType<typeof LessonPageContext>;

    constructor(props: Readonly<OnLessonModePageProps> | OnLessonModePageProps) {
        super(props);

        this.logger = container.resolve<ILogger>(DiTokens.LOGGER);
        this.featureToggle = container.resolve<IFeatureToggle>(DiTokens.FEATURE_TOGGLE);

        this.state = {
            activeSubpageMode: SubpageModeEnum.LESSON_MATERIALS
        }
    }

    protected activeModeOnChange = (newValue: string) => {
        this.setState(() => {
            return {
                activeSubpageMode: newValue as SubpageModeEnum
            }
        });
    }

    protected content = () => {
        return <>
            {/*LESSON_MATERIALS_MODE*/}
            <div
                style={{display: (this.state.activeSubpageMode === SubpageModeEnum.LESSON_MATERIALS) ? "block" : "none"}}
                key={"lesson_materials_mode"}>
                {
                    ((this.context.lessonIntId !== null) && (this.context.groupIntId !== null))
                    && <LessonMaterialsModePage
                        ref={this.context.lessonMaterialsPageRef}
                        refToLessonHomeworkMaterialsPage={this.context.lessonHomeworkMaterialsPageRef}
                        refToLessonMaterialsPage={null}
                        materialsDtoList={this.context.lessonMaterialsList}
                        apiToken={this.props.apiToken}/>
                }
            </div>

            {/*LESSON_WORK_RESULTS*/}
            <div
                style={{display: (this.state.activeSubpageMode === SubpageModeEnum.LESSON_WORK_RESULTS) ? "block" : "none"}}
                key={"lesson_work_results"}>
                <LessonWorkResultsMode/>
            </div>

            {/*STUDENT_IN_LESSON*/}
            <div style={{display: (this.state.activeSubpageMode === SubpageModeEnum.RESULTS) ? "block" : "none"}}
                 key={"student_in_lesson_mode"}>
                <LessonPageContext.Consumer>
                    {
                        (lessonPageContext) => <StudentsInLessonModePage
                            lessonPageContext={lessonPageContext}
                            stToken={this.props.stToken}
                        />
                    }
                </LessonPageContext.Consumer>
            </div>
        </>
    }

    render() {
        return <div>
            <HorizontalNavigation onItemChange={this.activeModeOnChange}
                                  activeItemId={this.state.activeSubpageMode}
                                  viewMode={ViewModeEnum.BOOKMARKS}
                                  items={[
                                      {
                                          id: SubpageModeEnum.LESSON_MATERIALS,
                                          name: t`Упражнения`
                                      },
                                      {
                                          id: SubpageModeEnum.LESSON_WORK_RESULTS,
                                          name: t`Выполнение упражнений`
                                      },
                                      {
                                          id: SubpageModeEnum.RESULTS,
                                          name: t`Оценка работы`
                                      }
                                  ]
                                  }/>
            {this.content()}
        </div>
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setRoomConnectionParams: (connectionParams: RoomConnectionParams | null) =>
        dispatch(setRoomConnectionParams(connectionParams)),
    setRoomConnectionState: (state: LessonRoomStateTypes) =>
        dispatch(setLessonRoomState(state)),
    setCurrentRoomId: (roomId: string | null) =>
        dispatch(setLessonRoomId(roomId)),
    setCameraRequestAccessNow: (value: boolean) =>
        dispatch(setCameraRequestAccessNow(value)),
    loadLessonRoomMembers: (roomId: string) =>
        dispatch(loadRoomMembersList(roomId)),
    clearActiveRoomState: () =>
        dispatch(clearRoomState()),
    leaveFromRoomViaRouter: () =>
        dispatch(handleLeaveFromRoomViaRouter()),
    resetLessonMaterialsState: () =>
        dispatch(resetLessonMaterialsState())
});

const mapStateToProps = ({user, app, vcsState, layout}: ApplicationState) => ({
    userId: (user.profileData?.id) ?? null,
    stToken: user.stToken,
    apiToken: user.sessionToken,
    currentStUserId: (user.profileData) ? user.profileData.schools[0].accounts[0].stId : null,
    accessToCameraAllowedInSession: app.cameraAccess.allowedInSession,
    accessToCameraRequestingNow: app.cameraAccess.requestAccessNow,
    videoRoomState: vcsState,
    currentTheme: layout.activeTheme,
});

const connector = connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(OnLessonModePage);
