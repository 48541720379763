import {DefaultTheme} from "styled-components";
import {ThemeEnum} from "./ThemeEnum";
import {BaseTheme} from "./BaseTheme";

export const LightTheme: DefaultTheme = {
    type: ThemeEnum.light,

    colors: {
        ...BaseTheme.colors,
    },

    slideContent: {
        ...BaseTheme.slideContent
    },

    otherColors: {
        ...BaseTheme.otherColors,
    },

    filters: {
        ...BaseTheme.filters,
    },

    media: {
        ...BaseTheme.media,
    },

    opacity: {
        ...BaseTheme.opacity
    },

    size: {
        ...BaseTheme.size
    },

    zIndices: {
        ...BaseTheme.zIndices
    }
}