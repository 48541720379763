import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {DefaultLoader} from "../../../../../components/DefaultLoader";
import {
    DtoTmOrganization
} from "../../../../../../components/HttpApiClient/ApiDto/Response/TmOrganizations/DtoTmOrganization";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../../../store/app/selector";
import {LoggerSectionsEnum} from "../../../../../../components/Logger/LoggerSectionsEnum";
import {ILogger} from "../../../../../../components/Logger/ILogger";
import {ErrorLoadingContent} from "../../../../../components/Ui/Elements/ErrorLoadingContent";
import {t} from "@lingui/macro";
import {
    DtoTmDiscipline
} from "../../../../../../components/HttpApiClient/ApiDto/Response/TmDisciplines/DtoTmDiscipline";
import {CreateNewDisciplineItem} from "./CreateNewDisciplineItem";
import {LoadMoreDisciplineItem} from "./LoadMoreDisciplineItem";
import {DisciplineItem} from "./DisciplineItem";

const DisciplinesListWrapper = styled.div`
`;

const DisciplineList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`;

interface DisciplinesListProps {
    organizationId: string;
    disciplines: DtoTmDiscipline[] | null;
    setDisciplines: React.Dispatch<React.SetStateAction<DtoTmDiscipline[] | null>>;
    selectedDisciplineId: string | null;
    setSelectedDisciplineId: (value: string | null) => void;
}

export const DisciplinesList: React.FC<DisciplinesListProps> = (props) => {
    const [nowLoading, setNowLoading] = useState<boolean>(false);
    const [loadingError, setLoadingError] = useState<boolean>(false);

    const [paginationPagesLoaded, setPaginationPagesLoaded] = useState<number>(0);
    const [totalDisciplinesItems, setTotalDisciplinesItems] = useState<number>(0);

    const sessionToken = useSelector(sessionTokenSelector);
    const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
    const logger = container.resolve<ILogger>(DiTokens.LOGGER);

    const {organizationId, disciplines, setDisciplines} = {...props};

    const loadContent = useCallback((page?: number) => {
        if (!sessionToken) {
            return;
        }

        setLoadingError(false);
        setNowLoading(true);

        if (page === undefined) {
            page = 1;
        }

        httpApiClient.tmGetDisciplines(sessionToken, organizationId, page, 20)
            .then((data) => {
                setDisciplines((state) => {
                    if (state === null) {
                        return data.data.list;
                    }

                    return [
                        ...state,
                        ...data.data.list
                    ]
                });

                setPaginationPagesLoaded(data.data.page);
                setTotalDisciplinesItems(data.data.totalCount);
            })
            .catch((err) => {
                logger.error(LoggerSectionsEnum.TM_DISCIPLINES_API, 'Error on loading disciplines list: ', err);

                setLoadingError(true);
            })
            .finally(() => {
                setNowLoading(false);
            })
    }, [sessionToken, httpApiClient, organizationId, setDisciplines, logger]);

    // Сброс дисциплин при изменении выбранной организации
    useEffect(() => {
        setDisciplines(() => null);

    }, [organizationId, setDisciplines]);

    // Загрузка дисциплин при сбросе списка дисциплин
    useEffect(() => {
        if (disciplines === null) {
            loadContent(1);
        }
    }, [disciplines, loadContent]);


    const disciplineOnClick = (item: DtoTmOrganization) => {
        props.setSelectedDisciplineId(item.id);
    }

    const organizationList = () => {
        if (nowLoading) {
            return <DefaultLoader/>;
        }

        let listItems: null | JSX.Element[] = null;

        if (props.disciplines !== null) {
            listItems = props.disciplines.map((item) => {
                return <DisciplineItem active={item.id === props.selectedDisciplineId}
                                       key={item.id}
                                       item={item}
                                       onClick={() => disciplineOnClick(item)}/>
            });
        }

        return (<DisciplineList>
            {listItems}
            {((props.disciplines !== null) && (totalDisciplinesItems > props.disciplines.length)) ?
                <LoadMoreDisciplineItem onClick={() => loadContent(paginationPagesLoaded + 1)}/> : null}
            <CreateNewDisciplineItem organizationId={props.organizationId}
                                     onItemCreated={() => props.setDisciplines(null)}/>
        </DisciplineList>)
    }

    return <DisciplinesListWrapper>
        {
            (loadingError)
                ? <ErrorLoadingContent
                    title={t`Не удалось загрузить список дисциплин`}
                    retryBtnClick={() => loadContent()}
                />
                : organizationList()
        }
    </DisciplinesListWrapper>
}