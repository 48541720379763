import React from "react";
import styled from "styled-components";
import {PageSubtitle2} from "../../../../../styles/global-elements";
import {Trans} from "@lingui/macro";
import {NewPasswordSection} from "./NewPasswordSection";

const Section = styled.section``;

const SectionBody = styled.div``;

export const SecuritySection: React.FC = () => {
    return <div>
        <Section>
            <PageSubtitle2><Trans>Изменить пароль</Trans></PageSubtitle2>
            <SectionBody>
                <NewPasswordSection/>
            </SectionBody>
        </Section>
    </div>;
}