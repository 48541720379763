import React from 'react';
import {PageTitle} from "../../../../styles/global-elements";
import {Trans} from "@lingui/macro";
import styled from "styled-components";
import {NavigationProps, PlayerNavigation as PlayerNavigationOriginal} from "./navigation/index";
import {PlayerContentRefMethods, PlayerContentSide as PlayerContentOriginal} from "./content-side/index";
import {connect, ConnectedProps} from "react-redux";
import {container} from "tsyringe";
import {IHttpApiClient} from "../../../../../components/HttpApiClient/IHttpApiClient";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";
import {DefaultLoader} from "../../../../components/DefaultLoader";
import {ErrorLoadingContent} from "../../../../components/Ui/Elements/ErrorLoadingContent";
import {PlayerSlideModel} from "../../../../components/SlidePlayer/PlayerSlideModel";
import {SlidesLoader} from "./SlidesLoader";
import {PlayerContextProvider, SlidesCachedContent} from "../../../../components/SlidePlayer/PlayerContext";
import {ApplicationState} from "../../../../../store";
import {withRouter, WithRouterProps} from "../../../../components/WithRouterHoc";
import {debounce, DebouncedFunc} from "lodash";
import {EditorData} from "../../../../components/SlidePlayerEditorCommonParts/EditorData";
import {RoutesHelper} from "../../../../../helpers/RoutesHelper";
import {SlidePlayerIdEnum} from "../../../../../enums/SlidePlayerIdEnum";

const PlayerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
    //min-height: calc(100% - ${({theme}) => theme.size.headerHeight}px);
  //overflow: hidden;
`;

const PlayerLargeNavigation = styled(PlayerNavigationOriginal)<NavigationProps>`
  max-width: 344px;
  min-width: 344px;
`;

const PlayerSmallNavigation = styled(PlayerNavigationOriginal)<NavigationProps>`
`;

const PlayerContent = styled(PlayerContentOriginal)`
  flex-grow: 1;
  max-width: 680px;
  padding-bottom: 100px;

  @media (${({theme}) => theme.media.large}) {
    margin-right: 20px;
    padding-bottom: 40px;
  }
`;

enum LoadingState {
    NOW_LOADING,
    SUCCESS,
    ERROR
}

interface RouterParams {
    lessonId: string;
}

interface LessonPlayerProps extends PropsFromRedux {

}

type LessonPlayerPropsWRouter = WithRouterProps<RouterParams> & LessonPlayerProps;

interface LessonPlayerState {
    lessonId: string | null;
    loadingState: LoadingState;

    windowIsLarge: boolean;

    slides: PlayerSlideModel[] | null;
    selectedSlideId: string | null;

    slidesContent: SlidesCachedContent;
    selectedSlideIndex: number | null;
}

class LessonPlayer extends React.Component<LessonPlayerPropsWRouter, LessonPlayerState> {

    protected httpApiClient: IHttpApiClient;
    protected logger: ILogger;

    protected mediaQueryLarge;

    protected onResizeDebounced: null | DebouncedFunc<() => void>;

    protected rightSideRef: React.RefObject<PlayerContentRefMethods>;

    constructor(props: Readonly<LessonPlayerPropsWRouter> | LessonPlayerPropsWRouter) {
        super(props);

        this.httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        this.logger = container.resolve<ILogger>(DiTokens.LOGGER);

        this.mediaQueryLarge = window.matchMedia(`(${this.props.currentTheme.media.large})`);

        this.state = {
            lessonId: null,
            loadingState: LoadingState.NOW_LOADING,
            windowIsLarge: this.mediaQueryLarge.matches,
            slides: null,
            selectedSlideId: null,
            slidesContent: {},
            selectedSlideIndex: null
        }

        this.onResizeDebounced = debounce(this.onResize, 300);

        this.rightSideRef = React.createRef();
    }

    componentDidMount() {
        if (this.onResizeDebounced) {
            window.addEventListener('resize', this.onResizeDebounced);
        }

        const lessonId = ((this.props.match.params.lessonId) ?? "").trim();

        if (lessonId === '') {
            this.setState(() => {
                return {
                    loadingState: LoadingState.ERROR
                }
            });

            return;
        }

        this.setState(
            () => {
                return {
                    lessonId: lessonId,
                }
            },
            () => {
                this.loadContent()
            }
        );
    }

    componentWillUnmount() {
        if (this.onResizeDebounced) {
            window.removeEventListener('resize', this.onResizeDebounced);

            this.onResizeDebounced.cancel();

            this.onResizeDebounced = null;
        }
    }

    protected onResize = () => {
        const windowIsLarge = this.mediaQueryLarge.matches;

        if (this.state.windowIsLarge !== windowIsLarge) {
            this.setState(() => {
                return {
                    windowIsLarge: windowIsLarge
                }
            });
        }
    }

    protected loadContent = () => {
        if ((!this.props.sessionToken) || (!this.state.lessonId)) {
            this.setState(() => {
                return {
                    loadingState: LoadingState.ERROR
                }
            });

            return;
        }

        this.setState(() => {
            return {
                loadingState: LoadingState.NOW_LOADING
            }
        });

        const loader = new SlidesLoader(this.httpApiClient, this.logger, this.props.sessionToken);

        loader.loadContent(this.state.lessonId)
            .then((data) => {
                this.setState(
                    () => {
                        return {
                            slides: data,
                            loadingState: LoadingState.SUCCESS
                        }
                    },
                    () => {
                        if ((this.state.slides) && (this.state.slides?.length > 0) && (this.state.selectedSlideId === null)) {
                            this.setSelectedSlideId(this.state.slides[0].tmSlideId);
                        }
                    })
            })
            .catch((err) => {
                this.logger.error(LoggerSectionsEnum.TM_SLIDES_API, 'Error on loading slides list: ', err);

                this.setState(
                    () => {
                        return {
                            slides: [],
                            loadingState: LoadingState.ERROR,
                            selectedSlideId: null
                        }
                    }
                );
            });
    }

    protected setSlideContent = (slideId: string, data: EditorData) => {
        const value = {...this.state.slidesContent};

        value[slideId] = data;

        this.setState(() => {
            return {
                slidesContent: value
            }
        })
    }

    protected setSelectedSlideId = (slideId: string | null) => {
        if ((slideId === null) || (this.state.slides === null)) {
            this.setState(() => {
                return {
                    selectedSlideId: null,
                    selectedSlideIndex: null
                }
            });

            return;
        }

        const selectedSlideIndex = this.state.slides.findIndex((item) => item.tmSlideId === slideId);

        if (selectedSlideIndex < 0) {
            this.setState(() => {
                return {
                    selectedSlideId: null,
                    selectedSlideIndex: null
                }
            });

            return;
        }

        this.setState(() => {
            return {
                selectedSlideId: slideId,
                selectedSlideIndex: selectedSlideIndex
            }
        });
    }

    protected content = () => {
        switch (this.state.loadingState) {
            case LoadingState.NOW_LOADING: {
                return <DefaultLoader/>;
            }
            case LoadingState.ERROR: {
                return <ErrorLoadingContent retryBtnClick={this.loadContent}/>;
            }
            default: {
                return <PlayerContextProvider value={{
                    lessonId: this.state.lessonId ?? "",
                    playerId: RoutesHelper.replaceParams(
                        SlidePlayerIdEnum.TEACHER_LESSON_TEST,
                        [
                            {
                                key: 'lessonId',
                                value: this.state.lessonId ?? ""
                            }
                        ]
                    ),
                    slides: this.state.slides,
                    slidesContent: this.state.slidesContent,
                    setSlidesContent: this.setSlideContent,
                    selectedSlideId: this.state.selectedSlideId,
                    setSelectedSlideId: this.setSelectedSlideId,
                    selectedSlide: (this.state.selectedSlideIndex !== null && this.state.slides)
                        ? (this.state.slides[this.state.selectedSlideIndex] ?? null)
                        : null,
                    selectedSlideIndex: this.state.selectedSlideIndex,
                    setInteractivityConfig: null
                }}>
                    <PlayerWrapper>
                        <PlayerContent ref={this.rightSideRef}/>
                        {(this.state.windowIsLarge) && <PlayerLargeNavigation/>}
                    </PlayerWrapper>
                    {(!this.state.windowIsLarge) && <PlayerSmallNavigation/>}
                </PlayerContextProvider>
            }
        }
    }

    render() {
        return <>
            <PageTitle><Trans>Тестирование урока</Trans></PageTitle>
            {this.content()}
        </>;
    }
}

// const mapDispatchToProps = (dispatch: Dispatch) => ({});

const mapStateToProps = ({user, layout}: ApplicationState) => ({
    currentTheme: layout.activeTheme,
    sessionToken: user.sessionToken
});

const connector = connect(mapStateToProps, null, null, {forwardRef: true});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default withRouter(connector(LessonPlayer));
