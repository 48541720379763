import React from "react";
import {ActionsList, ActionsListItem, InlineIconCss} from "./styles";
import {Trans} from "@lingui/macro";
import {RegularText} from "../../styles/global-elements";
import {ReactComponent as IosShareNoBorderIcon} from "../Ui/Svg/IosShareNoBorder.svg";
import {ReactComponent as IosAddToHomeNoBorderIcon} from "../Ui/Svg/IosAddToHomeNoBorder.svg";
import styled from "styled-components";

const IosShareNoBorderIconStyled = styled(IosShareNoBorderIcon)`
    ${InlineIconCss};
`;

const IosAddToHomeNoBorderIconStyled = styled(IosAddToHomeNoBorderIcon)`
    ${InlineIconCss};
`;

export const IphoneManual: React.FC = () => {
    return <>
        <RegularText><Trans>Установите приложение «Мои занятия»:</Trans></RegularText><br/>
        <ActionsList>
            <ActionsListItem>
                <Trans>Нажмите кнопку «<IosShareNoBorderIconStyled/>» в нижней части экрана.</Trans>
            </ActionsListItem>
            <ActionsListItem>
                <Trans>Прокрутите вниз и в списке выберите «На экран Домой <IosAddToHomeNoBorderIconStyled/>».</Trans>
            </ActionsListItem>
            <ActionsListItem>
                <Trans>Нажмите кнопку «Добавить».</Trans>
            </ActionsListItem>
            <ActionsListItem>
                <Trans>В приложении можно включить уведомления на телефон.</Trans>
            </ActionsListItem>
        </ActionsList>
    </>
}