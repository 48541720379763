import styled, {css} from "styled-components";
import {BlockWithBorderCss} from "../../../styles/global-elements";
import React from "react";
import classNames from "classnames";
import {HorizontalNavigationItemType} from "../HorizontalNavigation/HorizontalNavigationItemType";

const Wrapper = styled.div`
    ${BlockWithBorderCss};
    overflow: hidden;
    max-width: 344px;
    min-width: 344px;

    padding: 18px 0;
    margin: 0;
    display: block;
    position: sticky;
    overflow: unset;
    top: ${({theme}) => 100 + theme.size.headerHeight}px;
    height: fit-content;
`;

const ItemsListWrapper = styled.div`
    overflow: auto;
    flex-grow: 1;
    display: block;
    max-height: 100%;
    margin-right: 6px;
`;

const ItemsList = styled.div`
    padding: 0;
    margin: 0;
`;

const ListItem = css`
    display: flex;
    flex-direction: row;
    max-width: 100%;
    min-width: 100%;

    font-size: 16px;
    background: ${({theme}) => theme.colors.backgroundPrimary};
    color: ${({theme}) => theme.colors.textPrimary};

    cursor: pointer;
    border-left: 4px solid rgba(0, 0, 0, 0);
    overflow: hidden;
    opacity: 0.5;
    transition: opacity 0.3s ease;

    &:hover {
        opacity: 1;
    }

    &.selected {
        opacity: 1;
    }

    padding: 6px 32px;

    &.selected {
        border-left-color: ${({theme}) => theme.colors.textPrimary};
    }
`;

const ListItemDiv = styled.div`
    ${ListItem};
`;

interface RightNavigationAreaProps {
    items: HorizontalNavigationItemType[];
    activeItemId: string;
    onItemChange: (newItemId: string) => void;
}

export const RightNavigationArea: React.FC<RightNavigationAreaProps> = (
    {
        activeItemId,
        items,
        onItemChange
    }
) => {
    return <Wrapper>
        <ItemsListWrapper>
            <ItemsList>
                {
                    items.map((item) => {
                        return <ListItemDiv
                            key={item.id}
                            className={classNames(item.id === activeItemId && 'selected')}
                            onClick={() => onItemChange(item.id)}
                        >
                            {item.name}
                        </ListItemDiv>
                    })
                }
            </ItemsList>
        </ItemsListWrapper>
    </Wrapper>
}
