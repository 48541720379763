import {Reducer} from "redux";
import {I18nActionTypes, I18nState} from "./type";
import {initialState} from "./initialState";
import produce from "immer";

const reducer: Reducer<I18nState> = produce((draft: I18nState, action) => {
    switch (action.type) {
        case I18nActionTypes.SET_CURRENT_LOCALE_NAME: {
            draft.currentLocale = action.payload;

            break;
        }
        case I18nActionTypes.SET_LOCALE_STATE: {
            draft.currentState = action.payload;

            break;
        }
    }
}, initialState);

export {reducer as i18nReducer}