import {ServiceWorkerDataState, ServiceWorkerStatusEnum} from "./type";

export const initialState: ServiceWorkerDataState = {
    status: ServiceWorkerStatusEnum.UNKNOWN,
    version: null,
    pushSubscriptionState: null,
    registeredPushTokenHash: null,
    supportedFeatures: {
        pushManager: null
    }
}