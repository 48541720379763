import styled from "styled-components";
import React from "react";
import {BlockWithBorderFlexLink} from "../../../../../../../styles/global-elements";
import Logo from './Logo.png';
import LogoLarge from './LogoLarge.png';
import Qr from './FsQr.png';
import LeftBg from './LeftBg.png';
import RightBg from './RightBg.png';
import {ReactComponent as TextSvgSmall} from "./TextSmall.svg";
import {ReactComponent as TextSvgMedium} from "./TextMedium.svg";
import {ReactComponent as TextSvgLarge} from "./TextLarge.svg";
import {ReactComponent as ChanNameSvg} from "./ChanName.svg";
import useMediaQuery from "../../../../../../../../services/hooks/useMediaQuery";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../../../../../../store";
import {ITheme} from "../../../../../../../../services/theme/ITheme";

const BlockLink = styled(BlockWithBorderFlexLink)`
  overflow: hidden;
  position: relative;
  background-color: white;

  &.active {
    cursor: pointer;
  }
`;

const LogoImageSmall = styled.img`
    width: 85px;
    height: 34px;
`;

const LogoImageMedium = styled.img`
    width: 132px;
    height: 53px;
    
    @media (${({theme}) => theme.media.medium}){
        width: 154px;
        height: 49px;
    }
`;

const QrImage = styled.img`
    width: 140px;
    height: 140px;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: row;
    
    padding: 20px;
    
    @media (min-width: 516px) {
        padding: 24px;
    }
    
   @media (${({theme}) => theme.media.medium}){
        padding: 32px;
    }
    
   @media (${({theme}) => theme.media.large}){
        padding: 32px 165px 32px 191px;
    }
`;

const LeftWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
   
`;

const QrWrapper = styled.div`
    min-width: 140px;
    min-height: 140px;
    max-width: 140px;
    max-height: 140px;
`;

const TextSqgSmallStyled = styled(TextSvgSmall)`
    max-width: 90px;
    
    @media (min-width: 366px) {
        max-width: 110px;
    }
    
    @media (min-width: 516px) {
        display: none;
    }
`;

const TextSvgMediumStyled = styled(TextSvgMedium)`
    display: none;
    
    @media (min-width: 516px) {
        display: block;
        max-width: 200px;
    }
    
    @media (${({theme}) => theme.media.small}) {
        max-width: unset;
        width: 275px;
    }
    
    @media (${({theme}) => theme.media.medium}) {
        display: none;
    }
        
`;

const TextTopLineStyled = styled.div`
    display: flex;
    flex-direction: row;
    
    width: 90%;
    justify-content: space-between;
`;

const TopLineLogoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const TopLineChanNameWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const TextLargeStyled = styled(TextSvgLarge)`
    display: none;
    
    @media (${({theme}) => theme.media.medium}) {
        display: block;
        width: 476px;
    }
`;

const ChanNameSvgStyled = styled(ChanNameSvg)`
    @media (${({theme}) => theme.media.medium}) {
        display: block;
        width: 232px;
        height: auto;
    }
`;

const LeftBgImg = styled.img`
    position: absolute;
    top: 55px;
    left: -25px; 
    transform: rotate(55.79deg);
    height: 111px;
    width: 209px;
`;

const RightBgImg = styled.img`
    position: absolute;
    right: -44px;
    top: 57px;
    transform: rotate(54.45deg);
    height: 52px;
    width: 207px;
`;

export const Index: React.FC = () => {
    const currentTheme = useSelector<ApplicationState>(
        ({layout}: ApplicationState) => layout.activeTheme
    ) as ITheme;

    const isSmall = useMediaQuery(`(${currentTheme.media.small})`);
    const isMedium = useMediaQuery(`(${currentTheme.media.medium})`);
    const isLarge = useMediaQuery(`(${currentTheme.media.large})`);

    return <BlockLink href={"https://t.me/theoldschoolsaratov"} target={"_blank"}>
        {
            (isLarge)
            && <>
                <LeftBgImg src={LeftBg}/>
                <RightBgImg src={RightBg}/>
            </>
        }

        <ContentWrapper>
            {
                (!isSmall) && <LeftWrapper>
                    <LogoImageSmall src={Logo}/>
                    <TextSqgSmallStyled/>
                    <TextSvgMediumStyled/>
                </LeftWrapper>
            }

            {
                (isSmall) && <LeftWrapper>
                    <TextTopLineStyled>
                        <TopLineLogoWrapper>
                            <LogoImageMedium src={(isMedium) ? LogoLarge : Logo}/>
                        </TopLineLogoWrapper>
                        <TopLineChanNameWrapper>
                            <ChanNameSvgStyled/>
                        </TopLineChanNameWrapper>
                    </TextTopLineStyled>

                    <TextSvgMediumStyled/>
                    <TextLargeStyled/>
                </LeftWrapper>
            }

            <QrWrapper>
                <QrImage src={Qr}/>
            </QrWrapper>
        </ContentWrapper>

    </BlockLink>
}
