import React, {useMemo} from "react";
import {
    DtoSelfStudyTrackAdminSubscriberListItem
} from "../../../../../../components/HttpApiClient/ApiDto/Response/SelfStudyTrack/DtoSelfStudyTrackAdminSubscriberListItem";
import styled, {css} from "styled-components";
import {RegularTextCss} from "../../../../../styles/global-elements";
import {t, Trans} from "@lingui/macro";
import {IDateHelperService} from "../../../../../../services/date-helper/IDateHelperService";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {RouterLink} from "../../../../../components/Ui/Elements/Link";
import {RoutesHelper} from "../../../../../../helpers/RoutesHelper";
import {RoutesList} from "../../../../../RoutesList";
import {PageSectionsEnum} from "../../../student/profile-page/PageSectionsEnum";
import Energy32 from "../../../../../components/Ui/Png/Energy32@2x.png";
import {ReactComponent as SmallLogo} from "../../../../../components/Ui/Svg/SmallLogoLightTheme.svg";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid ${({theme}) => theme.colors.headerDivider};
    padding: 10px 10px;
    gap: 5px;

    @media (${({theme}) => theme.media.small}) {
        flex-direction: row;

        &:hover {
            background-color: ${({theme}) => theme.colors.backgroundHover};
        }
    }
`;

const DefaultField = css`
    ${RegularTextCss};

    @media (${({theme}) => theme.media.small}) {
        flex-basis: 33%;
        flex-grow: 1;
        word-break: break-word;
    }
`;

const NameWrapper = styled.div`
    ${DefaultField};

    @media (${({theme}) => theme.media.small}) {
        min-width: 100px;
    }
`;

const SmallLogoStyled = styled(SmallLogo)`
    display: inline;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    margin-left: 5px;
`;

const EmailWrapper = styled.div`
    ${DefaultField};

    @media (${({theme}) => theme.media.small}) {
        min-width: 100px;
    }
`;

const EmailString = styled.span`
    ${RegularTextCss};
    vertical-align: middle;

    &.guest {
        opacity: 0.8;
    }
`;

const LastEpisodeInfoWrapper = styled.div`
    ${DefaultField};

    @media (${({theme}) => theme.media.small}) {
        min-width: 100px;
    }
`;

const EnergyWrapper = styled.div`
    ${RegularTextCss};

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    @media (${({theme}) => theme.media.small}) {
        min-width: 50px;
        justify-content: flex-end;
    }
`;

const EnergyValue = styled.span`
    vertical-align: middle;
`;

const EnergyIcon = styled.img`
    vertical-align: middle;
    display: inline;
`;

const EpisodeOpenedDateWrapper = styled.span`
    ${RegularTextCss};
`;

const NameLink = styled(RouterLink)`
    ${RegularTextCss};
    display: inline;
    vertical-align: middle;

    color: ${({theme}) => theme.colors.textPrimary};
`;

const NotAStudent = styled.div`
    ${RegularTextCss};
    display: inline;
    vertical-align: middle;

    color: ${({theme}) => theme.colors.textSecondary};
`;

export interface ItemProps {
    subscriber: DtoSelfStudyTrackAdminSubscriberListItem;
}

export const Item: React.FC<ItemProps> = ({subscriber}) => {
    const dateHelper = useMemo(() => container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE), []);

    const nameString = useMemo(() => {
        if (subscriber.idInSchool) {
            return <NameLink
                to={
                    RoutesHelper.replaceParams(
                        RoutesList.TEACHER_STUDENT_PROFILE,
                        [
                            {
                                key: 'studentId',
                                value: subscriber.idInSchool.toString(10)
                            },
                            {
                                key: 'sectionId',
                                value: PageSectionsEnum.GENERAL
                            }
                        ]
                    )
                }
                target={"_blank"}
            >
                {subscriber.fioInSchool}
            </NameLink>
        }

        return <NotAStudent><Trans>Не ученик</Trans></NotAStudent>
    }, [subscriber.fioInSchool, subscriber.idInSchool]);

    const emailString = useMemo<React.ReactNode>(() => {
        if (subscriber.isGuestUser) {
            return <EmailString className={'guest'}>
                <Trans>Нет email</Trans>
            </EmailString>;
        }

        return <EmailString className={'guest'}>
            {subscriber.email}
        </EmailString>;
    }, [subscriber.email, subscriber.isGuestUser]);

    const currentEpisodeOpenedString = useMemo<string>(() => {
        return dateHelper.formatAsDate(
            dateHelper.dateFromString(subscriber.currentEpisodeOpenedAtUtc, true)
        );
    }, [dateHelper, subscriber.currentEpisodeOpenedAtUtc]);


    return <Wrapper>
        <NameWrapper>
            {nameString}
            {
                (subscriber.hasMlUtmSource)
                && <SmallLogoStyled title={t`Лид приглашён системой Мои занятия`}/>
            }
        </NameWrapper>
        <EmailWrapper>
            {emailString}
        </EmailWrapper>
        <LastEpisodeInfoWrapper>
            <Trans>ep.{subscriber.currentEpisodeNumber} с <EpisodeOpenedDateWrapper>{currentEpisodeOpenedString}</EpisodeOpenedDateWrapper>
            </Trans>
        </LastEpisodeInfoWrapper>
        <EnergyWrapper>
            <EnergyValue>
                {subscriber.chargeValue}
            </EnergyValue>
            <EnergyIcon
                src={Energy32}
                alt={'Energy icon'}
                width={'20px'}
                height={'20px'}
            />
        </EnergyWrapper>
    </Wrapper>
}