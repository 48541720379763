import {ITheme} from "./ITheme";

export const BaseTheme: ITheme = {
    colors: {
        browserTheme: "#FFFFFF",

        simpleWhite: "#FFFFFF",
        simpleBlack: "#141719",

        textPrimary: "#1F2733",
        textSecondary: "#6D6D6D",
        textTertiary: "#ADADAD",
        textInverse: "#FFFFFF",
        textPrimaryDark: "#000000",
        textAlwaysWhite: "#FFFFFF",

        accentPrimary: "#1979FF",
        accentSecondary: "#F23635",
        accentDivider: "#D2D2D2",

        backgroundPrimary: "#FFFFFF",
        backgroundSecondary: "#F7F4F2",
        backgroundInverse: "#14171A",
        backgroundAlwaysDark: "#212326",

        backgroundHover: "rgba(20,23,26,0.07)",
        backgroundInverseHover: "rgba(255,255,255,0.07)",

        chartPrimary: "#1979FF",
        chartYellow: "#FFC400",
        chartGreen: "#00FFA7",
        chartIndigo: "#B14AFF",

        availableLessonRoomHeaderFirst: "rgba(232, 242, 255, 0.8)",
        availableLessonRoomHeaderSecond: "rgb(204,229,255)",
        warningHeader: "rgba(255,251,230,0.8)",
        fatalErrorHeader: "rgb(255,232,232,0.8)",

        warningBulletColor: "#F23635",

        moneyNegativeBalance: "#F23635",

        fullScreenVideoBackground: "#364d6a",


        // Старые токены

        alertErrorBorder: "#ffccc7",
        alertErrorBg: "#fff2f0",
        alertErrorText: "#000000",

        navigationBackdrop: "#000000",

        headerDivider: "#EDEDED",

        modalBackdrop: "rgba(0,0,0,0.3)",

        noticeBackground: "#a7a7a714",
        noticeBorderAccent: "#19b3ff",

        coloredNoticeBackground: "#e8f2ff",

        disabledButtonBackground: "#F5F5F5",

        constructorCorrectAnswer: "#daf5bf",
        constructorCorrectAnswerFont: "#000000",

        constructorIncorrectAnswer: "#fa8080",
        constructorIncorrectAnswerFont: "#ffffff",

        progressBarBackground: "#F7F4F2",
        progressBarWrong: "#F23635",
        progressBarRight: "#00d58b",

        walletPlusGreen: "#57D9AC",
        walletMinusSum: "#F23635",

        studentRateParam1Color: "#FFC400",
        studentRateParam2Color: "#00FFA7",
        studentRateParam3Color: "#B14AFF",
        studentRateParam4Color: "#ff4ae7",
        studentRateParam5Color: "#1979FF",
        studentRateTotalAverageColor: "#1979FF",
    },

    slideContent: {
        textTeacherNotes: "#a32346",//"#db889f", //"#818181",
        textTeacherCanSay: "#005291",//"#4688bb",
        textTeacherAlsoDiscuss: "#744300",

        matchExerciseBlue: "#1979FF",
        matchExerciseConnected: "#daf5bf",

        blueBg: "#e7f1fe",
        blueText: "#718caf",

        playerAccent: "#1979FF",

        textInputDefaultBg: "#FFFFFF",
        textInputCorrectBg: "#ecf9dd",

        radioBorder: "#D2D2D2",

        teacherNotesBg: "#F8EDFF",
        teacherCanSayBg: "#E8F2FF",
        teacherAlsoDiscussBg: "#E6FFF7",

        historyTipIncorrectText: "#fa8080",
        historyTipCorrectText: "#63a125",
    },

    otherColors: {
        saveYourAchievementsBannerPrimary: '#ffeded',
        enablePushNotificationsBanner: '#e8f1ff'
    },

    filters: {
        authBackground: "brightness(0.8)"
    },

    media: {
        extraLarge: "min-width: 1440px",
        large: "min-width: 1270px",
        medium: "min-width: 1024px",
        small: "min-width: 768px",
        extraSmall: "maх-width: 767px"
    },

    opacity: {
        navigationBackdrop: "0.3"
    },

    size: {
        headerHeight: 72
    },

    zIndices: {
        pageContent: 1,
        tinyVideoOnPage: 2,
        dropDownList: 3,
        bottomPageNavigation: 5,
        header: 6,
        navigation: 7,
        videoFullScreenArea: 8,
        modal: 9,
        cameraAccessRequestSplash: 10,
        splashScreen: 11,
        tempHighest: 12
    }
}
