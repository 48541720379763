import {
    DtoLessonPlanItem
} from "../../../../../../../components/HttpApiClient/ApiDto/Response/Lessons/DtoLessonPlanItem";
import React, {useMemo} from "react";
import styled from "styled-components";
import {container} from "tsyringe";
import {IDateHelperService} from "../../../../../../../services/date-helper/IDateHelperService";
import {DiTokens} from "../../../../../../../di-factory/DiTokens";
import {TimePeriodWithClockIcon} from "../../../../common/TimePeriodWithClockIcon";

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: ${({theme}) => theme.colors.textSecondary};
    font-size: 13px;
    
    @media (${({theme}) => theme.media.small}) {
        font-size: 16px;
    }
`;

const WeekDayNameWrapper = styled.div`
    text-transform: capitalize;
`;

const TimePeriodWrapper = styled.div`
    text-align: right;
`;

interface ItemProps {
    item: DtoLessonPlanItem;
    className?: string;
}

export const Item: React.FC<ItemProps> = (
    {
        item,
        className
    }
) => {
    const dateHelperService = useMemo(() => container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE), []);
    const startDateTime = useMemo(() => dateHelperService.dateFromString(item.utcStartDateTime, true), [dateHelperService, item.utcStartDateTime])

    const weekDayName = useMemo(() => {
        const formatter = dateHelperService.getFormatter(
            false,
            false,
            'Asia/Yerevan',
            {
                weekday: 'long'
            }
        );

        return formatter.format(startDateTime)
    }, [dateHelperService, startDateTime]);

    const timeLine = useMemo(() => {
        return dateHelperService.timeRangeFromStartAndDuration(startDateTime, item.duration);
    }, [dateHelperService, item.duration, startDateTime]);

    return <Wrapper className={className}>
        <WeekDayNameWrapper>{weekDayName}</WeekDayNameWrapper>

        <TimePeriodWrapper>
            <TimePeriodWithClockIcon timeString={timeLine}/>
        </TimePeriodWrapper>
    </Wrapper>
}