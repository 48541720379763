import {DtoRequestWithPagination} from "../Common/DtoRequestWithPagination";
import {IsArray, IsNotEmpty, IsString, ValidateNested} from 'class-validator';
import {Type} from "class-transformer";
import {DtoNotificationChannel} from "./DtoNotificationChannel";

/**
 * DTO канала уведомлений
 */
export class DtoNotificationGroup extends DtoRequestWithPagination<DtoNotificationChannel> {
    @IsNotEmpty()
    @IsString()
    id: string;

    @IsNotEmpty()
    @IsString()
    alias: string;

    @IsNotEmpty()
    @IsArray()
    @ValidateNested({each: true})
    @Type(() => DtoNotificationChannel)
    list: Array<DtoNotificationChannel>;
}