import {FooterMode, Modal, ModalChildProps, ModalControlParams} from "../../Ui/Elements/Modal";
import React, {forwardRef, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Form, FormItem, useForm} from '../../Ui/Elements/Form';
import {BtnStyleEnum, Button} from "../../Ui/Elements/Button";
import {Input} from "../../Ui/Elements/TextInput";
import {t, Trans} from "@lingui/macro";
import {ModalSubTitle, ModalTitle} from "../../../styles/global-elements";
import {PopupActions} from "reactjs-popup/dist/types";
import {RadioGroup, RadioInput} from "../../Ui/Elements/RadioInput";
import {Space} from "antd";
import {Checkbox} from "../../Ui/Elements/Checkbox";
import {ActionForm} from "./ActionForm";
import {SlideItemInteractivityActionTypeEnum} from "../../../../enums/SlideItemInteractivityActionNameEnum";
import {
    SlideItemInteractivityActionConditionModeEnum
} from "../../../../enums/SlideItemInteractivityActionConditionModeEnum";
import {ActionFormMethodsInterface} from "./ActionForm/ActionFormMethodsInterface";
import {SlideItemInteractivityActionsRunModeEnum} from "../../../../enums/SlideItemInteractivityActionsRunModeEnum";
import {
    EditorItemInteractivityConfig,
    EditorItemInteractivityConfigAction
} from "../../SlidePlayerEditorCommonParts/EditorData";
import {ElementTypeEnum} from "../../SlidePlayerEditorCommonParts/ElementTypeEnum";

enum FormFieldNames {
    ITEM_ALIAS = 'alias',
    VISIBILITY = 'visibility',
    ACTIONS_RUN_MODE = 'actionsRunMode',
    NEXT_BTN_LABEL = 'nextBtnLabel',
}

interface ElementInteractivityConfigModalProps extends ModalChildProps {
    elementType: ElementTypeEnum;
    visibility: boolean;
    slideItemAlias: string | null;
    interactivityConfig: EditorItemInteractivityConfig;

    onSave: (visibility: boolean, slideItemAlias: string | null, interactivityConfig: EditorItemInteractivityConfig | null) => void;
}

export const ElementInteractivityConfigModal = forwardRef<PopupActions, ElementInteractivityConfigModalProps>(
    (props, modalRef) => {
        const [form] = useForm();
        // const modalRef = useRef<ReactjsPopupActions>(null);
        const actionForms = useRef<Array<ActionFormMethodsInterface | null>>([]);

        const [actions, setActions] = useState<EditorItemInteractivityConfigAction[]>(props.interactivityConfig.actions);
        const [nextBtnLabelFieldVisible, setNextBtnLabelFieldVisible] = useState<boolean>(props.interactivityConfig.actionRunMode === SlideItemInteractivityActionsRunModeEnum.BY_NEXT_BTN);

        useEffect(() => {
            actionForms.current = actionForms.current.slice(0, actions.length);
        }, [actions]);

        const elementHasCompleted = useMemo(() => {
            return [
                ElementTypeEnum.EXERCISES_RADIO,
                ElementTypeEnum.EXERCISES_CHECKBOX,
                ElementTypeEnum.EXERCISES_FILL_GAPS_COMBOBOX,
                ElementTypeEnum.EXERCISES_FILL_GAPS_TEXT,
                ElementTypeEnum.EXERCISES_FILL_GAPS_DRAG_DROP,
                ElementTypeEnum.EXERCISES_MATCH,
                ElementTypeEnum.EXERCISES_CORRECT_EXIST_IN_INPUT,
                ElementTypeEnum.DIALOG_BUBBLE

            ].some(item => item === props.elementType);
        }, [props.elementType]);

        const addAction = useCallback(() => {
            setActions((actions) => {
                return [
                    ...actions,
                    {
                        actionType: SlideItemInteractivityActionTypeEnum.SHOW_ITEM_BY_ALIAS,
                        actionAdditionalInfo: null,
                        condition: {
                            conditionMode: SlideItemInteractivityActionConditionModeEnum.POSITIVE,
                            fieldForCondition: 'answer',
                            targetValue: ''
                        },
                    }
                ]
            })
        }, []);

        const onSaveBtnClick = useCallback(async (controls: ModalControlParams) => {
            const actions = [];

            for (let index = 0; index < actionForms.current.length; index++) {
                const currentRef = actionForms.current[index];

                if (currentRef) {
                    actions.push(await currentRef.getData());
                }
            }

            const fields = await form.validateFields();

            props.onSave(
                fields[FormFieldNames.VISIBILITY],
                (fields[FormFieldNames.ITEM_ALIAS] === null)
                    ? null
                    : fields[FormFieldNames.ITEM_ALIAS].trim(),
                (actions.length === 0)
                    ? null
                    : {
                        actions: actions,
                        actionRunMode: fields[FormFieldNames.ACTIONS_RUN_MODE],
                        nextBtnLabel: fields[FormFieldNames.NEXT_BTN_LABEL] ?? null
                    }
            );

            controls.closeModal();
        }, [form, props]);

        const footerContent = (controls: ModalControlParams) => {
            return <>
                <Button btnStyle={BtnStyleEnum.Primary}
                        onClick={() => onSaveBtnClick(controls)}>
                    <Trans>Применить</Trans>
                </Button>

                <Button btnStyle={BtnStyleEnum.Secondary}
                        onClick={() => controls.closeModal()}>
                    <Trans>Отменить</Trans>
                </Button>
            </>;
        }

        return <Modal innerRef={modalRef}
                      trigger={props.trigger}
                      closeAllowed={true}
                      onOpen={() => {
                          setActions(props.interactivityConfig.actions);
                          form.resetFields();
                          setNextBtnLabelFieldVisible(props.interactivityConfig.actionRunMode === SlideItemInteractivityActionsRunModeEnum.BY_NEXT_BTN)
                      }}
                      onClose={() => {
                          setActions([]);
                      }}
                      footer={footerContent}
                      footerMode={FooterMode.SPACE_BETWEEN}
                      children={
                          (controls) => {
                              return <div>
                                  <ModalTitle>
                                      <Trans>Настройки интерактивности элемента</Trans>
                                  </ModalTitle>
                                  <ModalSubTitle><Trans>Настройки элемента</Trans></ModalSubTitle>
                                  <Form form={form} layout={"vertical"}>
                                      <FormItem
                                          name={FormFieldNames.ITEM_ALIAS}
                                          initialValue={props.slideItemAlias}
                                          label={t`Технический псевдоним (alias) элемента слайда`}
                                          rules={[
                                              {
                                                  type: "string",
                                                  max: 100,
                                                  message: t`Название должно быть не длиннее 100 символов`
                                              }
                                          ]}
                                      >
                                          <Input
                                              status={""}
                                              type="text"
                                              size="large"
                                          />
                                      </FormItem>
                                      <FormItem
                                          name={FormFieldNames.VISIBILITY}
                                          initialValue={props.visibility}
                                          valuePropName={'checked'}
                                      >
                                          <Checkbox><Trans>Элемент отображается сразу</Trans></Checkbox>
                                      </FormItem>

                                      {
                                          (elementHasCompleted)
                                          && <>
                                              <ModalSubTitle><Trans>Действия по завершению выполнения
                                                  элемента</Trans></ModalSubTitle>

                                              <FormItem
                                                  name={FormFieldNames.ACTIONS_RUN_MODE}
                                                  initialValue={props.interactivityConfig.actionRunMode}
                                              >
                                                  <RadioGroup onChange={(e) => {
                                                      setNextBtnLabelFieldVisible(e.target.value === SlideItemInteractivityActionsRunModeEnum.BY_NEXT_BTN)
                                                  }}>
                                                      <Space direction={"vertical"}>
                                                          <RadioInput
                                                              value={SlideItemInteractivityActionsRunModeEnum.IMMEDIATELY}>
                                                              <Trans>Выполнить сразу</Trans>
                                                          </RadioInput>

                                                          <RadioInput
                                                              value={SlideItemInteractivityActionsRunModeEnum.BY_NEXT_BTN}>
                                                              <Trans>Выполнить по нажатию кнопки «Продолжить»</Trans>
                                                          </RadioInput>
                                                      </Space>
                                                  </RadioGroup>
                                              </FormItem>

                                              {
                                                  (nextBtnLabelFieldVisible)
                                                  && <FormItem
                                                      name={FormFieldNames.NEXT_BTN_LABEL}
                                                      initialValue={props.interactivityConfig.nextBtnLabel}
                                                      label={t`Надпись на кнопке «Продолжить»`}
                                                      rules={[
                                                          {
                                                              type: "string",
                                                              max: 30,
                                                              message: t`Длина должна быть не более 30 символов`
                                                          }
                                                      ]}
                                                  >
                                                      <Input
                                                          status={""}
                                                          type="text"
                                                          size="large"/>
                                                  </FormItem>
                                              }
                                          </>
                                      }
                                  </Form>

                                  {
                                      (elementHasCompleted)
                                      && <>
                                          {
                                              actions.map((actionItem, index) => {
                                                  return <ActionForm
                                                      ref={el => actionForms.current[index] = el}
                                                      key={index}
                                                      actionIndex={index}
                                                      actionConfig={actionItem}
                                                  />
                                              })
                                          }

                                          <Button
                                              btnStyle={BtnStyleEnum.Secondary}
                                              onClick={addAction}
                                          >
                                              + <Trans>Добавить действие</Trans>
                                          </Button>
                                      </>
                                  }
                              </div>
                          }
                      }
        />
    });


ElementInteractivityConfigModal.displayName = 'ElementInteractivityConfigModal';