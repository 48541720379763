import {IGroupScheduleReader} from "./IGroupScheduleReader";
import {DtoGroupSchedule} from "../../../components/StonlineApiClient/ApiDto/Response/StudentGroups/DtoGroupSchedule";
import {GroupScheduleReaderResultItemType} from "./GroupScheduleReaderResultItemType";
import {IDateHelperService} from "../../../services/date-helper/IDateHelperService";
import {ScheduleFormFieldsEnum as ScheduleFormFields} from "./ScheduleFormFieldsEnum";

export class GroupScheduleReader implements IGroupScheduleReader {

    dateHelperService: IDateHelperService;

    constructor(dateHelperService: IDateHelperService) {
        this.dateHelperService = dateHelperService;
    }

    /**
     * @inheritDoc
     */
    readSchedule(schedule: DtoGroupSchedule): GroupScheduleReaderResultItemType[] {
        const result: GroupScheduleReaderResultItemType[] = [];

        const dayNums: string = (schedule.weekDays) ? schedule.weekDays.trim() : '';

        for (let charIndex = 0; charIndex < dayNums.length; charIndex++) {
            let weekDayNum = parseInt(dayNums[charIndex]);

            const timesByDay = this.getTimesByDayNum(weekDayNum, schedule);

            if (weekDayNum === 7) {
                weekDayNum = 0;
            }

            result.push({
                weekDayNum: weekDayNum,
                timeStart: this.dateHelperService.removeSecondsFromTimeString(timesByDay.startTime),
                timeEnd: this.dateHelperService.removeSecondsFromTimeString(timesByDay.endTime),
            });
        }

        return result;
    }

    protected getTimesByDayNum(dayNum: number, scheduleObject: DtoGroupSchedule): { startTime: string, endTime: string } {
        switch (dayNum) {
            case 1:
                return {
                    startTime: scheduleObject.mondayTimeStart,
                    endTime: scheduleObject.mondayTimeEnd,
                };
            case 2:
                return {
                    startTime: scheduleObject.tuesdayTimeStart,
                    endTime: scheduleObject.tuesdayTimeEnd,
                };
            case 3:
                return {
                    startTime: scheduleObject.wednesdayTimeStart,
                    endTime: scheduleObject.wednesdayTimeEnd,
                };
            case 4:
                return {
                    startTime: scheduleObject.thursdayTimeStart,
                    endTime: scheduleObject.thursdayTimeEnd,
                };
            case 5:
                return {
                    startTime: scheduleObject.fridayTimeStart,
                    endTime: scheduleObject.fridayTimeEnd,
                };
            case 6:
                return {
                    startTime: scheduleObject.saturdayTimeStart,
                    endTime: scheduleObject.saturdayTimeEnd,
                };
            case 7:
                return {
                    startTime: scheduleObject.sundayTimeStart,
                    endTime: scheduleObject.sundayTimeEnd,
                };
            default: {
                throw new Error("Unknown week day num");
            }
        }
    }

    getFormNames (dayNum: number): {ENABLED: string, TIME_START: string, TIME_END: string} {
        const result = {
            ENABLED: '',
            TIME_START: '',
            TIME_END: ''
        }

        switch (dayNum) {
            case 1: {
                result.ENABLED = ScheduleFormFields.MONDAY_ENABLED;
                result.TIME_START = ScheduleFormFields.MONDAY_TIME_START;
                result.TIME_END = ScheduleFormFields.MONDAY_TIME_END;

                break;
            }
            case 2: {
                result.ENABLED = ScheduleFormFields.TUESDAY_ENABLED;
                result.TIME_START = ScheduleFormFields.TUESDAY_TIME_START;
                result.TIME_END = ScheduleFormFields.TUESDAY_TIME_END;

                break;
            }
            case 3: {
                result.ENABLED = ScheduleFormFields.WEDNESDAY_ENABLED;
                result.TIME_START = ScheduleFormFields.WEDNESDAY_TIME_START;
                result.TIME_END = ScheduleFormFields.WEDNESDAY_TIME_END;

                break;
            }
            case 4: {
                result.ENABLED = ScheduleFormFields.THURSDAY_ENABLED;
                result.TIME_START = ScheduleFormFields.THURSDAY_TIME_START;
                result.TIME_END = ScheduleFormFields.THURSDAY_TIME_END;

                break;
            }
            case 5: {
                result.ENABLED = ScheduleFormFields.FRIDAY_ENABLED;
                result.TIME_START = ScheduleFormFields.FRIDAY_TIME_START;
                result.TIME_END = ScheduleFormFields.FRIDAY_TIME_END;

                break;
            }
            case 6: {
                result.ENABLED = ScheduleFormFields.SATURDAY_ENABLED;
                result.TIME_START = ScheduleFormFields.SATURDAY_TIME_START;
                result.TIME_END = ScheduleFormFields.SATURDAY_TIME_END;

                break;
            }
            case 7: {
                result.ENABLED = ScheduleFormFields.SUNDAY_ENABLED;
                result.TIME_START = ScheduleFormFields.SUNDAY_TIME_START;
                result.TIME_END = ScheduleFormFields.SUNDAY_TIME_END;

                break;
            }
        }

        return result;
    };

    /**
     * @inheritDoc
     */
    createScheduleDtoByForm(formObject: { [p: string]: any }): DtoGroupSchedule {
        const dtoObject = new DtoGroupSchedule();

        dtoObject.weekDays = ' ';

        const fieldsDayNames = [
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'saturday',
            'sunday',
        ];

        for (let i = 1; i <= 7; i++) {
            const fieldNames = this.getFormNames(i);

            const dayName = fieldsDayNames[i-1];

            // @ts-ignore
            dtoObject[`${dayName}BreakTime`] = 0;
            // @ts-ignore
            dtoObject[`${dayName}TimeStart`] = '00:00:00';
            // @ts-ignore
            dtoObject[`${dayName}TimeEnd`] = '00:00:00';

            if (!formObject[fieldNames.ENABLED]) {
                continue;
            }

            const timeStartValue = formObject[fieldNames.TIME_START];
            const timeEndValue = formObject[fieldNames.TIME_END];

            if (!timeStartValue || !timeEndValue) {
                continue;
            }

            dtoObject.weekDays = dtoObject.weekDays.concat(i.toString(10));

            // @ts-ignore
            dtoObject[`${dayName}TimeStart`] = timeStartValue.format('HH:mm').concat(':00');
            // @ts-ignore
            dtoObject[`${dayName}TimeEnd`] = timeEndValue.format('HH:mm').concat(':00');
        }

        return dtoObject;
    }
}