import React from 'react';
import {Form as AntdForm, FormItemProps as AntdFormItemProps, FormProps as AntdFormProps} from 'antd';
import {FormProvider as AntdFormProvider, FormProviderProps as AntdFormProviderProps} from "antd/lib/form/context";
import styled from "styled-components";

interface FormProps extends AntdFormProps {
}

export const Form: React.FC<FormProps> = ({...props}) => {
    return <AntdForm {...props}/>
}

export const FormUseWatch = AntdForm.useWatch;

interface FormItemProps extends AntdFormItemProps {
}

export const FormItemNoStyle: React.FC<FormItemProps> = ({...props}) => {
    return <AntdForm.Item {...props}/>
}

export const FormItem = styled(FormItemNoStyle)`
  margin: 0 0 35px;
  position: relative;
  width: 100%;

  .ant-form-inline .ant-form-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .ant-form-item-label {
    padding: 0 !important;
    white-space: nowrap;
    
    overflow: initial;
  }

  .ant-form-item-label > label {
    font-size: 14px;
    color: ${({theme}) => theme.colors.textTertiary};
    white-space: break-spaces;

    // @media (${({theme}) => theme.media.small}) {
    //   font-size: 18px;
    // }
  }

  .ant-form-item-label > label:after {
    content: "";
  }

  .ant-form-item-label > label {
    height: auto;
  }

  .ant-form-item-label, .ant-form-item-control {
    width: 100%;
    text-align: left;
  }

  .ant-form-item-required:before {
    display: block !important;
    position: absolute;
    top: 0;
    left: -8px;
    height: 4px;
    width: 4px;
    margin: 0;
    content: "*" !important;
    font-size: 23px !important;
    font-family: Raleway,Arial,serif !important;
  }

  .ant-form-item-required::after {
    display: none;
    //display: block !important;
    //position: absolute;
    //top: 7px;
    //right: -7px;
    //height: 4px;
    //width: 4px;
    //background-color: #ff5757;
    //border-radius: 2px;
    //margin: 0;
    //content: " ";
  }
`;

export const useForm = AntdForm.useForm;

export const FormProvider: React.FC<AntdFormProviderProps> = (props) => {
    return <AntdFormProvider {...props}/>
};
