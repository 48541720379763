import * as React from "react";
import {useEffect, useMemo, useState} from "react";
import {Outlet, useLocation} from "react-router-dom";
import {CreditCardLogos, IconColor} from "../../components/CreditCardLogos";
import {CookieDocsLink} from "../../components/CookieDocsLink";
import {SpinnerArea} from "./components/SpinnerArea";
import {
    BackgroundWrapper,
    BottomLineWrapper,
    ButtomLineLeftCol,
    ButtomLineRightCol,
    Dialog,
    DialogWrapper,
    Footer,
    FooterItem,
    FormLoaderWrapper,
    FormWrapper,
    LogoWrapper,
    PageWrapper
} from './style';
import {AnimatedContainer} from "./components/AnimatedContainer";
import {AnimatePresence, motion, Variants} from "framer-motion";
import {Alert} from "../../components/Ui/Elements/Alert";
import {Logo} from "../../components/Ui/Elements/Logo";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import {ITheme} from "../../../services/theme/ITheme";
import useMediaQuery from "../../../services/hooks/useMediaQuery";
import {RoutesList} from "../../RoutesList";

export interface OutletContext {
    setLoader: (visible: boolean, text?: string) => void,
    setAlertMessage: (text: string | null) => void
}

export const AuthPage: React.FC = () => {
    const [showLoader, setShowLoader] = useState(false);
    const [loaderText, setLoaderText] = useState('');
    const [alertMessage, setAlertMessage] = useState<null | string>(null);

    const location = useLocation();

    const setLoader = (visible: boolean, text?: string) => {
        setShowLoader(visible);

        setLoaderText((visible && (text !== undefined)) ? text : '');
    }

    const currentTheme = useSelector<ApplicationState>(
        ({layout}: ApplicationState) => layout.activeTheme
    ) as ITheme;
    const isSmall = useMediaQuery(`(${currentTheme.media.small})`);

    const alertMessageWrapper = () => {
        if (alertMessage) {
            const animations: Variants = {
                initial: {opacity: 0, y: -40},
                animate: {opacity: 1, y: 0},
                //exit: {opacity: 0, y: -40},
            }

            return <motion.div
                variants={animations}
                initial="initial"
                animate="animate"
                transition={{type: "tween", ease: "easeOut", duration: .2}} //*/[.25, .73, .66, .86]
            >
                <div style={{marginBottom: "30px"}}>
                    <Alert message={alertMessage} type="error"/>
                </div>
            </motion.div>
        }
    }

    useEffect(() => {
        return () => {
            setAlertMessage(null);
        }
    }, [location]);

    const content = () => {
        const outletContext: OutletContext = {
            setLoader,
            setAlertMessage
        };

        return (
            <div style={{minHeight: "125px"}}>
                <div style={{display: (!showLoader) ? 'block' : 'none'}}>
                    {alertMessageWrapper()}
                    <AnimatePresence exitBeforeEnter={true} initial={false}>
                        <Outlet context={outletContext}/>
                    </AnimatePresence>
                </div>

                <FormLoaderWrapper style={{display: (showLoader) ? 'block' : 'none'}}>
                    <AnimatePresence exitBeforeEnter={true} initial={false}>
                        <AnimatedContainer>
                            <>
                                <SpinnerArea tip={loaderText}/>
                            </>
                        </AnimatedContainer>
                    </AnimatePresence>
                </FormLoaderWrapper>
            </div>
        )

        // switch (props.authPageMode) {
        //     case AuthPageMode.SIGN_UP: {
        //         return <SignUpForm setLoader={setLoader}/>;
        //     }
        //     case AuthPageMode.RESTORE_PASS: {
        //         return <SignUpForm setLoader={setLoader}/>;
        //     }
        //     default: {
        //         return <LoginForm setLoader={setLoader}/>;
        //     }
        // }
    }

    const backgroundImage = useMemo(() => {
        const images = process.env.REACT_APP_AUTH_BG_IMAGES as string;

        const imageArray = images.split(' ');

        return `/login-bg/${imageArray[Math.floor(Math.random() * imageArray.length)]}`;
    }, []);

    // useEffect(() => {
    //     document.body.style.backgroundImage = `url('${backgroundImage()}')`;
    //
    //     return () => {
    //         document.body.style.backgroundImage = 'none';
    //     }
    // }, []);

    return (
        <div>
            <PageWrapper>
                <BackgroundWrapper
                    style={{
                        backgroundImage: `url('${backgroundImage}')`
                    }}
                />
                <DialogWrapper>
                    <Dialog>
                        <LogoWrapper href={process.env.REACT_APP_LANDING_URL ?? '#'} target={"_blank"}>
                            <Logo width="177px"/>
                        </LogoWrapper>
                        <FormWrapper>
                            {content()}
                        </FormWrapper>
                        <Footer>
                            {
                                !isSmall && (
                                    <FooterItem>
                                        <CreditCardLogos iconColor={(!isSmall) ? IconColor.GRAY : IconColor.WHITE}/>
                                    </FooterItem>
                                )
                            }
                            <FooterItem>
                                <CookieDocsLink/>
                            </FooterItem>
                        </Footer>
                    </Dialog>
                </DialogWrapper>
                {
                    isSmall && (
                        <BottomLineWrapper>
                            <ButtomLineLeftCol>
                                <CookieDocsLink/>
                            </ButtomLineLeftCol>
                            <ButtomLineRightCol>
                                <CreditCardLogos iconColor={(!isSmall) ? IconColor.GRAY : IconColor.WHITE}/>
                            </ButtomLineRightCol>
                        </BottomLineWrapper>
                    )
                }
            </PageWrapper>
        </div>
    );
}