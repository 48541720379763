import {
    ActionParamExerciseUserAnswer,
    ActionParamExerciseUserValue,
    ActionParamItemAdditionalData,
    ActionParamItemOverriddenParams
} from "../../../store/slidesWorkData/type";
import {SlidePlayerIdEnum} from "../../../enums/SlidePlayerIdEnum";
import {container} from "tsyringe";
import {ILogger} from "../../../components/Logger/ILogger";
import {DiTokens} from "../../../di-factory/DiTokens";
import {LoggerSectionsEnum} from "../../../components/Logger/LoggerSectionsEnum";
import {call, put, select} from "redux-saga/effects";
import {
    AdditionalDataPayload,
    AnswerPayload,
    ItemHandleState,
    OverriddenParamsPayload,
    QueueItem,
    QueueItemContext,
    ValuePayload
} from "../../../store/slidesWorkDataSaveQueue/type";
import {newItemToQueue} from "../../../store/slidesWorkDataSaveQueue/actions";
import {lastQueueItemSelector} from "../../../store/slidesWorkDataSaveQueue/selector";
import {cloneDeep, isEqual} from "lodash";
import {currentUserIdSelector} from "../../../store/user/selector";
import {
    QueueItemDataTypeEnum,
    QueueItemTargetEnum
} from "../../../components/WsApiClient/ApiDto/Request/SlidesWorkData/QueueItem";
import {ISlidePlayerPatternHelper} from "../../../services/slide-player-id-pattern-helper/ISlidePlayerPatternHelper";

export enum DataTypeEnum {
    VALUE,
    ANSWER,
    ADDITIONAL_DATA,
    OVERRIDDEN_PARAMS
}

/**
 * Отправить данные в очередь на сохранение
 *
 * @param dataType
 * @param data
 */
export function* pushDataToSaveQueue(dataType: DataTypeEnum, data: ActionParamExerciseUserAnswer | ActionParamItemAdditionalData | ActionParamExerciseUserValue<string> | ActionParamItemOverriddenParams) {
    // 1. Определяем - что это за данные
    const playerId = data.playerId;

    const slidePlayerPatternHelper = container.resolve<ISlidePlayerPatternHelper>(DiTokens.SLIDE_PLAYER_PATTERN_HELPER);
    const playerIdMatchPatternResultData = slidePlayerPatternHelper.findPlayerIdPattern(playerId);

    if (playerIdMatchPatternResultData === null) {
        return;
    }

    const pattern = playerIdMatchPatternResultData.pattern;

    switch (pattern) {
        case SlidePlayerIdEnum.TEACHER_LESSON_TEST: {
            // не нужно ничего сохранять
            return;
        }
        case SlidePlayerIdEnum.STUDENT_SLIDE_ON_LESSON:
        case SlidePlayerIdEnum.STUDENT_SLIDE_ON_HOMEWORK: {
            // Нужно поставить значение, введённое самим учеником, в очередь на сохранение
            const userId = (yield select(currentUserIdSelector)) as string | null;

            if (userId === null) {
                throw new Error('Not found user id');
            }

            yield call(
                pushItemToSaveQueue,
                userId,
                data.slideItemId as string,
                dataType,
                data,
                (pattern === SlidePlayerIdEnum.STUDENT_SLIDE_ON_HOMEWORK)
                    ?QueueItemTargetEnum.STUDENT_HOMEWORK
                    :QueueItemTargetEnum.STUDENT_IN_LESSON,
                {
                    lessonId: playerIdMatchPatternResultData.matchResult.params.lessonId as string,
                    tmSlideId: data.slideId as string,
                }
            );

            break;
        }
        case SlidePlayerIdEnum.SELF_STUDY_TRACK_EPISODE: {
            // Нужно поставить значение, введённое самим учеником, в очередь на сохранение
            const userId = (yield select(currentUserIdSelector)) as string | null;

            if (userId === null) {
                throw new Error('Not found user id');
            }

            yield call(
                pushItemToSaveQueue,
                userId,
                data.slideItemId as string,
                dataType,
                data,
                QueueItemTargetEnum.SELF_STUDY_TRACK_SLIDE,
                {
                    episodeId: playerIdMatchPatternResultData.matchResult.params.episodeId as string,
                    tmSlideId: data.slideId as string,
                }
            );

            break;
        }
        default: {
            const logger = container.resolve<ILogger>(DiTokens.LOGGER);

            logger.error(
                LoggerSectionsEnum.SLIDES_WORK_DATA_SAVE_QUEUE,
                'Unknown slide player id pattern: ',
                pattern
            );
        }
    }
}

/**
 * Поставить в очередь изменение на слайде
 *
 * @param targetUserId
 * @param slideItemId
 * @param dataType
 * @param data
 * @param targetType
 * @param contextData
 */
function* pushItemToSaveQueue(
    targetUserId: string,
    slideItemId: string,
    dataType: DataTypeEnum,
    data: ActionParamExerciseUserAnswer | ActionParamItemAdditionalData | ActionParamExerciseUserValue<string> | ActionParamItemOverriddenParams,
    targetType: QueueItemTargetEnum,
    contextData: QueueItemContext
) {
    let queueItemDataType: QueueItemDataTypeEnum;
    let payload: AdditionalDataPayload | ValuePayload | AnswerPayload | OverriddenParamsPayload;

    switch (dataType) {
        case DataTypeEnum.OVERRIDDEN_PARAMS: {
            queueItemDataType = QueueItemDataTypeEnum.OVERRIDDEN_PARAMS;
            payload = (data as ActionParamItemOverriddenParams).overriddenParams;

            break
        }
        case DataTypeEnum.ADDITIONAL_DATA: {
            queueItemDataType = QueueItemDataTypeEnum.ADDITIONAL_DATA;
            payload = {
                value: data.value
            } as AdditionalDataPayload;

            break
        }
        case DataTypeEnum.VALUE: {
            queueItemDataType = QueueItemDataTypeEnum.VALUE;
            payload = {
                value: data.value
            } as ValuePayload;

            break
        }
        case DataTypeEnum.ANSWER: {
            queueItemDataType = QueueItemDataTypeEnum.ANSWER;
            const dataValue = data as ActionParamExerciseUserAnswer;

            payload = {
                value: dataValue.value,
                award: dataValue.award,
                missedAward: dataValue.missedAward,
                answerIsCorrect: dataValue.answerIsCorrect
            } as AnswerPayload;

            break;
        }
        default: {
            throw new Error('Unknown data type: ' + dataType);
        }
    }

    const queueItem: QueueItem<QueueItemContext> = {
        itemNumber: 0,
        handleState: ItemHandleState.WAIT,
        targetType: targetType,
        targetUserId: targetUserId,
        slideItemId: slideItemId,
        exerciseId: data.exerciseId,
        dataType: queueItemDataType,
        payload: payload,
        contextData: contextData
    };

    // Сравним предыдущее значение в очереди, чтобы не повторять одинаковые элементы
    let lastQueueItem = (yield select(lastQueueItemSelector)) as QueueItem<QueueItemContext> | null;

    if (lastQueueItem !== null) {
        lastQueueItem = cloneDeep(lastQueueItem);
        lastQueueItem.itemNumber = 0;
        lastQueueItem.handleState = ItemHandleState.WAIT;

        if (isEqual(queueItem, lastQueueItem)) {
            const logger = container.resolve<ILogger>(DiTokens.LOGGER);

            logger.info(
                LoggerSectionsEnum.SLIDES_WORK_DATA_SAVE_QUEUE,
                'New queue item ignored (duplicate last)'
            );

            return;
        }
    }

    yield put(newItemToQueue(queueItem));
}
