import React from "react";
import {LoggerSectionsEnum} from "../../../../../../components/Logger/LoggerSectionsEnum";
import {DefaultLoader} from "../../../../../components/DefaultLoader";
import {ErrorLoadingContent} from "../../../../../components/Ui/Elements/ErrorLoadingContent";
import {t, Trans} from "@lingui/macro";
import {BtnStyleEnum, Button} from "../../../../../components/Ui/Elements/Button";
import {SelectMaterialsModal} from "../../../../../components/TeachingMaterialsCatalog/select-materials-modal";
import {ConfirmDialog} from "../../../../../components/Ui/Elements/ConfirmDialog";
import {LessonMaterialsList} from "./LessonMaterialsList";
import {NotificationTypesEnum, openNotification} from "../../../../../components/Ui/Elements/Notification";
import {
    ButtonItemWrapper,
    ButtonWrapper,
    LessonMaterialsBase,
    LessonMaterialsModePageProps,
    LoadingState,
    MaterialItemWithAdditionalFields,
    MAX_ITEMS_PER_PAGE,
    PageMode,
    Wrapper
} from "../../Common/LessonMaterialsModeBase";
import {cloneDeep} from "lodash";

export class LessonMaterialsModePage extends LessonMaterialsBase {
    constructor(props: Readonly<LessonMaterialsModePageProps> | LessonMaterialsModePageProps) {
        super(props);

        this.pageMode = PageMode.LESSON;
    }

    public fetchMaterials = () => {
        if (
            (this.props.apiToken === null)
            ||(this.context.groupIntId === null)
            ||(this.context.lessonIntId === null)
        ){
            return null;
        }

        this.setState(() => {
            return {
                loadingState: LoadingState.LOADING
            }
        });

        if (this.abortController !== null) {
            this.abortController.abort();
            this.abortController = null;
        }

        this.abortController = new AbortController();

        this.apiClient.getLessonMaterials(
            this.props.apiToken,
            this.context.groupIntId,
            this.context.lessonIntId,
            1, // Пока не используем пагинацию
            MAX_ITEMS_PER_PAGE,
            this.abortController
        )
            .then((data) => {
                this.setState(() => {
                    return {
                        loadingState: LoadingState.SUCCESS,
                    }
                });

                this.context.setLessonMaterialsList(data.data.list);
            })
            .catch((error) => {
                this.logger.error(LoggerSectionsEnum.LESSON_MATERIALS, "Error on fetch lesson materials info: ", error);

                this.setState(() => {
                    return {
                        loadingState: LoadingState.ERROR
                    }
                });
            });
    }


    protected toggleHiddenForStudent = (item: MaterialItemWithAdditionalFields) => {
        if (
            (this.props.apiToken === null)
            ||(this.context.groupIntId === null)
            ||(this.context.lessonIntId === null)
        ){
            return;
        }

        if (this.updateOpAbortController !== null) {
            this.updateOpAbortController.abort();
            this.updateOpAbortController = null;
        }

        const indexInContextList = this.getSlideIndexInLessonMaterials(item.slideTmId);

        if (indexInContextList === null) {
            return;
        }

        const itemInContextList = cloneDeep(this.context.lessonMaterialsList[indexInContextList]);

        if (!itemInContextList) {
            return;
        }

        this.updateOpAbortController = new AbortController();

        this.setSlideLockById(itemInContextList.slideTmId, true);

        const newValue = !itemInContextList.hiddenForStudent;

        this.apiClient.setLessonMaterialsSlideVisibility(
            this.props.apiToken,
            this.context.groupIntId,
            this.context.lessonIntId,
            item.slideTmId,
            newValue,
            // this.updateOpAbortController нарочно в запрос не передаём. Но в случае успеха проверим состояние
        )
            .then((result) => {
                if (this.updateOpAbortController?.signal?.aborted) {
                    // Мы сознательно не прерывали запрос, но результат обрабатывать не нужно
                    return;
                }

                this.context.setLessonMaterialsList(this.context.lessonMaterialsList.map((contextItem) => {
                    if (itemInContextList.slideTmId === contextItem.slideTmId) {
                        itemInContextList.hiddenForStudent = newValue;

                        return itemInContextList;
                    }

                    return contextItem;
                }));

                this.sendMessageAboutSlideListUpdated();
            })
            .catch((error) => {
                if (this.updateOpAbortController?.signal?.aborted) {
                    // Мы сознательно не прерывали запрос, но результат обрабатывать не нужно
                    return;
                }

                this.setSlideLockById(item.slideTmId, false);
                this.logger.error(LoggerSectionsEnum.LESSON_MATERIALS, "Error on toggle lesson materials slide", error);

                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Ошибка сохранения`,
                    t`Не удалось сохранить информацию`
                );
            });
    }

    protected copySlideToHomeworkOnClick = (item: MaterialItemWithAdditionalFields) => {
        if (
            (this.props.apiToken === null)
            || (this.context.groupIntId === null)
            || (this.context.lessonIntId === null)
        ) {
            return;
        }

        this.setSlideLockById(item.slideTmId, true);

        this.apiClient.addTmSlideToLesson(
            this.props.apiToken,
            this.context.groupIntId,
            this.context.lessonIntId,
            item.slideTmId,
            true
        )
            .then((result) => {
                this.setSlideLockById(item.slideTmId, false);

                this.fetchMaterialsInOppositeList();
            })
            .catch((error) => {
                this.logger.error(LoggerSectionsEnum.LESSON_MATERIALS, "Error on add tm slide to student group lesson", error);

                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Ошибка сохранения`,
                    t`Не удалось скопировать слайд. Попробуйте повторить попытку или добавить слайд из раздела домашней работы.`
                );
            })
    }

    render() {
        switch (this.state.loadingState) {
            case LoadingState.NOT_INIT:
            case LoadingState.LOADING: {
                return <DefaultLoader/>;
            }
            case LoadingState.SUCCESS: {
                return <Wrapper>
                    {
                        ((this.state.lessonMaterials.length > 0) && (this.props.apiToken))
                        && <LessonMaterialsList
                            apiToken={this.props.apiToken}
                            lessonMaterials={this.state.lessonMaterials}
                            allItemsDisabled={this.state.savingOrderNow}
                            toggleHiddenForStudent={this.toggleHiddenForStudent}
                            reorderItems={this.reorderItems}
                            deleteItem={this.deleteSlideOnClick}
                            copySlideToHomeworkOnClick={this.copySlideToHomeworkOnClick}
                        />
                    }

                    {(this.state.lessonMaterials.length === 0) && this.emptyListNotice()}

                    <br/>
                    <br/>

                    <ButtonWrapper>
                        <ButtonItemWrapper>
                            <Button btnStyle={
                                (this.state.lessonMaterials.length === 0)
                                    ? BtnStyleEnum.Primary
                                    : BtnStyleEnum.Primary}
                                    onClick={this.openSelectMaterialModal}>
                                <Trans>Добавить материалы</Trans>
                            </Button>
                        </ButtonItemWrapper>
                        {
                            (this.state.lessonMaterials.length > 0)
                            && <ButtonItemWrapper>
                                <ConfirmDialog okText={t`Очистить`}
                                               trigger={
                                                   <Button btnStyle={BtnStyleEnum.Secondary}
                                                           onClick={this.openSelectMaterialModal}>
                                                       <Trans>Очистить</Trans>
                                                   </Button>
                                               }
                                               ref={this.removeSlideConfirmDialogRef}
                                               cancelText={t`Отмена`}
                                               title={t`Удалить все слайды из урока?`}
                                               errorText={t`Не удалось удалить информацию`}
                                               okMethod={this.callClearAllSlides}/>
                            </ButtonItemWrapper>
                        }
                    </ButtonWrapper>

                    {
                        this.props.apiToken && this.context.groupIntId && this.context.lessonIntId
                        && <SelectMaterialsModal apiToken={this.props.apiToken}
                                                                     ref={this.selectMaterialModalRef}
                                                                     stLessonId={this.context.lessonIntId}
                                                                     stGroupId={this.context.groupIntId}
                                                                     addTmLessonToLesson={this.addTmLessonOnClick}
                                                                     slideSelector={{
                                                                         selectedSlides: this.state.lessonMaterials.map((item) => item.slideTmId),
                                                                         onSelectSlide: this.addTmSlideOnClick
                                                                     }}
                                                                     alreadySelectedSlideIds={[]}/>
                    }

                    {this.addLessonConfirmDialog()}
                    {this.addSlideConfirmDialog()}
                    {this.deleteSlideConfirmDialog()}
                </Wrapper>;
            }
            case LoadingState.ERROR: {
                return <ErrorLoadingContent retryBtnClick={this.fetchMaterials}/>
            }
            default: {
                throw new Error('Unknown loading state: ' + JSON.stringify(this.state.loadingState));
            }
        }
    }
}
