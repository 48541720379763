import {
    DtoGroupSchedule
} from "../../../../../components/StonlineApiClient/ApiDto/Response/StudentAgreements/GetAdditionalList/DtoGroupSchedule";
import {FormInstance} from "antd";
import React, {useMemo} from "react";
import styled from "styled-components";
import {t, Trans} from "@lingui/macro";
import {FormItem, FormUseWatch} from "../../../../components/Ui/Elements/Form";
import moment from "moment";
import {Checkbox} from "../../../../components/Ui/Elements/Checkbox";
import {container} from "tsyringe";
import {IGroupScheduleReader} from "../../../../components/GroupScheduleReader/IGroupScheduleReader";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {WeekDayNameByNumber, WeekDayType} from "../../../../components/Ui/Elements/TextPresenter/WeekDayNameByNumber";
import {TimePicker} from "../../../../components/Ui/Elements/TimePicker";
import {
    ScheduleFormFieldsEnum as ScheduleFormFields
} from "../../../../components/GroupScheduleReader/ScheduleFormFieldsEnum";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DayGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TimesGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-left: 25px;
  margin-bottom: 20px;

  @media (${({theme}) => theme.media.small}) {
    flex-direction: row;
  }
`;

const DateInputFormItem = styled(FormItem)`
  width: 200px;
`;


interface ScheduleFormProps {
    scheduleData: DtoGroupSchedule | null;
    form: FormInstance;
    className?: string;
}

export const ScheduleForm: React.FC<ScheduleFormProps> = (
    {scheduleData, form, className}
) => {

    const scheduleReader = useMemo(() => container.resolve<IGroupScheduleReader>(DiTokens.GROUP_SCHEDULE_READER), []);

    const mondayEnabledValue = FormUseWatch(ScheduleFormFields.MONDAY_ENABLED, form);
    const tuesdayEnabledValue = FormUseWatch(ScheduleFormFields.TUESDAY_ENABLED, form);
    const wednesdayEnabledValue = FormUseWatch(ScheduleFormFields.WEDNESDAY_ENABLED, form);
    const thursdayEnabledValue = FormUseWatch(ScheduleFormFields.THURSDAY_ENABLED, form);
    const fridayEnabledValue = FormUseWatch(ScheduleFormFields.FRIDAY_ENABLED, form);
    const saturdayEnabledValue = FormUseWatch(ScheduleFormFields.SATURDAY_ENABLED, form);
    const sundayEnabledValue = FormUseWatch(ScheduleFormFields.SUNDAY_ENABLED, form);

    const scheduleValues = useMemo(() => {
        return (scheduleData) ? scheduleReader.readSchedule(scheduleData) : [];
    }, [scheduleData, scheduleReader]);

    const enabledValues = useMemo<{ [id: number]: any }>(() => {
        return {
            1: mondayEnabledValue,
            2: tuesdayEnabledValue,
            3: wednesdayEnabledValue,
            4: thursdayEnabledValue,
            5: fridayEnabledValue,
            6: saturdayEnabledValue,
            7: sundayEnabledValue
        }
    }, [fridayEnabledValue, mondayEnabledValue, saturdayEnabledValue, sundayEnabledValue, thursdayEnabledValue, tuesdayEnabledValue, wednesdayEnabledValue]);

    const items = useMemo<JSX.Element[]>(() => {
        const result: JSX.Element[] = [];

        for (let i = 1; i <= 7; i++) {
            const fieldNames = scheduleReader.getFormNames(i);
            const dayNum = (i === 7) ? 0 : i;

            const initialValue = scheduleValues.find(item => {
                return item.weekDayNum === dayNum;
            }) ?? null;

            result.push(
                <DayGroup key={i}>
                    <FormItem
                        name={fieldNames.ENABLED}
                        initialValue={(initialValue) ? !!initialValue.weekDayNum : false}
                        valuePropName={'checked'}
                    >
                        <Checkbox>
                            <WeekDayNameByNumber weekDayNumber={dayNum}
                                                 nameType={WeekDayType.NOMINATIVE}/>
                        </Checkbox>
                    </FormItem>
                    {
                        (enabledValues[i] === true)
                        && <TimesGroup>
                            <DateInputFormItem
                                name={fieldNames.TIME_START}
                                initialValue={(initialValue) ? moment(initialValue.timeStart, "HH:mm") : null}
                                label={<Trans>Начало урока</Trans>}
                                rules={[
                                    {
                                        required: true,
                                        message: t`Укажите время начала`,
                                    }
                                ]}
                            >
                                <TimePicker/>
                            </DateInputFormItem>

                            <DateInputFormItem
                                name={fieldNames.TIME_END}
                                initialValue={(initialValue) ? moment(initialValue.timeEnd, "HH:mm") : null}
                                label={<Trans>Завершение урока</Trans>}
                                rules={[
                                    {
                                        required: true,
                                        message: t`Укажите время завершения`,
                                    }
                                ]}
                            >
                                <TimePicker/>
                            </DateInputFormItem>
                        </TimesGroup>
                    }
                </DayGroup>
            );
        }

        return result;
    }, [enabledValues, scheduleValues, scheduleReader]);

    return <Wrapper className={className}>
        {items}
    </Wrapper>
}