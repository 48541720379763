import React, {useCallback, useEffect, useMemo, useState} from "react";
import {RoutesList} from "../../../../RoutesList";
import {t, Trans} from "@lingui/macro";
import {PageWrapper} from "../../../../components/PageWrapper";
import {PageLoadingStateEnum} from "../../../../../enums/PageLoadingStateEnum";
import {container} from "tsyringe";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {IHttpApiClient} from "../../../../../components/HttpApiClient/IHttpApiClient";
import {useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../../store/app/selector";
import {useParams} from "react-router-dom";
import {NoConnection} from "../../../../../components/HttpApiClient/Exception/NoConnection";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";
import {
    DtoSelfStudyTrackAdminOverviewResponse
} from "../../../../../components/HttpApiClient/ApiDto/Response/SelfStudyTrack/DtoSelfStudyTrackAdminOverviewResponse";
import {DefaultLoader} from "../../../../components/DefaultLoader";
import {ErrorLoadingContent} from "../../../../components/Ui/Elements/ErrorLoadingContent";
import {AdminViewContextProvider, IAdminViewContext} from "./Context";
import {LinksBlock} from "./LinksBlock";
import {Widgets} from "./Widgets";
import {Subscribers} from "./Subscribers";
import {PageSubtitle} from "../../../../styles/global-elements";

export const ViewTrack: React.FC = () => {
    const routerParams = useParams();
    const sessionToken = useSelector(sessionTokenSelector);

    const [loadingState, setLoadingState] = useState<PageLoadingStateEnum>(PageLoadingStateEnum.NOT_INIT);
    const [overviewData, setOverviewData] = useState<DtoSelfStudyTrackAdminOverviewResponse | null>(null);

    const providerContent = useMemo<IAdminViewContext|null>(() => {
        if (overviewData === null) {
            return null;
        }
        
        return {
            trackId: overviewData.trackId,
            trackName: overviewData.trackName,
            trackSlug: overviewData.trackSlug,
            totalEpisodesCount: overviewData.totalEpisodesCount,
            summaryResponse: overviewData.summaryResponse,
            subscribersList: overviewData.subscribers
        }
    }, [overviewData]);
    
    const fetchData = useCallback(async (trackId: string) => {
        if (sessionToken === null) {
            setLoadingState(PageLoadingStateEnum.ERROR);

            return;
        }

        const logger = container.resolve<ILogger>(DiTokens.LOGGER);
        const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);

        try {
            const result = await httpApiClient.selfStudyTrackAdminOverview(
                sessionToken,
                trackId
            );

            setOverviewData(result.data);
            setLoadingState(PageLoadingStateEnum.SUCCESS);
        } catch (e) {
            if (e instanceof NoConnection) {
                setLoadingState(PageLoadingStateEnum.ERROR);

                return;
            }

            logger.error(
                LoggerSectionsEnum.SELF_STUDY_ADMIN_OVERVIEW,
                'Overview loading error. Track id ' + trackId,
                e
            );
            setLoadingState(PageLoadingStateEnum.ERROR);
        }

    }, [sessionToken]);

    useEffect(() => {
        if (routerParams.trackId === undefined) {
            setLoadingState(PageLoadingStateEnum.ERROR);
        } else {
            fetchData(routerParams.trackId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loadingState === PageLoadingStateEnum.NOT_INIT || loadingState === PageLoadingStateEnum.LOADING) {
        return <DefaultLoader/>
    }

    if (loadingState === PageLoadingStateEnum.ERROR || !providerContent) {
        return <ErrorLoadingContent retryBtnClick={() => {
            if (routerParams.trackId) {
                fetchData(routerParams.trackId).then();
            }
        }}/>
    }

    return <AdminViewContextProvider value={providerContent}>
        <PageWrapper
            pageTitle={(overviewData === null) ? t`Обзор трека` : overviewData.trackName}
            contentFullWidth={true}
            pageContent={<div>
                <Widgets/>
                <PageSubtitle><Trans>Участники</Trans></PageSubtitle>
                <Subscribers/>
                <LinksBlock/>
            </div>}
            rightBlockContent={null}
            backLink={{
                href: RoutesList.TEACHER_SELF_STUDY_TRACKS,
                title: t`Обратно, к списку треков`
            }}>
        </PageWrapper>
    </AdminViewContextProvider>
}