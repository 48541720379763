import React from "react";
import {PageTitle, RegularText} from "../../../styles/global-elements";
import {Trans} from "@lingui/macro";
import styled from "styled-components";
import {DownloadBtn} from "./DownloadBtn";

const PageWrapper = styled.div``;

const FeaturesList = styled.ul`
  margin: 1em 0;
  padding-left: 17px;
`;

const DownloadBlock = styled.div`
`;

const AdditionalText = styled(RegularText)`
    font-size: 13px;
    color: ${({theme}) => theme.colors.textSecondary};
`;

export const AboutWinApp: React.FC = () => {
    return <div>
        <PageTitle><Trans>Дополнительные инструменты для учёта уже доступны</Trans></PageTitle>
        <PageWrapper>

            <FeaturesList>
                <li><RegularText><Trans>быстрая генерация документов по шаблонам</Trans></RegularText></li>
                <li><RegularText><Trans>импорт банковской выписки для распределения оплат учеников</Trans></RegularText>
                </li>
                <li><RegularText><Trans>настройка уровня доступа сотрудников</Trans></RegularText></li>
                <li><RegularText><Trans>расширенное управление расписанием и переносами занятий</Trans></RegularText>
                </li>
                <li><RegularText><Trans>SMS рассылки</Trans></RegularText></li>
                <li><RegularText><Trans>гибкая система начислений для сотрудников</Trans></RegularText></li>
            </FeaturesList>
            <br/>
            <RegularText><Trans>Всё это есть в программе для Windows и включено в ваш тариф.</Trans></RegularText>
            <br/>
            <br/>
            <DownloadBlock>
                <DownloadBtn/>
            </DownloadBlock>

            <br/>
            <br/>
                <AdditionalText><Trans>* Программа запускается без установки. Логин/пароль для входа в программу те же, что и для входа в «Мои
                    занятия».</Trans></AdditionalText>
            <br/>
        </PageWrapper>
    </div>
}
