import {applyMiddleware, combineReducers, createStore, Store} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import {reduxPersistConfig} from "../config/ReduxPersistConfig";
import {persistReducer, persistStore} from 'redux-persist';
import {AppState} from "./app/type";
import {LayoutState} from "./layout/type";
import {UserState} from "./user/type";
import {I18nState} from "./i18n/type";
import {appReducer} from "./app/reducer";
import {layoutReducer} from "./layout/reducer";
import {userReducer} from "./user/reducer";
import {i18nReducer} from "./i18n/reducer";
import createSagaMiddleware from 'redux-saga';
import {rootSaga} from "../saga";
import {CommonPersistedState} from "./commonPersisted/type";
import {commonPersistedReducer} from "./commonPersisted/reducer";
import {uploadQueueReducer} from "./uploadQueue/reducer";
import {fileFormatsRepoReducer} from "./fileFormatsRepo/reducer";
import {Persistor} from "redux-persist/es/types";
import {UploadQueueState} from "./uploadQueue/type";
import {FileFormatsRepoState} from "./fileFormatsRepo/type";
import {StreamEventState} from "./streamEvent/type";
import {streamEventReducer} from "./streamEvent/reducer";
import {UserOnlineStateSubscriptionsState} from "./userOnlineStateSubscriptions/type";
import {userOnlineStateSubscriptionsReducer} from "./userOnlineStateSubscriptions/reducer";
import {LessonRoomState} from "./lessonRoom/type";
import {lessonRoomReducer} from "./lessonRoom/reducer";
import {LessonRoomsState} from "./lessonRooms/type";
import {lessonRoomsReducer} from "./lessonRooms/reducer";
import {LessonMaterialsState} from "./lessonMaterials/type";
import {lessonMaterialsReducer} from "./lessonMaterials/reducer";
import {SlidesWorkDataState} from "./slidesWorkData/type";
import {slidesWorkDataReducer} from "./slidesWorkData/reducer";
import {SlidesWorkDataSaveQueueState} from "./slidesWorkDataSaveQueue/type";
import {slidesWorkDataSaveQueueReducer} from "./slidesWorkDataSaveQueue/reducer";
import {container} from "tsyringe";
import {ICustomStorage} from "../components/CustomStorage/ICustomStorage";
import {DiTokens} from "../di-factory/DiTokens";
import {VcsStateType} from "./vcsState/types";
import {vcsStateReducer} from "./vcsState/reducer";
import {ServiceWorkerDataState} from "./serviceWorker/type";
import {serviceWorkerReducer} from "./serviceWorker/reducer";

export interface ApplicationState {
    app: AppState;
    layout: LayoutState;
    user: UserState;
    i18n: I18nState;
    streamEvent: StreamEventState;
    userOnlineStateSubscriptions: UserOnlineStateSubscriptionsState;
    commonPersisted: CommonPersistedState;
    uploadQueue: UploadQueueState;
    fileFormatsRepo: FileFormatsRepoState;
    lessonRoom: LessonRoomState;
    lessonRooms: LessonRoomsState;
    lessonMaterials: LessonMaterialsState;
    slidesWorkData: SlidesWorkDataState;
    slidesWorkDataSaveQueue: SlidesWorkDataSaveQueueState;
    vcsState: VcsStateType;
    serviceWorker: ServiceWorkerDataState;
}

export const createRootReducer = () => {
    return combineReducers({
        app: appReducer,
        layout: layoutReducer,
        user: userReducer,
        i18n: i18nReducer,
        streamEvent: streamEventReducer,
        userOnlineStateSubscriptions: userOnlineStateSubscriptionsReducer,
        commonPersisted: commonPersistedReducer,
        uploadQueue: uploadQueueReducer,
        fileFormatsRepo: fileFormatsRepoReducer,
        lessonRoom: lessonRoomReducer,
        lessonRooms: lessonRoomsReducer,
        lessonMaterials: lessonMaterialsReducer,
        slidesWorkData: slidesWorkDataReducer,
        slidesWorkDataSaveQueue: slidesWorkDataSaveQueueReducer,
        vcsState: vcsStateReducer,
        serviceWorker: serviceWorkerReducer
    })
};

export function configureStore(): { persistor: Persistor; store: Store<ApplicationState> } {
    const composeEnhancers = composeWithDevTools({trace: process.env.NODE_ENV === "development"});

    const sagaMiddleware = createSagaMiddleware();

    const customStorage = container.resolve<ICustomStorage>(DiTokens.CUSTOM_STORAGE);

    const config = reduxPersistConfig;

    config.storage = customStorage;

    const store = createStore(
        persistReducer(config, createRootReducer()),
        undefined,
        composeEnhancers(applyMiddleware(sagaMiddleware))
    );

    const persistor = persistStore(store);

    sagaMiddleware.run(rootSaga);

    return {
        persistor,
        store
    };
}
