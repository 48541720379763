import React from "react";
import {RegularText} from "../../styles/global-elements";
import {Trans} from "@lingui/macro";
import {ChromeManual} from "./ChromeManual";

export const UnknownManual: React.FC = () => {
    return <div>
        <RegularText><Trans>Не удалось найти инструкцию для вашего браузера. Попробуйте использовать инструкцию для
            Chrome:</Trans></RegularText>
        <br/>
        <ChromeManual/>
    </div>
}