import {put, putResolve, select, takeEvery} from 'redux-saga/effects';
import {AppActionTypes, AppInitializationStateTypes} from "../../store/app/type";
import * as AppActionCreators from "../../store/app/actions";
import {collectUtm} from "../../store/app/actions";
import * as i18nActionCreators from "../../store/i18n/actions";
import * as layoutActionCreators from "../../store/layout/actions";
import {container} from "tsyringe";
import {ILogger} from "../../components/Logger/ILogger";
import {DiTokens} from "../../di-factory/DiTokens";
import {ApplicationState} from "../../store";
import {CommonPersistedState} from "../../store/commonPersisted/type";
import {AvailableLocales, LocaleEnum} from "../../store/i18n/type";
import {availableThemes, ThemeEnum} from "../../services/theme/ThemeEnum";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";
import * as UserActionCreators from "../../store/user/actions";
import {UserProfileLoadState} from "../../store/user/type";
import {ISoundPlayer} from "../../components/SoundPlayer/ISoundPlayer";
import {IWsDisconnector} from "../../components/WsDisconnector/IWsDisconnector";
import {matchPath} from "react-router";
import {RoutesList} from "../../views/RoutesList";

/**
 * Сага отвечает за запуск инициализации приложения и всех процессов, связанных с ним.
 */
export function* watchStartAppInitialization() {
    yield takeEvery(
        AppActionTypes.START_APP_INITIALIZATION,
        initializeApplication
    );
}

const getPersistedState = (state: ApplicationState) => state.commonPersisted;

function* initializeApplication() {
    const logger: ILogger = container.resolve<ILogger>(DiTokens.LOGGER);

    logger.info(LoggerSectionsEnum.START_APPLICATION, 'Start app init...');

    // Первым делом - сбор UTM меток, чтобы в дальнейшем их не потерять
    yield putResolve(collectUtm());

    // Регистрация выключателя ws соединения
    const wsDisconnector = container.resolve<IWsDisconnector>(DiTokens.WS_DISCONNECTOR);

    wsDisconnector.startObserving();

    yield put(AppActionCreators.setAppInitializationState(AppInitializationStateTypes.IN_PROGRESS));

    try {
        // Загружаем данные
        const persistedData = (yield select(getPersistedState)) as CommonPersistedState;

        const isInternationalDomain = (window.location.hostname).includes('.com');

        // Локаль
        let localeForSelect = (isInternationalDomain) ? LocaleEnum.EN : LocaleEnum.RU;

        if (
            (persistedData.locale !== null)
            && (AvailableLocales.includes(persistedData.locale))
        ) {
            localeForSelect = persistedData.locale;
        }

        yield put(i18nActionCreators.selectLocale(localeForSelect));

        // Тема UI
        let themeForSelect = ThemeEnum.light;

        if (
            (persistedData.themeName !== null)
            && (availableThemes.includes(persistedData.themeName))
        ) {
            themeForSelect = persistedData.themeName;
        }

        yield put(layoutActionCreators.changeTheme(themeForSelect));

        yield put(AppActionCreators.setAppInitializationState(AppInitializationStateTypes.COMPLETED));

        // Проверим: не находимся ли мы на странице авторизации по токену
        // (в этом случае не рассматриваем текущую авторизацию)
        const currentPath = document.location.pathname;

        const matchLoginViaToken = matchPath({path: RoutesList.LOGIN_VIA_TOKEN}, currentPath);
        const matchTeacherLoginViaToken = matchPath({path: RoutesList.TEACHER_LOGIN_VIA_TOKEN}, currentPath);
        const matchStudentDemoAuth = matchPath({path: RoutesList.STUDENT_DEMO}, currentPath);

        if ((matchLoginViaToken !== null) || (matchTeacherLoginViaToken !== null) || (matchStudentDemoAuth !== null)) {
            // Мы на странице авторзации по токену. Действуем так,
            // будто у нас нет токена - страница попытается авторизоваться под новым пользователем.
            yield put(layoutActionCreators.setSplashScreenVisible(false));
            yield put(UserActionCreators.setUserProfileLoadState(UserProfileLoadState.NOT_AUTHORIZED));
        } else {
            // Пытаемся взять из common сохранённый токен. Если есть, то стартуем авторизацию.
            if (persistedData.authToken && persistedData.profileType) {
                yield putResolve(UserActionCreators.setUserProfileType(persistedData.profileType));
                yield putResolve(UserActionCreators.setUserSessionToken(persistedData.authToken));
                yield put(UserActionCreators.startLoadingUserProfile());
            } else {
                yield put(layoutActionCreators.setSplashScreenVisible(false));
                yield put(UserActionCreators.setUserProfileLoadState(UserProfileLoadState.NOT_AUTHORIZED));
            }
        }

        let soundPlayer = container.resolve<ISoundPlayer>(DiTokens.SOUND_PLAYER);

        soundPlayer.loadSounds();
    } catch (e) {

    }
}
