import React from "react";
import {ActionsList, ActionsListItem, InlineIconCss} from "./styles";
import {ReactComponent as ChromeSettingsIcon} from "../Ui/Svg/ChromeSettingsNoBorder.svg";
import {ReactComponent as MessageNoBorderIcon} from "../Ui/Svg/MessageNoBorder.svg";
import {ReactComponent as CrossNoBorderIcon} from "../Ui/Svg/CrossNoBorder.svg";
import {Trans} from "@lingui/macro";
import styled from "styled-components";

const ChromeSettingsIconStyled = styled(ChromeSettingsIcon)`
    ${InlineIconCss};
`;

const MessageNoBorderIconStyled = styled(MessageNoBorderIcon)`
    ${InlineIconCss};
`;

const CrossNoBorderIconStyled = styled(CrossNoBorderIcon)`
    ${InlineIconCss};
`;

export const FirefoxManual: React.FC = () => {
    return <ActionsList>
        <ActionsListItem>
            <Trans>
                Поищите значок <ChromeSettingsIconStyled/> в адресной строке браузера (в верхней части экрана).
            </Trans>
        </ActionsListItem>
        <ActionsListItem>
            <Trans>
                Для пункта «<MessageNoBorderIconStyled/> Разрешить уведомления» нажмите на «Заблокировано <CrossNoBorderIconStyled/>».
            </Trans>
        </ActionsListItem>
    </ActionsList>
}