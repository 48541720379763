import {IDeviceDetector} from "./IDeviceDetector";
import DeviceDetectorJs, {DeviceDetectorResult} from "device-detector-js";
import {SaveDeviceInfoRequestDto} from "../HttpApiClient/ApiDto/Request/User/SaveDeviceInfoRequestDto";
import {IDateHelperService} from "../../services/date-helper/IDateHelperService";
import {ILogger} from "../Logger/ILogger";
import {LoggerSectionsEnum} from "../Logger/LoggerSectionsEnum";

export class DeviceDetector implements IDeviceDetector {
    protected logger: ILogger;
    protected dateHelperService: IDateHelperService;

    constructor(logger: ILogger, dateHelperService: IDateHelperService) {
        this.logger = logger;
        this.dateHelperService = dateHelperService;
    }

    /**
     * @inheritDoc
     */
    isIPhone(): boolean {
        const info = this.getInfo();

        if (info.device === null) {
            return false;
        }

        return info.device.brand === 'Apple';
    }

    /**
     * @inheritDoc
     */
    isMobileSafari(): boolean {
        const info = this.getInfo();

        if (info.client === null) {
            return false;
        }

        return info.client.name === 'Mobile Safari';
    }

    isSafari(): boolean {
        const info = this.getInfo();

        if (info.client === null) {
            return false;
        }

        return info.client.name === 'Mobile Safari';
    }

    isChrome(): boolean {
        const info = this.getInfo();

        if (info.client === null) {
            return false;
        }

        return info.client.name.search('Chrome') > -1;
    }

    isEdge(): boolean {
        const info = this.getInfo();

        if (info.client === null) {
            return false;
        }

        return info.client.name.search('Edge') > -1;
    }

    isYandexBrowser(): boolean {
        const info = this.getInfo();

        if (info.client === null) {
            return false;
        }

        return info.client.name === 'Yandex Browser';
    }

    isOpera(): boolean {
        const info = this.getInfo();

        if (info.client === null) {
            return false;
        }

        return info.client.name === 'Opera';
    }

    isFirefox(): boolean {
        const info = this.getInfo();

        if (info.client === null) {
            return false;
        }

        return info.client.name.search('Firefox') > -1;
    }

    isDesktop(): boolean {
        const info = this.getInfo();

        return !!(info.device && info.device.type === "desktop");
    }

    webPushIsSupported(): boolean {
        if ('PushManager' in window) {
            return true;
        }

        if (!this.isMobileSafari()) {
            return false;
        }

        // Если iOS Safari, то проверим версию - там PushManager недоступен,
        // но есть возможность получать WebPush
        const info = this.getInfo();

        if (!info.os || !info.os.version) {
            return false;
        }

        try {
            const versionNumber = parseFloat(info.os.version);

            return versionNumber >= 16.4;
        } catch (e) {
            this.logger.warning(
                LoggerSectionsEnum.DEVICE_DETECTOR,
                'Cannot parse ios version from os data: ',
                info.os
            );

            return false;
        }
    }

    collectForApi(): SaveDeviceInfoRequestDto {
        const info = this.getInfo();

        const model = new SaveDeviceInfoRequestDto();

        model.timezoneName = this.dateHelperService.getCurrentDeviceTimezoneName();
        model.deviceType = (info.device && info.device.type) ? info.device.type : null;
        model.deviceBrand = (info.device && info.device.brand) ? info.device.brand : null;
        model.osName = (info.os && info.os.name) ? info.os.name : null;
        model.osVersion = (info.os && info.os.version) ? info.os.version : null;
        model.browserType = (info.client && info.client.type) ? info.client.type : null;
        model.browserName = (info.client && info.client.name) ? info.client.name : null;
        model.browserVersion = (info.client && info.client.version) ? info.client.version : null;

        return model;
    }

    public getInfo(): DeviceDetectorResult {
        const deviceDetector = new DeviceDetectorJs();

        return deviceDetector.parse(navigator.userAgent);
    }
}
