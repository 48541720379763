import {put, putResolve, takeEvery} from 'redux-saga/effects';
import {StreamEventActionTypes} from "../../store/streamEvent/type";
import {setAppIsReadyToHandleItemsValue, startHandleEventCycle} from "../../store/streamEvent/actions";
import {ILogger} from "../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";
import {container} from "tsyringe";
import {DiTokens} from "../../di-factory/DiTokens";

/**
 * Сага отвечает за запуск обработки сообщений потока событий
 */
export function* watchStartHandleEvent() {
    yield takeEvery(
        StreamEventActionTypes.START_HANDLING_EVENTS,
        startHandleEvent
    );
}

function* startHandleEvent() {
    let logger: ILogger = container.resolve<ILogger>(DiTokens.LOGGER);

    logger.debug(LoggerSectionsEnum.WS_STREAM_EVENT, 'Streaming events processing started.');

    yield putResolve(setAppIsReadyToHandleItemsValue(true));

    yield put(startHandleEventCycle());
}
