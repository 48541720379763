import storage from 'redux-persist/lib/storage';
import {createMigrate, createTransform} from "redux-persist";
import {QueueSendCycleStateEnum, SlidesWorkDataSaveQueueState} from "../store/slidesWorkDataSaveQueue/type";
import {StorageKeysEnum} from "../enums/StorageKeysEnum";
import {CommonPersistedState} from "../store/commonPersisted/type";
import {SlidesWorkDataState} from "../store/slidesWorkData/type";
import {PersistedState} from "redux-persist/es/types";
import {muteConfigInitialState} from "../store/commonPersisted/initialState";

const migrations = {
    2: (state: PersistedState): PersistedState => {
        const data = state as unknown as {commonPersisted: CommonPersistedState, slidesWorkData: SlidesWorkDataState};

        if (data.commonPersisted.muteConfig === undefined) {
            data.commonPersisted.muteConfig = muteConfigInitialState;
        }

        return data as unknown as PersistedState;
    }
}

export const reduxPersistConfig = {
    key: StorageKeysEnum.REDUX_PERSIST_KEY, //'persistent-store',
    keyPrefix: StorageKeysEnum.REDUX_PERSIST_PREFIX, //'my-lesson:',
    version: 2,
    storage: storage,
    migrate: createMigrate(migrations, {debug: true}),
    whitelist: ['commonPersisted', 'slidesWorkDataSaveQueue'],// Перечислить ветки, когда потребуется
    throttle: 500,
    debug: false,
    serialize: true,
    transforms: [
        createTransform(
            undefined,
            (outboundState: SlidesWorkDataSaveQueueState, _key) => {
                if (outboundState.sendCycleState === QueueSendCycleStateEnum.WORKING) {
                    outboundState.sendCycleState = QueueSendCycleStateEnum.STOPPED;
                }

                return outboundState;
            },
            {
                whitelist: ['slidesWorkDataSaveQueue']
            }
        )
    ]
};
