import React, {useCallback, useContext, useMemo, useRef, useState} from "react";
import {BtnDefaultProps} from "./BtnDefaultProps";
import {BtnStyleEnum} from "../../../../../../components/Ui/Elements/Button";
import {ReactComponent as PhoneNoPaddingIcon} from "../../../../../../components/Ui/Svg/PhoneNoPadding.svg";
import {ButtonStyled} from "./ButtonStyled";
import styled from "styled-components";
import {container} from "tsyringe";
import {
    IPushNotificationSubscriptionService
} from "../../../../../../../services/push-notifications/IPushNotificationSubscriptionService";
import {DiTokens} from "../../../../../../../di-factory/DiTokens";
import {ILogger} from "../../../../../../../components/Logger/ILogger";
import {useDispatch, useSelector} from "react-redux";
import {sessionTokenSelector} from "../../../../../../../store/app/selector";
import * as ServiceWorkerActions from "../../../../../../../store/serviceWorker/actions";
import * as AppActions from "../../../../../../../store/app/actions";
import {NotificationTypesEnum, openNotification} from "../../../../../../components/Ui/Elements/Notification";
import {t} from "@lingui/macro";
import {LoggerSectionsEnum} from "../../../../../../../components/Logger/LoggerSectionsEnum";
import {WebPushEnableManualModal} from "../../../../../../components/WebPushEnableManualModal";
import {PopupActions as ReactjsPopupActions} from "reactjs-popup/dist/types";
import {
    pushNotificationsSupportedSelector,
    pushSubscriptionStateSelector
} from "../../../../../../../store/serviceWorker/selector";
import {INotificationsSectionContext, NotificationsSectionContext} from "../Context";
import {IHttpApiClient} from "../../../../../../../components/HttpApiClient/IHttpApiClient";
import {NoConnection} from "../../../../../../../components/HttpApiClient/Exception/NoConnection";

const PhoneNoPaddingIconStyled = styled(PhoneNoPaddingIcon)`
    width: 20px;
    height: 20px;
`;

export const WebPushBtn: React.FC<BtnDefaultProps> = (
    {
        groupId,
        channelDto
    }
) => {
    const context = useContext<INotificationsSectionContext>(NotificationsSectionContext);

    const dispatch = useDispatch();
    const apiToken = useSelector(sessionTokenSelector);
    const pushSubscriptionState = useSelector(pushSubscriptionStateSelector);
    const pushSubscriptionSupported = useSelector(pushNotificationsSupportedSelector);

    const [loading, setLoading] = useState<boolean>(false);

    const modalRef = useRef<ReactjsPopupActions>(null);

    const buttonIsActive = useMemo<boolean>(() => {
        return !!(channelDto.enabled && channelDto.ready && pushSubscriptionState);
    }, [channelDto.enabled, channelDto.ready, pushSubscriptionState]);

    const turnOnNotifications = useCallback(async () => {
        if (!apiToken) {
            return;
        }

        const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        const pushSubscriptionService = container.resolve<IPushNotificationSubscriptionService>(DiTokens.PUSH_NOTIFICATION_SUBSCRIPTION_SERVICE);
        const logger = container.resolve<ILogger>(DiTokens.LOGGER);

        setLoading(true);

        // Если push не подключены в браузере
        if (!pushSubscriptionState) {
            let subscription: PushSubscription | null = null;

            try {
                subscription = await pushSubscriptionService.subscribe(true);
            } catch (error) {
                logger.warning(
                    LoggerSectionsEnum.PUSH_NOTIFICATION_SUBSCRIBE,
                    'Subscription error: ',
                    error
                );

                modalRef.current?.open();
                setLoading(false);

                return;
            }

            try {
                await pushSubscriptionService.registerSubscription(apiToken, subscription.toJSON());
            } catch (error) {
                logger.error(
                    LoggerSectionsEnum.PUSH_NOTIFICATION_SUBSCRIBE,
                    'Web push subscription registration error: ',
                    error
                );

                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Не удалось выполнить`,
                    t`Попробуйте повторить попытку позднее`
                );

                setLoading(false);

                return;
            }

            dispatch(ServiceWorkerActions.setRegisteredPushTokenHash(pushSubscriptionService.getTokenHash(subscription.toJSON())));
            dispatch(ServiceWorkerActions.setPushSubscriptionState(true));
        }

        // Если уведомления просто не были включены для этого канала
        if (!channelDto.enabled) {
            try {
                await httpApiClient.notificationsSetChannel(
                    apiToken,
                    groupId,
                    channelDto.alias,
                    true
                );
            } catch (e) {
                if (e instanceof NoConnection) {
                    openNotification(
                        NotificationTypesEnum.ERROR,
                        t`Не удалось выполнить`,
                        t`Проверьте соединение с интернетом`
                    );
                } else {
                    logger.error(
                        LoggerSectionsEnum.NOTIFICATION_SETTINGS_LIST,
                        'Web push enable error: ',
                        e
                    );

                    openNotification(
                        NotificationTypesEnum.ERROR,
                        t`Не удалось выполнить`,
                        t`Попробуйте повторить попытку позднее`
                    );
                }

                setLoading(false);

                return;
            }
        }

        try {
            await context.reloadSettings();
        } catch (e) {
            dispatch(AppActions.rebootApp());

            return;
        }

        openNotification(
            NotificationTypesEnum.SUCCESS,
            t`Выполнено`,
            t`Уведомления включены`
        );

        setLoading(false);
    }, [apiToken, channelDto.alias, channelDto.enabled, context, dispatch, groupId, pushSubscriptionState]);

    const turnOffNotifications = useCallback(async () => {
        if (!apiToken) {
            return;
        }

        setLoading(true);

        const httpApiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        const logger = container.resolve<ILogger>(DiTokens.LOGGER);

        try {
            await httpApiClient.notificationsSetChannel(
                apiToken,
                groupId,
                channelDto.alias,
                false
            );
        } catch (e) {
            if (e instanceof NoConnection) {
                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Не удалось выполнить`,
                    t`Проверьте соединение с интернетом`
                );
            } else {
                logger.error(
                    LoggerSectionsEnum.NOTIFICATION_SETTINGS_LIST,
                    'Web push disable error: ',
                    e
                );

                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Не удалось выполнить`,
                    t`Попробуйте повторить попытку позднее`
                );
            }

            return;
        }

        try {
            await context.reloadSettings();
        } catch (e) {
            dispatch(AppActions.rebootApp());

            return;
        }

        setLoading(false);
    }, [apiToken, channelDto.alias, context, dispatch, groupId]);

    const onClick = useCallback(() => {
        if (buttonIsActive) {
            turnOffNotifications();
        } else {
            turnOnNotifications();
        }
    }, [buttonIsActive, turnOffNotifications, turnOnNotifications]);

    if (!pushSubscriptionSupported) {
        return null;
    }

    return <div>
        <WebPushEnableManualModal ref={modalRef}/>
        <ButtonStyled
            loading={loading}
            btnStyle={
                (buttonIsActive) ? BtnStyleEnum.Primary : BtnStyleEnum.Secondary
            }
            onClick={onClick}
            icon={<PhoneNoPaddingIcon className={PhoneNoPaddingIconStyled}/>}
        >
            Push
        </ButtonStyled>
    </div>
}