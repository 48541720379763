import React, {useContext, useMemo} from 'react';
import classNames from "classnames";
import styled from "styled-components";
import {IPlayerContext, PlayerContext} from "../SlidePlayer/PlayerContext";
import {PlayerSlideModel} from "../SlidePlayer/PlayerSlideModel";
import {ReactComponent as CheckSvg} from "../../components/Ui/Svg/CheckBold16.svg";
import {SlideWorkData} from "../../../store/slidesWorkData/type";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../../store";


const SlideNameWrapper = styled.div`
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  opacity: 0.5;
  transition: opacity 0.3s ease;
`;

const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  max-width: 100%;
  min-width: 100%;
  padding: 6px 0;

  font-size: 16px;
  background: ${({theme}) => theme.colors.backgroundPrimary};
  color: ${({theme}) => theme.colors.textPrimary};

  cursor: pointer;
  border-left: 4px solid rgba(0, 0, 0, 0);
  overflow: hidden;

  &:hover ${SlideNameWrapper} {
    opacity: 1;
  }

  &.selected ${SlideNameWrapper} {
    opacity: 1;
  }

  @media (${({theme}) => theme.media.large}) {
    padding: 6px 32px;

    &.selected {
      border-left-color: ${({theme}) => theme.colors.textPrimary};
    }
  }
`;

const SlideNumberText = styled.span``;

const SlideTitleText = styled.span``;

const ScoreValue = styled.div`
  max-width: 3em;
  min-width: 3em;
  font-size: 14px;
  text-align: right;
  padding-right: 10px;
`;

const CompleteCheck = styled(CheckSvg)`
  min-width: 16px;
  max-width: 16px;
  min-height: 16px;
  max-height: 16px;
  margin-top: 2px;
  margin-right: 3px;
  color: ${({theme}) => theme.colors.progressBarRight};
`;

interface SlideListItemProps {
    playerId: string;
    slideId: string;
    slideNumberInList: number;
}

export const SlideListItem: React.FC<SlideListItemProps> = (props) => {
    const {slideId} = props;

    const playerContext = useContext<IPlayerContext>(PlayerContext);
    const {selectedSlideId, setSelectedSlideId, slides} = playerContext;

    const slideWorkDataState = useSelector(({slidesWorkData}: ApplicationState) => slidesWorkData);

    const currentSlideData = useMemo<SlideWorkData | null>(() => {
        const playerIndex = slideWorkDataState.indexByPlayerId[props.playerId];

        if (playerIndex === undefined) {
            return null;
        }

        const slideDataIndex = playerIndex.indexBySlideId[slideId];

        if ((slideDataIndex === undefined) || (slideWorkDataState.slides[slideDataIndex] === undefined)) {
            return null;
        }

        return slideWorkDataState.slides[slideDataIndex];
    }, [props.playerId, slideId, slideWorkDataState.indexByPlayerId, slideWorkDataState.slides]);

    const slideOnClick = (itemId: string) => {
        setSelectedSlideId(itemId);
    }

    const currentSlideModel = useMemo<PlayerSlideModel | null>(() => {
        return (slides?.find((item) => item.tmSlideId === slideId)) ?? null;
    }, [slides, slideId])

    return (currentSlideModel) && (
        <ListItem onClick={() => slideOnClick(slideId)}
                  className={
                      classNames(
                          (slideId === selectedSlideId) && "selected"
                      )}>
            <SlideNameWrapper>
                <SlideNumberText>{props.slideNumberInList.toString(10)}.&nbsp;</SlideNumberText>
                <SlideTitleText>{currentSlideModel.tmSlideName}</SlideTitleText>
            </SlideNameWrapper>
            {
                (currentSlideData) && (currentSlideData.slideCompleted) && <CompleteCheck/>
            }
            {
                (currentSlideData) && (currentSlideData.totalAward > 0) && <ScoreValue>
                    {currentSlideData.totalAward.toString(10)}
                </ScoreValue>
            }
        </ListItem>
    );
};
