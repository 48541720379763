import {Reducer} from "redux";
import {FileFormatsRepoActionTypes, FileFormatsRepoState, FileInfo} from "./type";
import {initialState} from "./initialState";
import produce from "immer";

const reducer: Reducer<FileFormatsRepoState> = produce((draft: FileFormatsRepoState, action) => {
    switch (action.type) {
        case FileFormatsRepoActionTypes.SET_FILE_INFO: {
            const payloadData: FileInfo = action.payload as FileInfo;

            if (draft.indexByFileId[payloadData.id] !== undefined) {
                draft.files[draft.indexByFileId[payloadData.id]] = payloadData;
            } else {
                draft.files.push(payloadData);
                draft.indexByFileId[payloadData.id] = draft.files.length - 1;
            }

            break;
        }
        case FileFormatsRepoActionTypes.REMOVE_FILE_INFO: {
            const objectIndex = draft.indexByFileId[action.payload];

            if (objectIndex === undefined) {
                return;
            }

            if (draft.files[objectIndex] === undefined) {
                delete draft.indexByFileId[action.payload];

                return;
            }

            draft.files = draft.files.filter(item => item.id !== action.payload);

            break;
        }
    }
}, initialState);

export {reducer as fileFormatsRepoReducer}