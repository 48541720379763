import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router";
import {t} from "@lingui/macro";
import {StudentProfileSection} from "./profile";
import {useSelector} from "react-redux";
import {sessionTokenSelector, stTokenSelector} from "../../../../../store/app/selector";
import {container} from "tsyringe";
import {IStonlineApiClient} from "../../../../../components/StonlineApiClient/IStonlineApiClient";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {
    DtoStudentGeneralInfo
} from "../../../../../components/StonlineApiClient/ApiDto/Response/Student/DtoStudentGeneralInfo";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";
import {DefaultLoader} from "../../../../components/DefaultLoader";
import {ErrorLoadingContent} from "../../../../components/Ui/Elements/ErrorLoadingContent";
import {RoutesHelper} from "../../../../../helpers/RoutesHelper";
import {RoutesList} from "../../../../RoutesList";
import {PageSectionsEnum} from "./PageSectionsEnum";
import {StudentPageContextProvider} from "./StudentPageContext";
import {StudentsPaymentsSection} from "./payments";
import {PageWrapper} from "../../../../components/PageWrapper";
import {
    HorizontalNavigationItemType
} from "../../../../components/PageNavigation/HorizontalNavigation/HorizontalNavigationItemType";
import {HorizontalNavigation} from "../../../../components/PageNavigation/HorizontalNavigation";
import {useNavigate} from "react-router-dom";
import {IHttpApiClient} from "../../../../../components/HttpApiClient/IHttpApiClient";
import {
    DtoStudentEntranceInfoResponse
} from "../../../../../components/HttpApiClient/ApiDto/Response/Students/DtoStudentEntranceInfoResponse";
import {RightBlock} from "./right-block";
import {StudentAgreementsSection} from "./agreements";
import {ViewModeEnum} from "../../../../components/PageNavigation/HorizontalNavigation/ViewModeEnum";

enum LoadingState {
    NOT_INIT,
    LOADING,
    SUCCESS,
    ERROR
}

export const StudentInfo: React.FC = () => {
    const {studentId, sectionId} = useParams();
    const navigate = useNavigate();

    const [pageState, setPageState] = useState<LoadingState>(LoadingState.NOT_INIT);
    const [studentGeneralInfo, setStudentGeneralInfo] = useState<DtoStudentGeneralInfo | null>(null);
    const [studentEntranceInfoResponse, setStudentEntranceInfoResponse] = useState<DtoStudentEntranceInfoResponse | null>(null);
    const [abortController, setAbortController] = useState<AbortController | null>(null);

    const stToken = useSelector(stTokenSelector);
    const apiToken = useSelector(sessionTokenSelector);

    const fetchBaseInfo = useCallback(() => {
        const studentIdInt = parseInt(studentId ?? '');

        if ((stToken === null) || (apiToken === null) || (!studentIdInt)) {
            setPageState(LoadingState.ERROR);

            return;
        }

        const stApiClient = container.resolve<IStonlineApiClient>(DiTokens.STONLINE_CLIENT);
        const apiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        const logger = container.resolve<ILogger>(DiTokens.LOGGER);

        if (abortController !== null) {
            abortController.abort();
        }

        const newAbortController = new AbortController();
        setAbortController(newAbortController);

        setPageState(LoadingState.LOADING);

        stApiClient.getStudentProfileGeneralInfo(
            stToken,
            studentIdInt,
            newAbortController
        )
            .then((model) => {
                setStudentGeneralInfo(model.result);

                if (model.result.email) {
                    return apiClient.getStudentEntranceInfo(
                        apiToken,
                        model.result.email
                    );
                }

                return Promise.resolve(null);
            })
            .then((model) => {
                if (model) {
                    setStudentEntranceInfoResponse(model.data);
                } else {
                    setStudentEntranceInfoResponse(null);
                }

                setPageState(LoadingState.SUCCESS);
            })
            .catch((err) => {
                setPageState(LoadingState.ERROR);

                logger.error(LoggerSectionsEnum.STUDENT_PROFILE, err);
            });
    }, [studentId, stToken, apiToken, abortController]);

    const linkToSection = useCallback((sectionId: PageSectionsEnum) => {
        return RoutesHelper.replaceParams(
            RoutesList.TEACHER_STUDENT_PROFILE,
            [
                {
                    key: 'studentId',
                    value: studentId ?? '0'
                },
                {
                    key: 'sectionId',
                    value: sectionId
                }
            ]
        );
    }, [studentId]);

    const onHorizontalNavigationClick = useCallback((itemId: string) => {
        navigate(linkToSection(itemId as unknown as PageSectionsEnum));
    }, [linkToSection, navigate]);

    const horizontalNavigationItems = useMemo<HorizontalNavigationItemType[]>(() => {
        return [
            {
                id: PageSectionsEnum.GENERAL,
                name: t`Профиль`
            },
            {
                id: PageSectionsEnum.EDUCATION,
                name: t`Обучение`
            },
            {
                id: PageSectionsEnum.PAYMENTS,
                name: t`Платежи`
            }
        ]
    }, []);

    const activeSectionId = useMemo<PageSectionsEnum>(() => {
        const allSections = Object.values(PageSectionsEnum) as string[];

        if (allSections.indexOf(sectionId ?? '') > -1) {
            return sectionId as PageSectionsEnum;
        }

        return PageSectionsEnum.GENERAL;
    }, [sectionId]);

    const activeSection = useMemo<JSX.Element | null>(() => {
        switch (activeSectionId) {
            case PageSectionsEnum.GENERAL: {
                return <StudentProfileSection/>;
            }
            case PageSectionsEnum.EDUCATION: {
                return <StudentAgreementsSection/>;
            }
            case PageSectionsEnum.PAYMENTS: {
                return <StudentsPaymentsSection/>;
            }
            default: {
                return null;
            }
        }
    }, [activeSectionId]);

    useEffect(() => {
        if (pageState === LoadingState.NOT_INIT) {
            fetchBaseInfo();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (pageState === LoadingState.NOT_INIT || pageState === LoadingState.LOADING) {
        return <DefaultLoader/>;
    }

    if (
        (pageState === LoadingState.ERROR)
        || (studentGeneralInfo === null)
    ) {
        return <ErrorLoadingContent retryBtnClick={fetchBaseInfo}/>;
    }

    return <StudentPageContextProvider value={{
        studentGeneralInfoDto: studentGeneralInfo,
        setStudentGeneralInfoDto: (dto) => setStudentGeneralInfo(dto),
        setStudentEntranceInfoDto: (dto) => setStudentEntranceInfoResponse(dto),
        studentEntranceInfoResponse: studentEntranceInfoResponse,
        getLinkToSection: linkToSection,
        reloadBaseInfo: fetchBaseInfo
    }}>
        <PageWrapper
            backLink={{
                title: t`К списку учеников`,
                href: RoutesList.TEACHER_ALL_STUDENTS
            }}
            pageTitle={null}
            pageContent={<div>
                <HorizontalNavigation
                    items={horizontalNavigationItems}
                    activeItemId={activeSectionId}
                    onItemChange={onHorizontalNavigationClick}
                    viewMode={ViewModeEnum.BOOKMARKS}
                />
                {activeSection}
            </div>}
            rightBlockContent={
                <RightBlock/>
            }
        />
    </StudentPageContextProvider>
}
