import React from "react";
import {ILogger} from "../../../../../../components/Logger/ILogger";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../../di-factory/DiTokens";
import {LessonPageContext} from "../../LessonPageContext";
import {Dispatch} from "redux";
import {ApplicationState} from "../../../../../../store";
import {connect, ConnectedProps} from "react-redux";
import {IWsApiClient} from "../../../../../../components/WsApiClient/IWsApiClient";
import {ApiMethodEnum} from "../../../../../../components/WsApiClient/ApiMethodEnum";
import {LoggerSectionsEnum} from "../../../../../../components/Logger/LoggerSectionsEnum";
import {WsResponseStatusEnum} from "../../../../../../components/WsApiClient/WsResponseStatusEnum";
import {DefaultLoader} from "../../../../../components/DefaultLoader";
import {ErrorLoadingContent} from "../../../../../components/Ui/Elements/ErrorLoadingContent";
import {WsConnectionStatusEnum} from "../../../../../../components/WsApiClient/WsConnectionStatusEnum";
import {processStudentLessonWorkDataItemsList} from "../../../../../../store/slidesWorkData/actions";
import StudentLessonResultsBySlides from "../LessonResultsMode/StudentResultsBySlides";
import {
    DtoStudentLessonWorkDataItem
} from "../../../../../../components/WsApiClient/ApiDto/Response/Lesson/DtoStudentLessonWorkDataItem";
import {
    DtoGetStudentsLessonWorkDataResult
} from "../../../../../../components/WsApiClient/ApiDto/Response/Lesson/DtoGetStudentsLessonWorkDataResult";
import {StudentSlideViewDetailsType} from "./Common/StudentSlideViewDetailsType";
import StudentSlideView from "./StudentSlideView";

enum PageState {
    NOT_INIT,
    LOADING,
    LOADING_ERROR,
    READY
}

interface LessonResultsModePageProps extends PropsFromRedux {
}

interface LessonResultsModePageState {
    pageState: PageState;
    studentSlideViewData: StudentSlideViewDetailsType | null;
}

class LessonResultsModePage extends React.Component<LessonResultsModePageProps, LessonResultsModePageState> {

    protected logger: ILogger;
    protected wsClient: IWsApiClient;

    static contextType = LessonPageContext;
    context!: React.ContextType<typeof LessonPageContext>;

    constructor(props: Readonly<LessonResultsModePageProps> | LessonResultsModePageProps) {
        super(props);

        this.logger = container.resolve<ILogger>(DiTokens.LOGGER);
        this.wsClient = container.resolve<IWsApiClient>(DiTokens.WS_CLIENT);

        this.state = {
            pageState: PageState.NOT_INIT,
            studentSlideViewData: null
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps: Readonly<LessonResultsModePageProps>, prevState: Readonly<LessonResultsModePageState>, snapshot?: any) {
        const wsConnectedFromNow = this.props.wsIsConnected && !prevProps.wsIsConnected;

        if (wsConnectedFromNow) {
            this.fetchData();
        }
    }

    protected fetchData() {
        if (this.props.apiToken === null) {
            return;
        }

        const lessonRoomId = this.context.lessonRoomId;

        if (lessonRoomId === null) {
            this.setState(() => {
                return {
                    pageState: PageState.LOADING_ERROR
                }
            });

            return;
        }

        this.wsClient.queryAsPromise(
            ApiMethodEnum.HOMEWORK_TEACHER_GET_STUDENTS_LESSON_WORK_SUMMARY,
            {
                lessonId: lessonRoomId
            },
            DtoGetStudentsLessonWorkDataResult
        )
            .then((data) => {
                if (data.response.status !== WsResponseStatusEnum.OK) {
                    throw new Error(
                        `Response status is ${data.response.status}, message: ${JSON.stringify(data.response.errors)}`
                    );
                }

                this.props.processStudentLessonWorkDataItemsList(lessonRoomId, data.response.result.list);

                this.setState(() => {
                    return {
                        pageState: PageState.READY,
                    }
                });

            })
            .catch((err) => {
                this.logger.error(LoggerSectionsEnum.HOMEWORK_ROOM, 'Error on load students lesson work results: ', err);
            });
    }

    /**
     * Перейти в режим просмотра работы ученика над слайдом
     *
     * @param data
     * @protected
     */
    protected openStudentSlideView(data: StudentSlideViewDetailsType) {
        this.setState(() => {
            return {
                studentSlideViewData: data
            }
        });
    }

    protected closeStudentSlideView() {
        this.setState(() => {
            return {
                studentSlideViewData: null
            }
        })
    }

    protected content() {
        switch (this.state.pageState) {
            case PageState.LOADING: {
                return <DefaultLoader/>;
            }
            case PageState.LOADING_ERROR: {
                return <ErrorLoadingContent retryBtnClick={() => this.fetchData()}/>;
            }
            case PageState.READY: {
                if (this.state.studentSlideViewData === null) {
                    return <StudentLessonResultsBySlides
                        openStudentSlideViewProc={(data) => this.openStudentSlideView(data)}/>

                }

                return <StudentSlideView data={this.state.studentSlideViewData}
                                         openStudentSlideViewProc={(data) => this.openStudentSlideView(data)}
                                         closeStudentSlideView={() => this.closeStudentSlideView()}
                />;
            }
        }

        return null;
    }

    render() {
        return <div>
            {this.content()}
        </div>
    }
}


const mapDispatchToProps = (dispatch: Dispatch) => ({
    processStudentLessonWorkDataItemsList: (lessonId: string, items: DtoStudentLessonWorkDataItem[]) =>
        dispatch(processStudentLessonWorkDataItemsList({lessonId, items}))
});

const mapStateToProps = ({user, app}: ApplicationState) => ({
    userId: (user.profileData?.id) ?? null,
    stToken: user.stToken,
    apiToken: user.sessionToken,
    wsIsConnected: app.wsConnectionStatus === WsConnectionStatusEnum.AUTHORIZED
});

const connector = connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(LessonResultsModePage);
