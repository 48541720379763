import React, {useEffect, useState} from "react";
import {VisibilityContext} from "react-horizontal-scrolling-menu";
import {HorizontalNavigationItemType} from "./HorizontalNavigationItemType";
import styled from "styled-components";
import {RegularTextCss} from "../../../styles/global-elements";
import classNames from "classnames";
import {ScrollVisibilityApiType} from "./ScrollVisibilityApiType";
import {ViewModeEnum} from "./ViewModeEnum";
import {ReactComponent as ArrowRightIcon} from "../../Ui/Svg/ArrowRightRounded16.svg";

const Label = styled.div`
  ${RegularTextCss};

  padding-bottom: 8px;
  border-bottom: 3px solid rgba(0, 0, 0, 0);
  color: ${({theme}) => theme.colors.textSecondary};
  cursor: pointer;
  white-space: nowrap;
`;

const ArrowWrapper = styled.div`
  padding-top: 5px;
  color: ${({theme}) => theme.colors.textSecondary};
  padding-left: 10px;
`;

const ItemWrapper = styled.div`
  user-select: none;

  &.disabled {
    ${Label} {
      cursor: default;
    }

    opacity: 0.5;
  }

  &.active {
    ${Label} {
      border-bottom-color: ${({theme}) => theme.colors.textPrimary};
      color: ${({theme}) => theme.colors.textPrimary};
    }

    ${ArrowWrapper} {
      color: ${({theme}) => theme.colors.textPrimary};
    }

    cursor: default;
  }

  &.step-wizard-view {
    ${Label} {
      border-bottom-color: rgba(255,255,255,0) !important;
    }
    
    display: flex;
    flex-direction: row;
  }
`;

interface ItemProps {
    itemPayload: HorizontalNavigationItemType;
    isActiveItem: boolean;
    viewMode: ViewModeEnum;
    onClick: (id: string, visibility: ScrollVisibilityApiType) => void;
    isLastItem: boolean;
    disabled: boolean;
}

export const Item: React.FC<ItemProps> = (
    {
        itemPayload,
        isActiveItem,
        onClick,
        viewMode,
        isLastItem,
        disabled
    }
) => {
    const visibility = React.useContext(VisibilityContext);
    const [isActiveLastValue, setIsActiveLastValue] = useState<boolean>(false);

    useEffect(() => {
        if (isActiveItem) {
            if (!isActiveLastValue) {
                setTimeout(() => {
                    visibility.scrollToItem(visibility.getItemById(itemPayload.id), "smooth", "center", "nearest");
                }, 100);

                setIsActiveLastValue(true);
            }
        } else {
            if (isActiveLastValue) {
                setIsActiveLastValue(false);
            }
        }
    }, [isActiveItem, isActiveLastValue, onClick, itemPayload.id, visibility]);

    return <ItemWrapper
        onClick={() => {
            if (!disabled) {
                onClick(itemPayload.id, visibility);
            }
        }}
        className={classNames(
            isActiveItem && "active",
            viewMode === ViewModeEnum.STEP_WIZARD && "step-wizard-view",
            disabled && "disabled"
        )}
        id={itemPayload.id}
        key={itemPayload.id}>
        <Label>
            {itemPayload.name}
        </Label>
        {
            (viewMode === ViewModeEnum.STEP_WIZARD && !isLastItem)
            && <ArrowWrapper>
                <ArrowRightIcon/>
            </ArrowWrapper>
        }
    </ItemWrapper>
}