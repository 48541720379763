import {IStreamEventRouter} from "./IStreamEventRouter";
import {IStreamEventService} from "./IStreamEventService";
import {RouteActionCreatorType} from "../../components/WsApiClient/Router/RouteActionCreatorType";
import {StreamEventTypeEnum} from "./StreamEventTypeEnum";
import {
    StreamEventSubscriberHandler,
    StreamEventSubscriberItem,
    StreamEventSubscribersList
} from "./StreamEventSubscribersList";

export class StreamEventService implements IStreamEventService {
    router: IStreamEventRouter;
    subscribersList: StreamEventSubscribersList;
    subscriberIdsList: {[name: string]: StreamEventTypeEnum};

    constructor(router: IStreamEventRouter) {
        this.router = router;
        this.subscribersList = {};
        this.subscriberIdsList = {};
    }

    /**
     * @inheritDoc
     */
    getActionByStreamEventType(type: StreamEventTypeEnum): RouteActionCreatorType | null {
        let route = this.router.getRouteByType(type);

        if (route === null) {
            return null;
        }

        return route.actionCreator;
    }

    /**
     * @inheritDoc
     */
    getSubscribersByStreamEventType(type: StreamEventTypeEnum): StreamEventSubscriberItem[] {
        if ((this.subscribersList[type] === undefined) || (this.subscribersList[type].length === 0)) {
            return [];
        }

        return this.subscribersList[type];
    }

    /**
     * @inheritDoc
     */
    subscribeToEventType(type: StreamEventTypeEnum, subscriberId: string, callback: StreamEventSubscriberHandler) {
        if (this.subscriberIdsList[subscriberId] !== undefined) {
            throw new Error(`StreamEvent subscriber with id ${subscriberId} already exist.`);
        }

        if (this.subscribersList[type] === undefined) {
            this.subscribersList[type] = [];
        }

        this.subscribersList[type].push({
            streamEventType: type,
            subscriberId: subscriberId,
            handler: callback
        });

        this.subscriberIdsList[subscriberId] = type;
    }

    /**
     * @inheritDoc
     */
    unsubscribeFromEventType(subscriberId: string) {
        if (this.subscriberIdsList[subscriberId] === undefined) {
            throw new Error(`StreamEvent subscriber with id ${subscriberId} not found.`);
        }

        const type = this.subscriberIdsList[subscriberId];

        this.subscribersList[type] = this.subscribersList[type].filter(item => item.subscriberId !== subscriberId);

        if (this.subscribersList[type].length === 0) {
            delete this.subscribersList[type];
        }

        delete this.subscriberIdsList[subscriberId];
    }

}
