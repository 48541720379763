import {put, putResolve, takeEvery} from 'redux-saga/effects';
import {ILogger} from "../../components/Logger/ILogger";
import {WorkerPayloadType} from "../WorkerPayloadType";
import {ResponseActionCreatorPayload} from "../../components/WsApiClient/ResponseActionCreatorPayload";
import {ResponseBaseDto} from "../../components/WsApiClient/ApiDto/Response/ResponseBaseDto";
import {ListDto} from "../../components/WsApiClient/ApiDto/Response/ListDto";
import {LoggerSectionsEnum} from "../../components/Logger/LoggerSectionsEnum";
import {StreamEventActionTypes, StreamEventItemState, StreamEventStoreItem} from "../../store/streamEvent/type";
import {StreamEventBaseDto} from "../../components/WsApiClient/ApiDto/Response/StreamEvent/StreamEventBaseDto";
import * as StreamEventsCreators from "../../store/streamEvent/actions";
import {startHandlingEvents} from "../../store/streamEvent/actions";
import {WsServerConstants} from "../../components/WsApiClient/WsApiClient";
import * as AppActionCreators from "../../store/app/actions";
import {rebootApp} from "../../store/app/actions";
import {container} from "tsyringe";
import {DiTokens} from "../../di-factory/DiTokens";

/**
 * Сага запускает процесс загрузки новых conversations.
 */
export function* watchLoadEventsAfterReconnectResult() {
    yield takeEvery(
        StreamEventActionTypes.PROCESS_EVENTS_RESPONSE_BATCH,
        loadEventsAfterReconnectResult
    );
}

function* loadEventsAfterReconnectResult(data: WorkerPayloadType<ResponseActionCreatorPayload<ResponseBaseDto<ListDto<StreamEventBaseDto<object | null>>>, null>>) {
    let logger: ILogger = container.resolve<ILogger>(DiTokens.LOGGER);

    let itemsCount: number = data.payload.response.result.items.length;

    yield putResolve(AppActionCreators.setPingTimeSuspendedValue(false));

    if (itemsCount === 0) {
        logger.debug(LoggerSectionsEnum.WS_STREAM_EVENT, 'Received empty batch of old stream events items.');

        yield put(startHandlingEvents());

        return;
    }

    // Количество событий с последнего момента более 100.
    // Перезагрузим страницу - это просто проще, чем нагонять столько событий.
    if (data.payload.response.result.totalCount > WsServerConstants.STREAM_EVENT_HISTORY_MAX_BATCH_SIZE) {
        yield put(rebootApp());

        return;
    }

    logger.debug(LoggerSectionsEnum.WS_STREAM_EVENT, `Received batch of old stream events with ${itemsCount} items.`);

    let items: Array<StreamEventBaseDto<object | null>> = data.payload.response.result.items;

    let result: Array<StreamEventStoreItem<object | null>> = [];

    items.forEach((item) => {
        let eventItem: StreamEventStoreItem<object | null> = new StreamEventStoreItem();

        eventItem.dto = item;
        eventItem.state = StreamEventItemState.WAIT_FOR_HANDLE;

        result.push(eventItem);
    });

    yield putResolve(StreamEventsCreators.pushEventsToList(result));

    yield put(startHandlingEvents());
}
