import styled from "styled-components";
import {
    DtoLessonMaterialItem
} from "../../../../../components/HttpApiClient/ApiDto/Response/LessonMaterials/DtoLessonMaterialItem";
import {TypesEnum} from "../../../../components/Ui/Elements/SlideIndexForTeacher";
import {LessonPageContext} from "../LessonPageContext";
import {DtoTmLesson} from "../../../../../components/HttpApiClient/ApiDto/Response/TmLesson/DtoTmLesson";
import {DtoTmSlide} from "../../../../../components/HttpApiClient/ApiDto/Response/TmSlide/DtoTmSlide";
import React, {RefObject} from "react";
import {IHttpApiClient} from "../../../../../components/HttpApiClient/IHttpApiClient";
import {IDateHelperService} from "../../../../../services/date-helper/IDateHelperService";
import {ILogger} from "../../../../../components/Logger/ILogger";
import {SelectMaterialsModal} from "../../../../components/TeachingMaterialsCatalog/select-materials-modal";
import {PopupActions} from "reactjs-popup/dist/types";
import {container} from "tsyringe";
import {DiTokens} from "../../../../../di-factory/DiTokens";
import {LoggerSectionsEnum} from "../../../../../components/Logger/LoggerSectionsEnum";
import {NoticeBlock, NoticeBlockText, NoticeBlockTitle} from "../../../../components/Ui/Elements/NoticeBlock";
import {t, Trans} from "@lingui/macro";
import {arrayMove} from "@dnd-kit/sortable";
import {NotificationTypesEnum, openNotification} from "../../../../components/Ui/Elements/Notification";
import {ConfirmDialog} from "../../../../components/Ui/Elements/ConfirmDialog";
import {
    DtoLessonMaterialHomeworkItem
} from "../../../../../components/HttpApiClient/ApiDto/Response/LessonMaterials/DtoLessonMaterialHomeworkItem";
import {DtoSlideItemBase} from "../../../../../components/HttpApiClient/ApiDto/Response/Common/DtoSlideItemBase";
import {IWsApiClient} from "../../../../../components/WsApiClient/IWsApiClient";
import {ApiMethodEnum} from "../../../../../components/WsApiClient/ApiMethodEnum";
import {isEqual} from "lodash";

export const MAX_ITEMS_PER_PAGE = 200;

export const Wrapper = styled.div``;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ButtonItemWrapper = styled.div``;

export enum PageMode {
    LESSON,
    LESSON_HOMEWORK
}

export enum LoadingState {
    NOT_INIT,
    LOADING,
    SUCCESS,
    ERROR
}


export type MaterialItemWithAdditionalFields = DtoSlideItemBase & {
    index: number | null;
    imageType: TypesEnum | null;
    itemDisabled: boolean;
}

export interface LessonMaterialsModePageProps {
    apiToken: string | null;
    // Список продублирован в props контекста, чтобы была возможность следить за ним в componentDidUpdate
    materialsDtoList: DtoLessonMaterialItem[] | DtoLessonMaterialHomeworkItem[];
    refToLessonMaterialsPage: React.RefObject<LessonMaterialsBase> | null;
    refToLessonHomeworkMaterialsPage: React.RefObject<LessonMaterialsBase> | null;
}

export interface LessonMaterialsModePageState {
    loadingState: LoadingState;
    lessonMaterials: MaterialItemWithAdditionalFields[];
    savingOrderNow: boolean;

    tmLessonItemForConfirm: DtoTmLesson | null;
    tmSlideItemForConfirm: DtoTmSlide | null;
    tmSlideItemForDeleteConfirm: MaterialItemWithAdditionalFields | null;
}

export abstract class LessonMaterialsBase extends React.Component<LessonMaterialsModePageProps, LessonMaterialsModePageState> {
    protected apiClient: IHttpApiClient;
    protected wsApiClient: IWsApiClient;
    protected dateHelperService: IDateHelperService;
    protected logger: ILogger;
    protected abortController: AbortController | null;
    protected updateOpAbortController: AbortController | null;
    protected selectMaterialModalRef: RefObject<SelectMaterialsModal>;
    protected addLessonConfirmDialogRef: RefObject<PopupActions>;
    protected addSlideConfirmDialogRef: RefObject<PopupActions>;
    protected removeSlideConfirmDialogRef: RefObject<PopupActions>;
    protected pageMode: PageMode;

    static contextType = LessonPageContext;
    context!: React.ContextType<typeof LessonPageContext>;

    protected constructor(props: Readonly<LessonMaterialsModePageProps> | LessonMaterialsModePageProps) {
        super(props);

        this.apiClient = container.resolve<IHttpApiClient>(DiTokens.HTTP_API_CLIENT);
        this.wsApiClient = container.resolve<IWsApiClient>(DiTokens.WS_CLIENT);
        this.dateHelperService = container.resolve<IDateHelperService>(DiTokens.DATE_HELPER_SERVICE);
        this.logger = container.resolve<ILogger>(DiTokens.LOGGER);
        this.selectMaterialModalRef = React.createRef();
        this.addLessonConfirmDialogRef = React.createRef();
        this.addSlideConfirmDialogRef = React.createRef();
        this.removeSlideConfirmDialogRef = React.createRef();
        this.pageMode = PageMode.LESSON;

        this.state = {
            loadingState: LoadingState.SUCCESS,
            lessonMaterials: [],
            savingOrderNow: false,
            tmLessonItemForConfirm: null,
            tmSlideItemForConfirm: null,
            tmSlideItemForDeleteConfirm: null
        }

        this.abortController = null;
        this.updateOpAbortController = null;
    }

    public abstract readonly fetchMaterials: () => void;

    componentDidMount() {
        this.setState(() => {
            return {
                lessonMaterials: this.prepareDtoItems(this.props.materialsDtoList)
            }
        });
    }

    componentDidUpdate(prevProps: Readonly<LessonMaterialsModePageProps>, prevState: Readonly<LessonMaterialsModePageState>, snapshot?: any) {
        if (!isEqual(prevProps.materialsDtoList, this.props.materialsDtoList)) {
            this.setState(() => {
                return {
                    lessonMaterials: this.prepareDtoItems(this.props.materialsDtoList)
                }
            })
        }
    }

    componentWillUnmount() {
        if (this.abortController !== null) {
            this.abortController.abort();
            this.abortController = null;
        }

        if (this.updateOpAbortController !== null) {
            this.updateOpAbortController.abort();
            this.updateOpAbortController = null;
        }
    }

    protected sendMessageAboutSlideListUpdated = () => {
        this.wsApiClient.query(
            ApiMethodEnum.LESSON_AND_HOMEWORK_NEED_SYNC_SLIDE_LIST,
            {
                lessonId: this.context.lessonRoomId
            },
            null,
            true
        ).then();
    }

    protected prepareDtoItems = (list: MaterialItemWithAdditionalFields[] | DtoLessonMaterialItem[] | DtoLessonMaterialHomeworkItem[]): MaterialItemWithAdditionalFields[] => {
        let tempIndex = 1;

        return list.map((item) => {
            let imageType: TypesEnum | null = null;
            let index: number | null = null;

            if (item instanceof DtoLessonMaterialHomeworkItem) {
                // Dto материалов домашней работы. У них нет hiddenForStudent.
                index = tempIndex;

                tempIndex++;
            } else if (item instanceof DtoLessonMaterialItem) {
                if (item.hiddenForStudent) {
                    imageType = TypesEnum.ADDITIONAL_ICON
                } else {
                    index = tempIndex;

                    tempIndex++;
                }
            } else {
                // MaterialItemWithAdditionalFields
                if (item.imageType !== null) {
                    imageType = item.imageType
                } else {
                    index = tempIndex;

                    tempIndex++;
                }
            }

            return {
                ...item,
                imageType: imageType,
                index: index,
                itemDisabled: false
            }
        })
    }

    protected emptyListNotice = () => {
        if (this.pageMode === PageMode.LESSON) {
            return <NoticeBlock>
                <>
                    <NoticeBlockTitle><Trans>Материалы не добавлены</Trans></NoticeBlockTitle>
                    <NoticeBlockText><Trans>Для занятий на уроке вы можете добавить слайды
                        из разных разделов учебных материалов.</Trans><br/><br/>
                        <Trans>Можно добавить весь урок учебных материалов и тогда слайды, рекомендуемые в
                            качестве домашнего задания будут добавлены в качестве домашнего задания к этому уроку,
                            а остальные слайды будут добавлены для непосредственной работы на
                            занятии.</Trans><br/><br/>
                        <Trans>Всегда можно удалить определённый слайд из урока, временно скрыть его для учеников или
                            же добавить в любой очерёдности любой слайд учебных материалов
                            (даже одновременно из разных разделов, уровней или дисциплин).</Trans>
                    </NoticeBlockText>
                    <NoticeBlockText>
                        <Trans>Нажмите «Добавить материалы», чтобы добавить материалы к этому уроку.</Trans>
                    </NoticeBlockText>
                </>
            </NoticeBlock>;
        }

        return <NoticeBlock>
            <>
                <NoticeBlockTitle><Trans>Материалы для домашней работы ещё не добавлены</Trans></NoticeBlockTitle>
                <NoticeBlockText><Trans>В домашнюю работу вы можете добавить слайды
                    из разных разделов учебных материалов.</Trans><br/><br/>
                    <Trans>Можно добавить весь урок учебных материалов и тогда слайды, рекомендуемые в
                        качестве домашнего задания будут добавлены в качестве домашнего задания к этому уроку,
                        а остальные слайды будут добавлены для непосредственной работы на
                        занятии.</Trans><br/><br/>
                    <Trans>Всегда можно удалить определённый слайд из домашней работы или
                        же добавить в домашнюю работу в любой очерёдности любой слайд учебных материалов
                        (даже одновременно из разных разделов, уровней или дисциплин).</Trans>
                </NoticeBlockText>
                <NoticeBlockText>
                    <Trans>Нажмите «Добавить материалы», чтобы добавить материалы для домашней работы.</Trans>
                </NoticeBlockText>
            </>
        </NoticeBlock>;
    }

    protected fetchMaterialsInOppositeList = () => {
        if (
            (this.props.refToLessonMaterialsPage !== null)
            && (this.props.refToLessonMaterialsPage.current)
        ) {
            this.props.refToLessonMaterialsPage.current.fetchMaterials();
        }

        if (
            (this.props.refToLessonHomeworkMaterialsPage !== null)
            && (this.props.refToLessonHomeworkMaterialsPage.current)
        ) {
            this.props.refToLessonHomeworkMaterialsPage.current.fetchMaterials();
        }
    }

    protected tmLessonAddOnClick = () => {
        return new Promise<void>((resolve, reject) => {
            if (this.props.apiToken === null) {
                reject(new Error("Api token is null"));

                return;
            }

            if (this.context.groupIntId === null) {
                reject(new Error("groupIntId is null"));

                return;
            }

            if (this.context.lessonIntId === null) {
                reject(new Error("lessonIntId is null"));

                return;
            }

            if (this.state.tmLessonItemForConfirm === null) {
                reject(new Error("tmLessonItemForConfirm is null"));

                return;
            }

            this.apiClient.addAllSlidesFromTmLessonToLesson(
                this.props.apiToken,
                this.context.groupIntId,
                this.context.lessonIntId,
                this.state.tmLessonItemForConfirm.id
            )
                .then((result) => {
                    this.fetchMaterials();

                    this.fetchMaterialsInOppositeList();

                    this.sendMessageAboutSlideListUpdated();

                    resolve();
                })
                .catch((error) => {
                    this.logger.error(LoggerSectionsEnum.LESSON_MATERIALS, "Error on add tm lesson to studentgroup lesson", error);

                    reject();
                })
        });
    }

    protected tmSlideAddOnClick = () => {
        return new Promise<void>((resolve, reject) => {
            if (this.props.apiToken === null) {
                reject(new Error("Api token is null"));

                return;
            }

            if (this.context.groupIntId === null) {
                reject(new Error("groupIntId is null"));

                return;
            }

            if (this.context.lessonIntId === null) {
                reject(new Error("lessonIntId is null"));

                return;
            }

            if (this.state.tmSlideItemForConfirm === null) {
                reject(new Error("tmSlideItemForConfirm is null"));

                return;
            }

            this.apiClient.addTmSlideToLesson(
                this.props.apiToken,
                this.context.groupIntId,
                this.context.lessonIntId,
                this.state.tmSlideItemForConfirm.id,
                (this.pageMode === PageMode.LESSON_HOMEWORK)
            )
                .then((result) => {
                    this.fetchMaterials();

                    this.sendMessageAboutSlideListUpdated();

                    resolve();
                })
                .catch((error) => {
                    this.logger.error(LoggerSectionsEnum.LESSON_MATERIALS, "Error on add tm slide to student group lesson", error);

                    reject();
                })
        });
    }

    protected setSlideLockById = (tmSlideId: string, lock: boolean) => {
        // Блокируем элемент
        this.setState(() => {
            return {
                lessonMaterials: this.prepareDtoItems(this.state.lessonMaterials.map((stateItem) => {
                    if (tmSlideId === stateItem.slideTmId) {
                        stateItem.itemDisabled = lock;
                    }

                    return stateItem;
                }))
            };
        });
    }

    protected reorderItems = (from: number, to: number) => {
        if ((!this.props.apiToken) || (!this.context.groupIntId) || (!this.context.lessonIntId)) {
            return;
        }

        const firstItemId = this.state.lessonMaterials[from].slideTmId;
        const secondItemId = this.state.lessonMaterials[to].slideTmId;

        if (this.pageMode === PageMode.LESSON) {
            this.context.setLessonMaterialsList(arrayMove(this.context.lessonMaterialsList, from, to));
        } else {
            this.context.setLessonHomeworkMaterialsList(arrayMove(this.context.lessonHomeworkMaterialsList, from, to));
        }

        this.setState(() => {
            return {
                savingOrderNow: true
            }
        });

        if (this.updateOpAbortController !== null) {
            this.updateOpAbortController.abort();
            this.updateOpAbortController = null;
        }

        this.updateOpAbortController = new AbortController();

        this.apiClient.tmSwapLessonSlideOrderPosition(
            this.props.apiToken,
            this.context.groupIntId,
            this.context.lessonIntId,
            firstItemId,
            secondItemId,
            (this.pageMode === PageMode.LESSON_HOMEWORK),
            this.updateOpAbortController
        )
            .then(() => {
                this.setState(() => {
                    return {
                        savingOrderNow: false
                    }
                });

                this.sendMessageAboutSlideListUpdated();
            })
            .catch((err) => {
                this.logger.error(
                    LoggerSectionsEnum.LESSON_MATERIALS,
                    'Error on reorder lesson slides list: ',
                    err
                );

                if (this.pageMode === PageMode.LESSON) {
                    this.context.setLessonMaterialsList(arrayMove(this.context.lessonMaterialsList, to, from));
                } else {
                    this.context.setLessonHomeworkMaterialsList(arrayMove(this.context.lessonHomeworkMaterialsList, to, from));
                }

                this.setState(() => {
                    return {
                        savingOrderNow: false
                    }
                });

                openNotification(
                    NotificationTypesEnum.ERROR,
                    t`Ошибка сохранения`,
                    t`Не удалось сохранить порядок слайдов. Попробуйте повторить попытку.`
                );
            });
    }

    protected openSelectMaterialModal = () => {
        if (this.selectMaterialModalRef.current) {
            this.selectMaterialModalRef.current.open();
        }
    }

    protected addLessonConfirmDialog = () => {
        if (this.state.tmLessonItemForConfirm === null) {
            return;
        }

        return <ConfirmDialog okText={t`Выбрать`}
                              ref={this.addLessonConfirmDialogRef}
                              cancelText={t`Отмена`}
                              title={t`Выбрать занятие` + ' «' + this.state.tmLessonItemForConfirm.name + '»?'}
                              text={t`Слайды урока, отмеченные в качестве рекомендуемых для домашней работы будут добавлены как домашняя работа к уроку. Слайды, указанные как дополнительные, будут добавлены на урок как временно скрытые для учеников. Остальные слайды будут добавлены для работы непосредственно на уроке.`}
                              okMethod={this.tmLessonAddOnClick}/>
    }

    protected addTmLessonOnClick = (item: DtoTmLesson) => {
        this.setState(
            () => {
                return {
                    tmLessonItemForConfirm: item
                }
            },
            () => {
                if (this.addLessonConfirmDialogRef.current) {
                    this.addLessonConfirmDialogRef.current.open();
                }
            }
        );
    }

    protected addSlideConfirmDialog = () => {
        if (this.state.tmSlideItemForConfirm === null) {
            return;
        }

        const title = (this.pageMode === PageMode.LESSON)
            ? t`Добавить к уроку слайд` + ' «' + this.state.tmSlideItemForConfirm.name + '»?'
            : t`Добавить к домшней работе слайд` + ' «' + this.state.tmSlideItemForConfirm.name + '»?'

        return <ConfirmDialog okText={t`Выбрать`}
                              ref={this.addSlideConfirmDialogRef}
                              cancelText={t`Отмена`}
                              title={title}
                              okMethod={this.tmSlideAddOnClick}/>
    }

    protected callDeleteSlide = async () => {
        if (this.props.apiToken === null) {
            throw new Error('Not found api token');
        }

        if (this.context.groupIntId === null) {
            throw new Error("groupIntId is null");
        }

        if (this.context.lessonIntId === null) {
            throw new Error("lessonIntId is null");
        }

        if (this.state.tmSlideItemForDeleteConfirm === null) {
            throw new Error('Not found tmSlideItemForDeleteConfirm');
        }

        if (this.updateOpAbortController !== null) {
            this.updateOpAbortController.abort();
            this.updateOpAbortController = null;
        }

        this.updateOpAbortController = new AbortController();

        const slideForDeleteTmId = this.state.tmSlideItemForDeleteConfirm.slideTmId;

        try {
            await this.apiClient.removeSlideFromLesson(
                this.props.apiToken,
                this.context.groupIntId,
                this.context.lessonIntId,
                slideForDeleteTmId,
                (this.pageMode === PageMode.LESSON_HOMEWORK),
                this.updateOpAbortController
            );

            // Удаляем из списка этот слайд
            if (this.pageMode === PageMode.LESSON) {
                this.context.setLessonMaterialsList(
                    this.context.lessonMaterialsList.filter((item) => {
                        return item.slideTmId !== slideForDeleteTmId
                    })
                );
            } else {
                this.context.setLessonHomeworkMaterialsList(
                    this.context.lessonHomeworkMaterialsList.filter((item) => {
                        return item.slideTmId !== slideForDeleteTmId
                    })
                );
            }

            this.setState((state) => {
                return {
                    tmSlideItemForDeleteConfirm: null
                }
            });

            this.sendMessageAboutSlideListUpdated();
        } catch (e) {
            this.logger.error(
                LoggerSectionsEnum.LESSON_MATERIALS,
                'Error on delete slide materials: ',
                e
            );

            throw e;
        }
    }

    protected deleteSlideOnClick = (item: MaterialItemWithAdditionalFields) => {
        this.setState(
            () => {
                return {
                    tmSlideItemForDeleteConfirm: item
                }
            },
            () => {
                if (this.removeSlideConfirmDialogRef.current) {
                    this.removeSlideConfirmDialogRef.current.open();
                }
            }
        )
    }

    protected deleteSlideConfirmDialog = () => {
        if (this.state.tmSlideItemForDeleteConfirm === null) {
            return;
        }

        return <ConfirmDialog okText={t`Удалить`}
                              ref={this.removeSlideConfirmDialogRef}
                              cancelText={t`Отмена`}
                              title={t`Удалить слайд?`}
                              text={t`Вы уверены, что нужно удалить слайд ${this.state.tmSlideItemForDeleteConfirm.slideTmName}?`}
                              errorText={t`Не удалось удалить информацию`}
                              okMethod={this.callDeleteSlide}/>;
    }

    protected callClearAllSlides = async () => {
        if (
            (this.props.apiToken === null)
            || (this.context.groupIntId === null)
            || (this.context.lessonIntId === null)
        ) {
            throw new Error('Not found api token or group/lesson id');
        }

        if (this.updateOpAbortController !== null) {
            this.updateOpAbortController.abort();
            this.updateOpAbortController = null;
        }

        this.updateOpAbortController = new AbortController();

        try {
            await this.apiClient.clearAllLessonSlides(
                this.props.apiToken,
                this.context.groupIntId,
                this.context.lessonIntId,
                (this.pageMode === PageMode.LESSON_HOMEWORK),
                this.updateOpAbortController
            );

            // Перезагрузим список
            this.fetchMaterials();

            this.sendMessageAboutSlideListUpdated();
        } catch (e) {
            this.logger.error(
                LoggerSectionsEnum.LESSON_MATERIALS,
                'Error on clear slide materials: ',
                e
            );

            throw e;
        }
    }

    protected addTmSlideOnClick = (item: DtoTmSlide) => {
        this.setState(
            () => {
                return {
                    tmSlideItemForConfirm: item
                }
            },
            () => {
                if (this.addSlideConfirmDialogRef.current) {
                    this.addSlideConfirmDialogRef.current.open();
                }
            }
        );
    }

    protected getSlideIndexInLessonMaterials = (tmSlideId: string): number | null => {
        return this.context.lessonMaterialsList.findIndex(item => item.slideTmId === tmSlideId) ?? null
    }

    protected getSlideIndexInLessonHomeworkMaterials = (tmSlideId: string): number | null => {
        return this.context.lessonHomeworkMaterialsList.findIndex(item => item.slideTmId === tmSlideId) ?? null
    }
}
